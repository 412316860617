import React, { Component } from 'react';
import { View, Text, Image, ActivityIndicator, ImageProps } from 'react-native';
import { color, font, shadow } from '../Component/Styles';



export default class ImageLoader extends Component<ImageProps> {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            height: 0,
            width: 0
        };
    }

    render() {
        const { isLoading, height, width } = this.state;
        return (
            <View style={{
                // height: height,
                // width: width,
                // alignSelf: 'flex-start',
            }}>
                {console.log('ImageLoader 25',this.state)}
                <Image
                    {...this.props}
                    onLoadStart={() => this.setState({ isLoading: true })}
                    onLoadEnd={() => this.setState({ isLoading: false })}
                    onLayout={(event) => {
                        this.setState({ height: event.nativeEvent.layout.height, width: event.nativeEvent.layout.width })
                    }}
                />
                
                {/* {isLoading ? (
                    <></>
                    // <View
                    //     style={{
                    //         position: 'absolute',
                    //         height: '100%',
                    //         width: '100%',
                    //         justifyContent: 'center',
                    //         alignItems: 'center',
                    //         backgroundColor: '#e2e2e2',
                    //     }}>
                    //     <ActivityIndicator color={'#000'} />
                    // </View>
                ) : null} */}
            </View>
        );
    }
}
