import React, { useEffect } from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

// ** Utils
import { getUserData } from "./utility/Utils";

import Login from "./view/auth/Login";
import Otp from "./view/auth/Otp";
import Activebids from "./view/authenticate/Activebids";
import AddProduct from "./view/authenticate/AddProduct";

import Home from "./view/authenticate/Home";
import Myposts from "./view/authenticate/Myposts";
import ProductDetail from "./view/authenticate/ProductDetail";
import Profile from "./view/authenticate/Profile";
import Shop from "./view/authenticate/Shop";
import UserReview from "./view/authenticate/UserReview";
import Settings from "./view/authenticate/Settings";
import PrivacyPolicy from "./view/authenticate/PrivacyPolicy";
import TermsConditions from "./view/authenticate/TermsConditions";
import NComponent from "./view/NComponent/index";
import FavouriteSeller from "./view/authenticate/FavouriteSeller";
import SellerProfile from "./view/authenticate/SellerProfile";
import PurchaseList from "./view/authenticate/PurchaseList";
import PurchasedProductDetails from "./view/authenticate/PurchasedProductDetails";
import ReviewSeller from "./view/authenticate/ReviewSeller";
import WatchList from "./view/authenticate/WatchList";
import Message from "./view/authenticate/Message";
import MessageDetails from "./view/authenticate/MessageDetails";
import EditProfile from "./view/authenticate/EditProfile";
import Shopdetails from "./view/authenticate/Shopdetails";
import AddProfile from "./view/auth/AddProfile";

import { useState } from "react";
import { fetchToken, onMessageListener } from "./firebase";

import Payment from "./view/authenticate/Payment";
import { Translation } from "react-i18next";

let userData = getUserData();

const NotFoundURl = () => (
  <div>
    <h1>
      404 -<Translation>{(t, { i18n }) => <>{t(" Not Found")}</>}</Translation>!
    </h1>
  </div>
);

function App() {
  const [isTokenFound, setTokenFound] = useState(false);
  const element = document.getElementsByTagName("html")[0];
  let tempLang = window.localStorage.getItem("lang") ?? "en";

  if (tempLang === "ar") {
    window.localStorage.setItem("lang", tempLang);
    window.localStorage.setItem("direction", "rtl");
    element.setAttribute("dir", "rtl");
  } else {
    window.localStorage.setItem("direction", "ltr");
    window.localStorage.setItem("lang", tempLang);
    element.setAttribute("dir", "ltr");
  }

  useEffect(() => {
    if (userData == null || typeof userData.token === "undefined") {
      // console.log("App.js", userData);
    }
  }, []);

  fetchToken(setTokenFound);

  onMessageListener()
    .then((payload) => {
      // setNotification({title: payload.notification.title, body: payload.notification.body})
      // setShow(true);
      if (payload?.data?.type === "chat_message") {
        let message = payload?.data?.message;
        message = JSON.parse(message);
        console.log("notification payload 88 => ", message);
      }
    })
    .catch((err) => console.log("failed: ", err));

  // console.log("messaging 80", firebaseApp);

  // console.log("userData 35", userData);
  if (userData == null) {
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/login" exact element={<Login />}></Route>
            <Route path="/otp" exact element={<Otp />}></Route>
            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </>
    );
  } else if (typeof userData.token === "undefined") {
    // console.log("userData 29", userData, userData.token);
    // return (
    //   <Navigate replace to="/login" />
    // )
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/login" exact element={<Login />}></Route>
            <Route path="/otp" exact element={<Otp />}></Route>
            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </>
    );
  } else {
    return (
      <>
        {/* <RequireAuth /> */}
        <BrowserRouter>
          {/* <Router /> */}
          <Routes>
            <Route path="/add-profile" exact element={<AddProfile />}></Route>
            <Route path="/" exact element={<Home />} />
            {/* <Route path="/" exact index element={<Home />}></Route> */}
            <Route path="/shop" exact element={<Shop />}></Route>
            <Route path="/shop-detail" exact element={<Shopdetails />}></Route>
            <Route path="/profile" exact element={<Profile />}></Route>
            <Route path="/user-review" exact element={<UserReview />}></Route>
            <Route path="/edit-profile" exact element={<EditProfile />}></Route>

            <Route path="/product-add" exact element={<AddProduct />}></Route>
            <Route
              path="/product-detail"
              exact
              element={<ProductDetail />}
            ></Route>

            <Route path="/message" exact element={<Message />}></Route>
            <Route
              path="/message-details"
              exact
              element={<MessageDetails />}
            ></Route>

            <Route path="/my-posts" exact element={<Myposts />}></Route>

            <Route path="/active-bids" exact element={<Activebids />}></Route>

            <Route path="/watch-list" exact element={<WatchList />}></Route>

            <Route
              path="/purchase-list"
              exact
              element={<PurchaseList />}
            ></Route>
            <Route
              path="/purchase-product-detail"
              exact
              element={<PurchasedProductDetails />}
            ></Route>
            <Route
              path="/review-seller"
              exact
              element={<ReviewSeller />}
            ></Route>

            <Route
              path="/favourite-seller"
              exact
              element={<FavouriteSeller />}
            ></Route>

            <Route
              path="/seller-profile"
              exact
              element={<SellerProfile />}
            ></Route>
            <Route path="/settings" exact element={<Settings />}></Route>
            <Route path="/privacy-policy" exact element={<PrivacyPolicy />}></Route>
            
            <Route path="/terms-conditions" exact element={<TermsConditions />}></Route>


            <Route path="/n-component" exact element={<NComponent />}></Route>
            <Route path="*" component={NotFoundURl} />

            <Route path="/payment/:sellerId" exact element={<Payment />} />
            {/* <Route path="/payment/:id" exact element={<Payment />} /> */}
          </Routes>
          {/* Here Add the Condition of which Route show the Footer Tab */}
        </BrowserRouter>
      </>
    );
  }
}
export default App;

// function RequireAuth({ children }: { children: JS.element }) {
//   let location = useLocation();
//   userData = getUserData();
//   if (!userData) {
//     console.log("Required Auth 43", userData, location);
//     // Redirect them to the /login page, but save the current location they were
//     // trying to go to when they were redirected. This allows us to send them
//     // along to that page after they login, which is a nicer userData experience
//     // than dropping them off on the home page.
//     return <Navigate to="/login" state={{ from: location }} replace />;
//   }
//   // console.log("Required Auth 50", userData, location);
//   return children;
//   // return children;
//   // return <Navigate to={location.pathname} state={{ from: location }} replace />;
//   // return true;
// }
