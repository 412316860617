// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => {
  // console.log('utils',localStorage.getItem("kushkUserData"));
  if (localStorage.getItem("kushkUserData")) {
    return true;
  }
  return false;
};

// export const getUserData = () => JSON.parse(localStorage.getItem("kushkUserData"));
export const getUserData = () => {
  // console.log('utils',isUserLoggedIn());
  return isUserLoggedIn()
    ? JSON.parse(localStorage.getItem("kushkUserData"))
    : isUserLoggedIn();
};

export const clearUserLocalStorage = () => {
  let tempLang = window.localStorage.getItem("lang") ?? "en";
  let tempDirection = window.localStorage.getItem("direction") ?? "ltr";
  localStorage.removeItem("kushkUserData");
  localStorage.removeItem("deviceToken");
  localStorage.clear();
  window.localStorage.setItem("lang", tempLang);
  window.localStorage.setItem("direction", tempDirection);
};

export const getUserDeviceToken = () => { 
  return localStorage.getItem('deviceToken');
}
// Test regx
export const checkOnlyNumber = (number) => {
  const regx_number = /^[0-9\b]+$/;
  return number === "" || regx_number.test(number) ? number : "Enter a numeric value only";
};
