import React, { Component } from "react";

import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  TextInput,
  Keyboard,
  ActivityIndicator,
  KeyboardAvoidingView,
} from "react-native";
import Header from "../Component/Header";
import { font } from "../Component/Styles";
import { API } from "../../Privet";
import ImagePicker from "../Component/ImagePicker";
import SnackBar from "../Component/SnackBar";
import { clearUserLocalStorage, getUserData } from "../../utility/Utils";
import { withRouter } from "../../withRouter";
import { Navigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';

class AddProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      image: "",
      data: "",
      userid: "",
    };
  }

  async componentDidMount() {
    console.log("Add Profile 30", this.props.location);
    const userData = await getUserData();
    const { userid, mobile } = userData;

    this.setState({ userid, mobile });
    if(typeof userData.email !== "undefined" && userData.email === "undefined" && userData.email !== "" && userData.email !== "null" && userData.email !== null){
        <Navigate to='/' />
        this.props.navigate('/');
        return ;
    }
  }

  willFocus() {}

  onSelectIMG(img) {
    // const { image } = this.state;
    let tempImg = img.target.files[0];
    this.setState({ image: tempImg });
  }

  CheckValidation() {
    const { firstname, lastname, image, email, mobile } = this.state;
    Keyboard.dismiss();
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const numreg = /[^0-9]/g;

    if (!image) {
      this.refs.RNSnackBar.show(this.props.t("Please select photo."));
    } else if (!firstname) {
      this.refs.RNSnackBar.show(this.props.t("Please enter first name."));
    } else if (!lastname) {
      this.refs.RNSnackBar.show(this.props.t("Please enter last name."));
    } else if (!email) {
      this.refs.RNSnackBar.show(this.props.t("Please enter email id."));
    } else if (!reg.test(email)) {
      this.refs.RNSnackBar.show(this.props.t("Please enter valid email id."));
    } else {
      this.AddProfileApi();
    }
  }

  // AddProfile API
  async AddProfileApi() {
      const { token } = getUserData();
    const { userid, mobile, firstname, lastname, image, email } = this.state;
    this.setState({ isLoading: true });

    const formdata = new FormData();
    formdata.append("edit_id", userid);
    formdata.append("name", firstname);
    formdata.append("lname", lastname);
    formdata.append("email", email);
    formdata.append("mobile", mobile);
    // formdata.append(
    //   "image",
    //   image.path
    //     ? { uri: image.path, name: "profile.png", type: "image/jpeg" }
    //     : ""
    // );
    image && formdata.append("image", image);

    await fetch(API.updateUserProfile, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        // Accept: 'application/json',
      },
      body: formdata,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          let items = {};
          items = {
            userid: userid + "",
            mobile: mobile + "",
            token: response.data.token + "",
            firstname: response.data.name + "",
            lastname: response.data.lname + "",
            email: response.data.email + "",
            image: response.data.image + "",
            imageURL: response.data.imageURL + "",
          };
          // console.log('Add Profile 125',response,response.data);
          clearUserLocalStorage();
          localStorage.setItem("kushkUserData", JSON.stringify(items));
          <Navigate to='/' />
          this.props.navigate("/");
          this.refs.RNSnackBar.show(response.message);
        } else {
          this.refs.RNSnackBar.show(response.message);
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { firstname, lastname, image, email, data, isLoading } = this.state;

    return (
      <KeyboardAvoidingView style={{ flex: 1, backgroundColor: "#ffff" }}>
        <View style={{ flex: 1, backgroundColor: "#fff", paddingTop: 30 }}>
          <Header title={this.props.t("Add Profile")} />

          <ScrollView contentContainerStyle={{ flexGrow: 1, padding: 15 }}>
            <View style={{ flex: 1, backgroundColor: "#fff" }}>
              <TouchableOpacity
                // onPress={() => this.ImagePicker.show()}
                onPress={() => {
                  this.upload.click();
                }}
                style={{
                  height: 120,
                  width: 120,
                  borderRadius: 120,
                  borderWidth: 1,
                  alignSelf: "center",
                  overflow: "hidden",
                  marginTop: 25,
                  justifyContent: "center",
                }}
              >
                <input
                  type="file"
                  id="file"
                  ref={(ref) => (this.upload = ref)}
                  style={{ display: "none" }}
                  // webkitdirectory="true"
                  onChange={this.onSelectIMG.bind(this)}
                />
                {image ? (
                  <img
                    // src={item}
                    src={URL.createObjectURL(image)}
                    style={{ height: "100%", width: "100%" }}
                    alt="profile"
                  />
                ) : data ? (
                  <img
                    // src={item}
                    src={data}
                    style={{ height: "100%", width: "100%" }}
                    alt="profile"
                  />
                ) : (
                  <Image
                    source={require("../../assets/img/user.png")}
                    style={{
                      height: "100%",
                      width: "100%",
                      alignSelf: "center",
                    }}
                  />
                )}
              </TouchableOpacity>
              <View style={{ marginTop: 30, marginHorizontal: 20 }}>
                <View style={{ marginBottom: 15 }}>
                  <Text style={{ fontFamily: font.reg, color: "#000" }}>
                    {this.props.t('First Name')}
                  </Text>
                  <TextInput
                    style={{
                      fontSize: 14,
                      color: "#000",
                      borderBottomWidth: 1,
                    }}
                    placeholder={this.props.t("Enter first name")}
                    value={firstname}
                    onChangeText={(firstname) => this.setState({ firstname })}
                    returnKeyType="next"
                    onSubmitEditing={() => this.refs.lastname.focus()}
                  />
                </View>
                <View style={{ marginBottom: 15 }}>
                  <Text style={{ fontFamily: font.reg, color: "#000" }}>
                    {this.props.t('Last Name')}
                  </Text>
                  <TextInput
                    ref="lastname"
                    style={{
                      fontSize: 14,
                      color: "#000",
                      borderBottomWidth: 1,
                    }}
                    placeholder={this.props.t("Enter last name")}
                    value={lastname}
                    onChangeText={(lastname) => this.setState({ lastname })}
                    returnKeyType="next"
                    onSubmitEditing={() => this.refs.emailid.focus()}
                  />
                </View>
                <View style={{ marginBottom: 15 }}>
                  <Text style={{ fontFamily: font.reg, color: "#000" }}>
                    {this.props.t('Email Address')}
                  </Text>
                  <TextInput
                    ref="emailid"
                    style={{
                      fontSize: 14,
                      color: "#000",
                      borderBottomWidth: 1,
                    }}
                    placeholder={this.props.t("Enter email address")}
                    keyboardType="email-address"
                    value={email}
                    autoCapitalize="none"
                    onChangeText={(email) => this.setState({ email })}
                    returnKeyType="done"
                  />
                </View>

                <TouchableOpacity
                  onPress={() => this.CheckValidation()}
                  style={{
                    backgroundColor: "#000",
                    paddingVertical: 13,
                    marginTop: 20,
                    borderRadius: 25,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {isLoading ? (
                    <ActivityIndicator color={"#fff"} />
                  ) : (
                    <Text
                      style={{
                        fontFamily: font.bold,
                        color: "#ffff",
                        fontSize: 16,
                      }}
                    >
                      {this.props.t('Next')}
                    </Text>
                  )}
                </TouchableOpacity>
              </View>
            </View>
          </ScrollView>
          <ImagePicker
            ref={(ref) => (this.ImagePicker = ref)}
            onSelect={(img) => this.onSelectIMG(img)}
          />
          <SnackBar ref="RNSnackBar" />
        </View>
      </KeyboardAvoidingView>
    );
  }
}

export default withTranslation()(withRouter(AddProfile))
