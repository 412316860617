import React, { Component } from "react";
// import SplashScreen from 'react-native-splash-screen';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  RefreshControl,
  FlatList,
  Dimensions,
} from "react-native";

/** Component */
import Header from "../Component/Header";
import { color, font, fontBold } from "../Component/Styles";

import { API } from "../../Privet";
import { clearUserLocalStorage, getUserData } from "../../utility/Utils";
import { withRouter } from "../../withRouter";
import FootTab from "./FootTab";
import { Navigate } from "react-router-dom";

import { withTranslation } from 'react-i18next';
// import Global from '../Global';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import { io } from 'socket.io-client';

let width = "";

class Shop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isSearch: false,
      text: "",
      currLang: window.localStorage.getItem("lang") ?? "en",
    };
    this.arrayHold = [];
    // this.socket = io('https://app.ekushk.com:3001');
  }

  async componentDidMount() {
    const { deviceWidth } = JSON.parse(localStorage.getItem("kushkDevice"));
    width = deviceWidth;
    // this.messageusersAPI();

    /*this.socket.onAny((key, data) => {
      if (key.endsWith('_' + Global.main.state.userid)) {
        this.messageusersAPI();
      }
    });
    Global.main.setState({ activeRoute: 'Shop' });
    Global.main.isTabBar(true);
    */
    this.maincategorylist();
  }

  willFocus() {
    /*Global.main.setState({ activeRoute: 'Shop' });
    Global.main.isTabBar(true);*/
  }

  async messageusersAPI() {
    const { token } = getUserData();

    fetch(API.chat_connect_users, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          clearUserLocalStorage();
          this.props.navigate("/login");
        }
        return response.json();
      })
      .then(async (res) => {
        if (res.success) {
          let count = 0;
          res.data.collection.map((item) => {
            return (count = item.count_unread_message + count);
          });
          // const items = [["count", count + ""]];
          /*await Global.main.setState({
            count: count + ''
          }, () => AsyncStorage.multiSet(items, async () => {
          }));*/
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  maincategorylist() {
    const { token } = getUserData();
    this.setState({ isLoading: true });

    fetch(API.maincategorylist, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          clearUserLocalStorage();
          return <Navigate to="/login" />;
        }
        return response.json();
      })
      .then((res) => {
        if (res.success) {
          this.setState({ isLoading: false, data: res.data });
          this.arrayHold = res.data;
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }
  search(text) {
    this.setState({ text });
    const data = this.arrayHold.filter((item) => {
      const itemData = `${item.name}`.toUpperCase();
      return itemData.indexOf(text.toUpperCase()) > -1;
    });
    this.setState({ data: data });
  }
  render() {
    const { isLoading, data, isSearch, text, showDrawer = false, title = "Your Shop" } = this.state;

    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <Header
          title={this.props.t(title)} 
          Limg={require("../../assets/img/homemenu.png")}
          showDrawer={showDrawer}
          closeDrawer={() => this.setState({ showDrawer: false })}
          Lpress={() => this.setState({ showDrawer: true })}
          Rimg={require("../../assets/img/search.png")}
          Rpress={() => this.setState({ isSearch: true })}
          _onWillFocus={() => this.willFocus()}
          searchBar={isSearch}
          closeSearchBar={() =>
            this.setState({ isSearch: false, data: this.arrayHold, text: "" })
          }
          texts={text}
          searchFilterFunction={(text) => this.search(text)}
          // Rpress={() => this.props.navigation.openDrawer()}
        />

        <FlatList
          numColumns={2}
          contentContainerStyle={{
            marginHorizontal: 0,
            marginTop: 15,
            paddingBottom: 40,
            flexGrow: 1,
          }}
          data={data}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              onPress={() =>
                this.props.navigate("/shop-detail", {
                  state: { Categoryid: item.id, CategoryName: this.state.currLang === 'ar' ? item.name_ar : item.name },
                })
              }
              style={{
                height: 160,
                // width: (width - 50) / 2,
                width: '50%',
                marginLeft: 15,
                marginBottom: 15,
                backgroundColor: "#FAFAFA",
                borderRadius: 8,
                overflow: "hidden",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                source={{ uri: item.image }}
                style={{ height: 78, width: 78, resizeMode: "contain" }}
              />
              <Text
                style={{
                  ...fontBold,
                  marginTop: 10,
                  color: "#231F20",
                  fontSize: 16,
                }}
              >
                { this.state.currLang === 'ar' ? item.name_ar : item.name}
              </Text>
              {item.totalProducts ? (
                <Text
                  style={{
                    ...fontBold,
                    color: "#9E9EA7",
                    fontSize: 10,
                  }}
                >
                  {item.totalProducts + '  '} 
                  {item.totalProducts === 1 || item.totalProducts === 0 ? 
                    this.props.t('Post') : this.props.t('Posts') 
                  }
                </Text>
              ) : null}
            </TouchableOpacity>
          )}
          ListEmptyComponent={
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!isLoading ? (
                <>
                  <Image
                    source={require("../../assets/img/nodata.png")}
                    style={{
                      height: 60,
                      width: 60,
                      tintColor: color.primeColor,
                    }}
                  />
                  <Text
                    style={{
                      ...fontBold,
                      textAlign: "center",
                      marginTop: 10,
                      color: color.lableColor,
                    }}
                  >
                    {this.props.t('Data not found')}!
                  </Text>
                </>
              ) : /*Platform.OS === 'ios' ? (
                <ActivityIndicator color={color.primeColor} size="large" />
              ) :*/ null}
            </View>
          }
          keyExtractor={(item, index) => index + ""}
          refreshControl={
            <RefreshControl
              refreshing={isLoading}
              onRefresh={() => this.maincategorylist()}
            />
          }
        />
        <FootTab />
      </View>
    );
  }
}

export default withTranslation()(withRouter(Shop));
