import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
  RefreshControl,
} from "react-native";
import Header from "../Component/Header";
import { color, font, fontBold, fontReg, shadow } from "../Component/Styles";
import { API } from "../../Privet";

// import ImageLoader from '../Component/ImageLoader';
import Coundown from "../Component/Coundown";

import { getUserData } from "../../utility/Utils";
import NImageLoader from "../NComponent/NImageLoader";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import FootTab from "./FootTab";
import { withRouter } from "../../withRouter";
import { withTranslation } from 'react-i18next';

const userData = getUserData();

class WatchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isRefreshing: false,
      WatchList: [],
      totalPage: 1,
      page: 1,
      isSearch: false,
      text: "",
      currLang: window.localStorage.getItem("lang") ?? "en",
    };
    this.arrayHold = [];
  }
  async componentDidMount() {
    // Global.main.setState({ activeRoute: '' });
    // Global.main.isTabBar(true);
    await this.WatchListApi();
  }

  willFocus() {
    // Global.main.setState({ activeRoute: '' });
    // Global.main.isTabBar(true);

    this.setState(
      {
        isLoading: true,
        page: 1,
        totalPage: 1,
      },
      () => this.WatchListApi()
    );
  }

  async WatchListApi() {
    const { token, userid } = userData;
    const { totalPage, page, isLoading, isRefreshing, WatchList } = this.state;
    if (totalPage >= page) {
      let formdata = new FormData();
      formdata.append("appuser_id", userid);
      formdata.append("page", page + "");
      formdata.append("perPage", "15");

      await fetch(API.userWatchlist, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        body: formdata,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {
            if (isLoading || isRefreshing || WatchList.length <= 0) {
              this.setState({
                isLoading: false,
                isRefreshing: false,
                WatchList: res.data.collection,
                totalPage: res.data.lastPage,
                page: page + 1,
              });
              this.arrayHold = res.data.collection;
            } else {
              this.setState({
                isLoading: false,
                isRefreshing: false,
                WatchList: [...WatchList, ...res.data.collection],
                totalPage: res.data.lastPage,
                page: page + 1,
              });
              this.arrayHold = [...WatchList, ...res.data.collection];
            }
          } else {
            this.setState({
              isLoading: false,
              isRefreshing: false,
              page: page + 1,
              WatchList: [],
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isRefreshing: false,
            page: page + 1,
          });
          console.log(error);
        })
        .finally(() => {
          this.setState({
            isLoading: false,
            isRefreshing: false,
            page: page + 1,
          });
        });
    }
  }

  renderFooter = () => {
    const { totalPage, page, isLoading, isRefreshing, WatchList } = this.state;
    if ((totalPage >= page && !isRefreshing) || isLoading) {
      return (
        <View
          style={{
            height: 40,
            width: 40,
            borderRadius: 40,
            ...shadow,
            alignSelf: "center",
            marginTop: 20,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator color={color.primeColor} style={{}} />
        </View>
      );
    } else {
      return null;
    }
  };

  onRefresh() {
    this.setState(
      {
        totalPage: 1,
        page: 1,
        isRefreshing: true,
      },
      () => this.WatchListApi()
    );
  }

  search(text) {
    this.setState({ text });
    const data = this.arrayHold.filter((item) => {
      const itemData =
        `${item.name} ${item.location} ${item.category_name} ${item.sub_category_name}`.toUpperCase();
      return itemData.indexOf(text.toUpperCase()) > -1;
    });
    this.setState({ WatchList: data });
  }

  render() {
    const {
      isLoading,
      isRefreshing,
      WatchList,
      isSearch,
      text,
      showDrawer = false,
    } = this.state;

    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <Header
          title={this.props.t("Watch List")}
          Limg={require("../../assets/img/homemenu.png")}
          showDrawer={showDrawer}
          closeDrawer={() => this.setState({ showDrawer: false })}
          Lpress={() => this.setState({ showDrawer: true })}
          Rimg={require("../../assets/img/search.png")}
          Rpress={() => this.setState({ isSearch: true })}
          _onWillFocus={() => this.willFocus()}
          searchBar={isSearch}
          closeSearchBar={() =>
            this.setState({
              isSearch: false,
              WatchList: this.arrayHold,
              text: "",
            })
          }
          texts={text}
          searchFilterFunction={(text) => this.search(text)}
        />

        <FlatList
          contentContainerStyle={{
            paddingBottom: 25,
            flexGrow: 1,
            paddingHorizontal: 20,
            paddingTop: 15,
          }}
          data={WatchList}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              style={{ flexDirection: "row", marginBottom: 20 }}
              onPress={() =>
                this.props.navigate("/product-detail", {
                  state: { id: item.id, type: "watchlist" },
                })
              }
            >
              <View
                style={{
                  height: 88,
                  width: 120,
                  borderRadius: 10,
                  overflow: "hidden",
                  marginLeft:  this.state.currLang === 'ar' ? 10 : 0,
                }}
              >
                <NImageLoader
                  imageSrc={
                    item.image
                      ? item.image
                      : require("../../assets/img/noimg.jpeg")
                  }
                  imageAlt={item.name}
                  imageStyle={{
                    verticalAlign: "middle",
                    height: "100%",
                    width: "100%",
                  }}
                />
              </View>
              <View style={{ paddingLeft: 18, flex: 1 }}>
                <Text
                  style={{
                    ...fontBold,
                    color: "#000",
                    fontSize: 15,
                    marginTop: 3,
                    textAlign: this.state.currLang === 'ar' ? 'right' : '',
                  }}
                >
                  {item.name}
                </Text>
                {item.location ? (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginRight: this.state.currLang === 'ar' ? 0 : 15,
                      marginTop: 5,
                    }}
                  >
                    <Image
                      source={require("../../assets/img/location.png")}
                      style={{
                        height: 12,
                        width: 12,
                        tintColor: color.lableColor,
                      }}
                    />
                    <Text
                      numberOfLines={2}
                      style={{
                        ...fontReg,
                        color: color.lableColor,
                        marginLeft:  this.state.currLang === 'ar' ? 0  : 5,
                        marginRight:  this.state.currLang === 'ar' ? 5  : 0,
                        fontSize: 10,
                      }}
                    >
                      {item.location}
                    </Text>
                  </View>
                ) : null}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flex: 1,
                  }}
                >
                  <View
                    style={{
                      justifyContent: "flex-end",
                      alignItems: "baseline",
                    }}
                  >
                    {item.typeofsell === "Buy it Now" ? (
                      <Text
                        style={{
                          ...fontBold,
                          color: "#E2583A",
                          fontSize: 14,
                          marginBottom: -10,
                        }}
                      >
                        SAR{" "}{item.saleprice + ""}
                      </Text>
                    ) : (
                      <Text
                        style={{
                          ...fontBold,
                          color: "#E2583A",
                          fontSize: 14,
                        }}
                      >
                        {/* SAR{" "}{item.saleprice} */}
                      </Text>
                    )}
                    {item.typeofsell === "Auction" ? (
                      <Text
                        style={{
                          ...fontBold,
                          color: "#E2583A",
                          fontSize: 14,
                        }}
                      >
                        SAR{" "}
                        {item.maximum_bid_price
                          ? item.maximum_bid_price + ""
                          : item.aucationminimum_amount + ""}
                      </Text>
                    ) : (
                      <Text
                        style={{
                          ...fontBold,
                          color: "#E2583A",
                          fontSize: 14,
                        }}
                      >
                        {/* SAR{" "}{item.saleprice} */}
                      </Text>
                    )}
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 15,
                    }}
                  >
                    {item.typeofsell === "Auction" ? (
                      <View
                        style={{
                          alignSelf: "flex-end",
                          flexDirection: "row",
                          alignItems: "center",
                          borderRadius: 26,
                          paddingVertical: 8,
                          paddingHorizontal: 10,
                          backgroundColor: color.primeColor,
                        }}
                      >
                        <Image
                          source={require("../../assets/img/clock.png")}
                          style={{ height: 15, width: 15 }}
                        />
                        <Text
                          style={{
                            ...fontBold,
                            color: "#000",
                            marginLeft:  this.state.currLang === 'ar' ? 0  : 8,
                            marginRight:   this.state.currLang === 'ar' ? 8 : 0,
                            fontSize: 12,
                            textAlignVertical: "center",
                          }}
                        >
                          <Coundown finishTime={item.auction_endtime} />
                        </Text>
                      </View>
                    ) : null}
                  </View>
                </View>
              </View>
            </TouchableOpacity>
          )}
          ListEmptyComponent={
            !isRefreshing && (
              <View style={{ flex: 1 }}>
                {!isLoading ? (
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      source={require("../../assets/img/nodata.png")}
                      style={{
                        height: 60,
                        width: 60,
                        tintColor: color.primeColor,
                      }}
                    />
                    <Text
                      style={{
                        ...fontBold,
                        textAlign: "center",
                        marginTop: 10,
                        color: color.lableColor,
                      }}
                    >
                      {this.props.t('Data not found')}!
                    </Text>
                  </View>
                ) : (
                  <>
                    {[1, 2, 3, 4, 5].map(() => {
                      return (
                        <View
                          style={{
                            flexDirection: "row",
                            // backgroundColor: "#a1a1a1"
                          }}
                        >
                          <View
                            style={{
                              height: 100,
                              width: 100,
                              overflow: "hidden",
                              marginBottom: 10
                            }}
                          >
                            <Skeleton
                              height={100}
                              width={100}
                              containerClassName="avatar-skeleton"
                            />
                          </View>
                          <View
                            style={{
                              paddingLeft: 18,
                              flex: 1,
                              width: "100%",
                              // backgroundColor: "#b25b25",
                            }}
                          >
                            <Skeleton width="100%"  style={{
                                  marginBottom: 10
                                }}/>
                            <Skeleton width="40%"  style={{
                                  marginBottom: 10
                                }}/>
                            <Skeleton width="60%"  style={{
                                  marginBottom: 10
                                }}/>
                            <Skeleton width="80%"  style={{
                                  marginBottom: 10
                                }}/>
                          </View>
                        </View>
                      );
                    })}
                  </>
                )}
              </View>
            )
          }
          keyExtractor={(item, index) => index + ""}
          onEndReachedThreshold={0.1}
          onEndReached={() => this.WatchListApi()}
          ListFooterComponent={this.renderFooter}
          refreshControl={
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={() => this.onRefresh()}
            />
          }
        />
        <FootTab />
      </View>
    );
  }
}

export default withTranslation()(withRouter(WatchList));
