import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Dimensions,
  FlatList,
  ActivityIndicator,
  RefreshControl,
} from "react-native";
import { API } from "../../Privet";
import ImageLoader from "../Component/ImageLoader";
import moment from "moment";
import Coundown from "../Component/Coundown";
import Header from "../Component/Header";
import { color, font, fontBold, fontReg, shadow } from "../Component/Styles";
// import SkeletonPlaceholder from "react-native-skeleton-placeholder";
import { clearUserLocalStorage, getUserData } from "../../utility/Utils";

import Skeleton from "react-loading-skeleton";
import NImageLoader from "../NComponent/NImageLoader";
import { withRouter } from "../../withRouter";
import FootTab from "./FootTab";
import { withTranslation } from 'react-i18next';

const { width } = Dimensions.get("window");

const userData = getUserData();

class Activebids extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isRefreshing: false,
      ActiveBidList: [],
      totalPage: 1,
      page: 1,
      isSearch: false,
      text: "",
      currLang: window.localStorage.getItem("lang") ?? "en",
    };
    this.arrayHold = [];
  }
  async componentDidMount() {
    this.ActiveBicApi();
  }

  willFocus() {
    this.ActiveBicApi();
  }

  timecalculate(expirydate) {
    var date = moment().utcOffset("+03:00").format("YYYY-MM-DD HH:mm:ss");
    var diffr = moment.duration(moment(expirydate).diff(moment(date)));
    var hours = parseInt(diffr.asHours());
    var minutes = parseInt(diffr.minutes());
    var seconds = parseInt(diffr.seconds());
    var d = hours * 60 * 60 + minutes * 60 + seconds;

    return d;
  }
  async ActiveBicApi() {
    const { token } = userData;
    const { totalPage, page, isLoading, isRefreshing, ActiveBidList } =
      this.state;
    if (totalPage >= page) {
      let formdata = new FormData();
      formdata.append("page", page + "");
      formdata.append("perPage", "15");

      await fetch(API.activeBids, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        body: formdata,
      })
        .then((response) => {
          if (response.status === 401) {
            clearUserLocalStorage();
            this.props.navigate("/login");
          }
          return response.json();
        })
        .then((res) => {
          if (res.success) {
            if (isLoading || isRefreshing || ActiveBidList.length <= 0) {
              this.setState({
                isLoading: false,
                isRefreshing: false,
                ActiveBidList: res.data.collection,
                totalPage: res.data.lastPage,
                page: page + 1,
              });
              this.arrayHold = res.data.collection;
            } else {
              this.setState({
                isLoading: false,
                isRefreshing: false,
                ActiveBidList: [...ActiveBidList, ...res.data.collection],
                totalPage: res.data.lastPage,
                page: page + 1,
              });
              this.arrayHold = [...ActiveBidList, ...res.data.collection];
            }
          } else {
            this.setState({
              isLoading: false,
              isRefreshing: false,
              page: page + 1,
              ActiveBidList: [],
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isRefreshing: false,
            page: page + 1,
          });
          console.log(error);
        })
        .finally(() => {
          this.setState({
            isLoading: false,
            isRefreshing: false,
            page: page + 1,
          });
        });
    }
  }

  renderFooter = () => {
    const { totalPage, page, isLoading, isRefreshing } = this.state;
    if ((totalPage >= page && !isRefreshing) || isLoading) {
      return (
        <View
          style={{
            height: 40,
            width: 40,
            borderRadius: 40,
            ...shadow,
            alignSelf: "center",
            marginTop: 20,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator color={color.primeColor} style={{}} />
        </View>
      );
    } else {
      return null;
    }
  };

  onRefresh() {
    this.setState(
      {
        totalPage: 1,
        page: 1,

        isRefreshing: true,
      },
      () => this.ActiveBicApi()
    );
  }

  search(text) {
    this.setState({ text });
    const data = this.arrayHold.filter((item) => {
      const itemData =
        `${item.productDetail.name} ${item.productDetail.location} ${item.productDetail.category_name} ${item.productDetail.sub_category_name}`.toUpperCase();
      return itemData.indexOf(text.toUpperCase()) > -1;
    });
    this.setState({ ActiveBidList: data });
  }

  render() {
    const {
      isLoading,
      isRefreshing,
      ActiveBidList,
      isSearch,
      text,
      showDrawer = false,
    } = this.state;
    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <Header
          showDrawer={showDrawer}
          closeDrawer={() => this.setState({ showDrawer: false })}
          title={this.props.t("Active bids")}
          Limg={require("../../assets/img/homemenu.png")}
          Lpress={() => this.setState({ showDrawer: true })}
          Rimg={require("../../assets/img/search.png")}
          Rpress={() => this.setState({ isSearch: true })}
          _onWillFocus={() => this.willFocus()}
          searchBar={isSearch}
          closeSearchBar={() =>
            this.setState({
              isSearch: false,
              ActiveBidList: this.arrayHold,
              text: "",
            })
          }
          texts={text}
          searchFilterFunction={(text) => this.search(text)}
        />
        <FlatList
          contentContainerStyle={{
            paddingBottom: 25,
            flexGrow: 1,
            paddingHorizontal: 20,
            paddingTop: 15,
          }}
          data={ActiveBidList}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              style={{ flexDirection: "row", marginBottom: 20 }}
              onPress={() =>
                this.props.navigate("/product-detail", {
                  state: { id: item.productDetail.id, type: "ActiveBid" },
                })
              }
            >
              <View
                style={{
                  height: 88,
                  width: 120,
                  borderRadius: 10,
                  overflow: "hidden",
                  marginLeft:  this.state.currLang === 'ar' ? 10 : 0,
                }}
              >
                <NImageLoader
                  imageSrc={
                    item.productDetail.image
                      ? item.productDetail.image
                      : require("../../assets/img/noimg.jpeg")
                  }
                  imageAlt={item.name}
                  imageStyle={{
                    verticalAlign: "middle",
                    height: "100%",
                    width: "100%",
                  }}
                />
              </View>
              <View style={{ paddingLeft: 18, flex: 1 }}>
                <Text
                  style={{
                    ...fontBold,
                    color: "#000",
                    fontSize: 15,
                    marginTop: 3,
                    textAlign: this.state.currLang === 'ar' ? 'right' : '',
                  }}
                >
                  {item.productDetail.name}
                </Text>
                {item.productDetail.location ? (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginRight: this.state.currLang === 'ar' ? 0 : 15,
                      marginTop: 5,
                    }}
                  >
                    <Image
                      source={require("../../assets/img/location.png")}
                      style={{
                        height: 12,
                        width: 12,
                        tintColor: color.lableColor,
                      }}
                    />
                    <Text
                      numberOfLines={2}
                      style={{
                        ...fontReg,
                        color: color.lableColor,
                        marginLeft:  this.state.currLang === 'ar' ? 0  : 5,
                        marginRight:  this.state.currLang === 'ar' ? 5  : 0,
                        fontSize: 10,
                      }}
                    >
                      {item.productDetail.location}
                    </Text>
                  </View>
                ) : null}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flex: 1,
                  }}
                >
                  <View
                    style={{
                      justifyContent: "flex-end",
                      alignItems: "baseline",
                    }}
                  >
                    {item.productDetail.typeofsell === "Buy it Now" ? (
                      <Text
                        style={{
                          ...fontBold,
                          color: "#E2583A",
                          fontSize: 14,
                        }}
                      >
                        SAR{" "} {item.productDetail.saleprice + ""}
                      </Text>
                    ) : (
                      <Text
                        style={{
                          ...fontBold,
                          color: "#E2583A",
                          fontSize: 14,
                        }}
                      >
                        {/* SAR{" "}{item.saleprice} */}
                      </Text>
                    )}
                    {item.productDetail.typeofsell === "Auction" ? (
                      <Text
                        style={{
                          ...fontBold,
                          color: "#E2583A",
                          fontSize: 14,
                        }}
                      >
                        SAR{" "}
                        { item.productDetail.maximum_bid_price
                          ? item.productDetail.maximum_bid_price + ""
                          : item.productDetail.aucationminimum_amount + ""}
                      </Text>
                    ) : (
                      <Text
                        style={{
                          ...fontBold,
                          color: "#E2583A",
                          fontSize: 14,
                        }}
                      >
                        {/* SAR{" "}{item.saleprice} */}
                      </Text>
                    )}
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 15,
                    }}
                  >
                    {item.productDetail.typeofsell === "Auction" ? (
                      <View
                        style={{
                          alignSelf: "flex-end",
                          flexDirection: "row",
                          alignItems: "center",
                          borderRadius: 26,
                          paddingVertical: 8,
                          paddingHorizontal: 10,
                          backgroundColor: color.primeColor,
                        }}
                      >
                        <Image
                          source={require("../../assets/img/clock.png")}
                          style={{ height: 15, width: 15 }}
                        />
                        <Text
                          style={{
                            ...fontBold,
                            color: "#000",
                            marginLeft:  this.state.currLang === 'ar' ? 0  : 8,
                            marginRight:   this.state.currLang === 'ar' ? 8 : 0,
                            fontSize: 12,
                            textAlignVertical: "center",
                          }}
                        >
                          <Coundown
                            finishTime={item.productDetail.auction_endtime}
                          />
                        </Text>
                      </View>
                    ) : null}
                  </View>
                </View>
              </View>
            </TouchableOpacity>
          )}
          ListEmptyComponent={
            <View style={{ flex: 1 }}>
              {!isLoading ? (
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={require("../../assets/img/nodata.png")}
                    style={{
                      height: 60,
                      width: 60,
                      tintColor: color.primeColor,
                    }}
                  />
                  <Text
                    style={{
                      ...fontBold,
                      textAlign: "center",
                      marginTop: 10,
                      color: color.lableColor,
                    }}
                  >
                    {this.props.t('Data not found')}!
                  </Text>
                </View>
              ) : (
                <>
                  {[1, 2, 3, 4, 5].map(() => {
                    return (
                      <View
                        style={{
                          flexDirection: "row",
                          // backgroundColor: "#a1a1a1"
                        }}
                      >
                        <View
                          style={{
                            height: 100,
                            width: 100,
                            overflow: "hidden",
                          }}
                        >
                          <Skeleton
                            height={100}
                            width={100}
                            containerClassName="avatar-skeleton"
                          />
                        </View>
                        <View
                          style={{
                            paddingLeft: 18,
                            flex: 1,
                            width: "100%",
                            // backgroundColor: "#b25b25",
                          }}
                        >
                          <Skeleton width="100%" style={{ marginBottom: 10}} />
                          <Skeleton width="40%" style={{ marginBottom: 10}} />
                          <Skeleton width="60%" style={{ marginBottom: 10}} />
                          <Skeleton width="80%" style={{ marginBottom: 10}} />
                        </View>
                      </View>
                    );
                  })}
                </>
              )}
            </View>
          }
          keyExtractor={(item, index) => index + ""}
          onEndReachedThreshold={0.1}
          onEndReached={() => this.ActiveBicApi()}
          ListFooterComponent={this.renderFooter}
          refreshControl={
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={() => this.onRefresh()}
            />
          }
        />
        <FootTab />
      </View>
    );
  }
}

export default withTranslation()(withRouter(Activebids));
