import React, { Component } from "react";

// import SplashScreen from 'react-native-splash-screen';

import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ActivityIndicator,
  Image,
  Button,
  ScrollView,
} from "react-native";
// import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  font,
  color,
  activityIndicator,
  TextInputStyle,
  fontWeight,
} from "../Component/Styles";
import { API } from "../../Privet";
import { withRouter } from "../../withRouter";
import { checkOnlyNumber } from "../../utility/Utils";
import SnackBar from "../Component/SnackBar";
import i18n from "../../i18n";

import { withTranslation } from "react-i18next";

// const RN = Platform.OS === 'ios' ? require('react-native') : require('react-native-safe-area-context');
// const { SafeAreaView } = RN;
// import { Navigate } from "react-router-dom";
// import { useHistory } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      mobile: "",
      active: false,
      isReload: false,
      language: window.localStorage.getItem("lang") ?? "en",
      direction: window.localStorage.getItem("direction") ?? "ltr",
    };
  }

  componentDidMount() {
    this.setState({ isReload: this.props.location.state });

    // console.log("Login 41", this.state.isReload, this.props.location);
    if (this.state.isReload) {
      this.setState({ isReload: false });
      window.location.reload();
    }
    // const { navigation } = this.props;
    // SplashScreen.hide();
  }

  willFocus() {
    this.setState({ isLoading: false, mobile: "" });
  }

  async login() {
    const { mobile } = this.state;
    Keyboard.dismiss();

    if (!mobile) {
      this.refs.RNSnackBar.show(this.props.t("Please enter mobile number."));
    } else {
      this.setState({ isLoading: true });

      let formdata = new FormData();
      formdata.append("mobile", mobile);
      console.log("Login.js 42", API.sendotp);
      await fetch(API.sendotp, {
        method: "POST",
        body: formdata,
      })
        .then((response) => response.json())
        .then(async (response) => {
          if (response.result === "success") {
            this.setState({ isLoading: false, data: response.data });
            this.props.navigate("/otp", {
              state: {
                OtpSend: response.data,
                mobile: this.state.mobile,
              },
            });
            // navigation.setItem({
            //   OtpSend: response.data,
            // });
            // console.log("Login.js 67", this.state, this.props);
            // if (response.data) {
            //   console.log("Login.js 77", this.state);
            //   //   await AsyncStorage.setItem('otp', JSON.stringify(response));
            // }
          } else {
            this.setState({ isLoading: false });
            this.refs.RNSnackBar.show(response.message);
          }
        })
        .catch((e) => {
          console.log("Login.js catch: ", e);
          this.refs.RNSnackBar.show("Something is wrong!");
          this.setState({ isLoading: false });
        });
    }
  }

  changeLanguage(lng) {
    i18n.changeLanguage(lng);
    const element = document.getElementsByTagName("html")[0];
    this.state.language = lng;

    if (lng === "ar") {
      window.localStorage.setItem("lang", lng);
      window.localStorage.setItem("direction", "rtl");
      element.setAttribute("dir", "rtl");
    } else {
      window.localStorage.setItem("direction", "ltr");
      window.localStorage.setItem("lang", lng);
      element.setAttribute("dir", "ltr");
    }
  }

  render() {
    const { isLoading, mobile } = this.state;
    return (
      <KeyboardAvoidingView
        style={{ flex: 1, backgroundColor: "#ffff" }}
        behavior={Platform.OS === "ios" ? "padding" : null}
      >
        {/* <SafeAreaView backgroundColor={color.primeColor} /> */}

        <ScrollView bounces={false} contentContainerStyle={{ flexGrow: 1 }}>
          <View style={{ flex: 1 }}>
            {/* Logo  */}
            <View
              style={{
                height: "30%",
                backgroundColor: color.primeColor,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Image
                source={require("../../assets/img/logo.png")}
                style={{ height: 80, width: 180, alignSelf: "center" }}
              />
            </View>
            {/* Sign In  */}
            <View
              style={{
                backgroundColor: "#ffff",
                paddingHorizontal: 20,
                paddingTop: 20,
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 1 }}>
                  <Text
                    style={{
                      fontFamily: font.bold,
                      fontWeight: fontWeight.bold,
                      color: "#000",
                      fontSize: 30,
                    }}
                  >
                    {this.props.t("Sign In")}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <View
                    style={{
                      height: 40,
                      width: 40,
                      borderRadius: 30,
                      marginRight: 10,
                    }}
                  >
                    <Image
                      source={require("../../assets/img/flag.png")}
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: 30,
                        borderWidth: 1,
                        borderColor: color.borderColor,
                      }}
                    />
                  </View>
                  <Image
                    source={require("../../assets/img/dropdown.png")}
                    style={{
                      height: 13,
                      width: 13,
                      tintColor: color.lableColor,
                      alignSelf: "center",
                    }}
                  />
                </View>
              </View>
              <Text
                style={{
                  fontFamily: font.thin,
                  fontWeight: fontWeight.thin,
                  color: "#000",
                  marginTop: 10,
                  fontSize: 15,
                }}
              >
                {this.props.t("Enter your mobile number")}
              </Text>
              <View
                borderColor={mobile ? "#000" : color.borderColor}
                style={{
                  borderWidth: 1,
                  borderRadius: 6,
                  marginTop: 40,
                  alignItems: "center",
                  flexDirection: "row",
                  paddingHorizontal: 15,
                  paddingVertical: 5,
                }}
              >
                <Text
                  style={{
                    fontFamily: font.thin,
                    fontWeight: fontWeight.thin,
                    color: color.lableColor,
                    fontSize: 14,
                  }}
                >
                  +961
                </Text>
                <TextInput
                  // style={{
                  //   paddingLeft: 5,
                  //   flex: 1,
                  //   color: mobile ? "#000" : color.lableColor,
                  //   fontFamily: font.thin,
                  //   fontSize: 14,
                  // }}
                  style={{
                    ...TextInputStyle,
                    borderWidth: 0,
                    paddingLeft: 5,
                    color: mobile ? "#000" : color.lableColor,
                    textAlign: this.state.language === 'ar' ? 'end' : ''
                  }}
                  placeholder={this.props.t("mobile number")}
                  keyboardType="number-pad"
                  value={mobile}
                  onChangeText={(mobile) => {
                    let checkItsNumber = checkOnlyNumber(mobile);
                    if (mobile !== checkItsNumber) {
                      this.refs.RNSnackBar.show(checkItsNumber + "");
                    } else {
                      this.setState({ mobile });
                    }
                  }}
                />
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: 36,
                    width: 30,
                  }}
                >
                  <Image
                    source={require("../../assets/img/mobile.png")}
                    style={{
                      width: "50%",
                      height: "65%",
                      alignSelf: "center",
                      tintColor: mobile ? "#000" : color.lableColor,
                    }}
                  />
                </View>
              </View>
              <TouchableOpacity
                disabled={isLoading}
                onPress={() => this.login()}
                style={{
                  backgroundColor: mobile ? "#000" : "#E9E9E9",
                  paddingVertical: 13,
                  marginTop: 50,
                  borderRadius: 25,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isLoading ? (
                  <ActivityIndicator style={activityIndicator} />
                ) : (
                  <Text
                    style={{
                      fontFamily: font.bold,
                      fontWeight: fontWeight.bold,
                      color: "#ffff",
                      fontSize: 16,
                    }}
                  >
                    {this.props.t("Next")}
                  </Text>
                )}
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
        {/* language section */}
        <View style={{ marginTop: 15, alignItems: "center", marginBottom: 20 }}>
          <Text style={{ fontFamily: font.bold, fontSize: 15, color: "#000" }}>
            {this.props.t("Language")}
          </Text>
          <View style={{ flexDirection: "row", marginTop: 10 }}>
            <TouchableOpacity
              disabled={this.state.language === "en"}
              onPress={() => this.changeLanguage("en")}
              style={{
                backgroundColor:
                  this.state.language === "en" ? color.primeColor : "#E9E9E9",
                margin: 10,
                paddingHorizontal: 30,
                paddingVertical: 10,
                borderRadius: 50,
              }}
            >
              <Text style={{ fontFamily: font.bold, fontSize: 15 }}>
                English
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              disabled={this.state.language === "ar"}
              onPress={() => this.changeLanguage("ar")}
              style={{
                backgroundColor:
                  this.state.language === "ar" ? color.primeColor : "#E9E9E9",
                margin: 10,
                paddingHorizontal: 30,
                paddingVertical: 10,
                borderRadius: 50,
              }}
            >
              <Text style={{ fontFamily: font.bold, fontSize: 15 }}>
                {this.props.t("Arabic")}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <SnackBar ref="RNSnackBar" />
      </KeyboardAvoidingView>
    );
  }
}

export default withTranslation()(withRouter(Login));
