import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  RefreshControl,
} from "react-native";
import Header from "../Component/Header";
import { color, font, fontBold } from "../Component/Styles";
import { API } from "../../Privet";

import Skeleton from "react-loading-skeleton";
import { getUserData } from "../../utility/Utils";
import { withRouter } from "../../withRouter";
import FootTab from "./FootTab";

import { withTranslation } from 'react-i18next';

const userData = getUserData();

class FavouriteSeller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isRefreshing: false,
      showDrawer: false,
      FavoriteSellerList: [],
      currLang: window.localStorage.getItem("lang") ?? "en",
    };
  }
  async componentDidMount() {
    this.FavoriteSellerListAPI();
  }

  willFocus() {
    this.FavoriteSellerListAPI();
  }

  async FavoriteSellerListAPI() {
    const { token, userid } = userData;
    this.setState({ isLoading: true });

    let formdata = new FormData();
    formdata.append("appuser_id", userid);

    await fetch(API.userFavoriteSellerList, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          this.setState({ isLoading: false, FavoriteSellerList: res.data });
          this.arrayHold = res.data;
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }

  search(text) {
    this.setState({ text });
    const data = this.arrayHold.filter((item) => {
      const itemData =
        `${item.name} ${item.lname} ${item.email}  ${item.mobile}`.toUpperCase();
      return itemData.indexOf(text.toUpperCase()) > -1;
    });
    this.setState({ FavoriteSellerList: data });
  }
  render() {
    const {
      isLoading,
      FavoriteSellerList,
      isSearch,
      text,
      showDrawer = false,
    } = this.state;

    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <Header
          title={this.props.t("Favourite Sellers")}
          Limg={require("../../assets/img/homemenu.png")}
          showDrawer={showDrawer}
          closeDrawer={() => this.setState({ showDrawer: false })}
          Lpress={() => this.setState({ showDrawer: true })}
          Rimg={require("../../assets/img/search.png")}
          Rpress={() => this.setState({ isSearch: true })}
          _onWillFocus={() => this.willFocus()}
          searchBar={isSearch}
          closeSearchBar={() =>
            this.setState({
              isSearch: false,
              FavoriteSellerList: this.arrayHold,
              text: "",
            })
          }
          texts={text}
          searchFilterFunction={(text) => this.search(text)}
        />
        <FlatList
          contentContainerStyle={{
            paddingBottom: 25,
            flexGrow: 1,
            paddingHorizontal: 20,
            paddingTop: 15,
          }}
          data={FavoriteSellerList}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              onPress={() =>
                this.props.navigate("/seller-profile", {
                  state: {
                    sellerid: item.id,
                  },
                })
              }
              style={{
                flexDirection: "row",
                paddingBottom: 20,
              }}
            >
              {item.imageURL ? (
                <Image
                  source={{ uri: item.imageURL }}
                  style={{
                    height: 70,
                    width: 70,
                    borderRadius: 70,
                    borderWidth: 1,
                    borderColor: color.borderColor,
                  }}
                />
              ) : (
                <Image
                  source={require("../../assets/img/user.png")}
                  style={{
                    height: 70,
                    width: 70,
                    borderRadius: 70,
                    borderWidth: 1,
                    borderColor: color.borderColor,
                  }}
                />
              )}
              <View style={{ 
                marginLeft: this.state.currLang  === 'ar' ? 0 : 15, 
                marginRight: this.state.currLang  === 'ar' ? 15 : 0,
                marginVertical: 5 
              }}
              >
                <Text
                  style={{
                    ...fontBold,
                    color: "#000",
                    fontSize: 15,
                    marginTop: 3,
                    marginBottom: 5,
                  }}
                >
                  {item.name} {item.lname}
                </Text>
                <Text
                  style={{
                    ...fontBold,
                    color: "#000",
                    fontSize: 15,
                    marginTop: 3,
                  }}
                >
                  {item.countOfSalledItem} {this.props.t('items for sale')}
                </Text>
              </View>
            </TouchableOpacity>
          )}
          ListEmptyComponent={
            <View style={{ flex: 1 }}>
              {!isLoading ? (
                <View
                  style={{
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    source={require("../../assets/img/nodata.png")}
                    style={{
                      height: 60,
                      width: 60,
                      tintColor: color.primeColor,
                    }}
                  />
                  <Text
                    style={{
                      ...fontBold,
                      textAlign: "center",
                      marginTop: 10,
                      color: color.lableColor,
                    }}
                  >
                    {this.props.t('Data not found')}!
                  </Text>
                </View>
              ) : (
                <>
                  {[1, 2, 3].map(() => {
                    return (
                      <View
                        style={{
                          flexDirection: "row",
                          // backgroundColor: "#a1a1a1"
                        }}
                      >
                        <View
                          style={{
                            height: 100,
                            width: 100,
                            overflow: "hidden",
                          }}
                        >
                          <Skeleton
                            circle
                            height={80}
                            width={80}
                            containerClassName="avatar-skeleton"
                            style = {{ marginBottom: 10 }}
                          />
                        </View>
                        <View
                          style={{
                            paddingLeft: 18,
                            flex: 1,
                            width: "100%",
                            // backgroundColor: "#b25b25",
                          }}
                        >
                          <Skeleton width="100%"  style = {{ marginBottom: 10 }}/>
                          <Skeleton width="40%"  style = {{ marginBottom: 10 }}/>
                          <Skeleton width="60%"  style = {{ marginBottom: 10 }}/>
                        </View>
                      </View>
                    );
                  })}
                </>
              )}
            </View>
          }
          keyExtractor={(item, index) => index + ""}
          refreshControl={
            <RefreshControl
              refreshing={isLoading}
              onRefresh={() =>
                this.setState({ isLoading: true }, () =>
                  this.FavoriteSellerListAPI()
                )
              }
            />
          }
        />
        <FootTab />
      </View>
    );
  }
}

export default withTranslation()(withRouter(FavouriteSeller));
