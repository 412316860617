import React, { Component } from "react";
// import SplashScreen from 'react-native-splash-screen';
import {
  View,
  Text,
  Image,
  Modal,
  FlatList,
  ActivityIndicator,
  TextInput,
  RefreshControl,
  KeyboardAvoidingView,
  SafeAreaView,
  TouchableOpacity,
} from "react-native";
// import AsyncStorage from '@react-native-async-storage/async-storage';

/** Components */
import Header from "../Component/Header";
import { color, font, fontBold, fontReg, shadow } from "../Component/Styles";
import Coundown from "../Component/Coundown";
import SnackBar from "../Component/SnackBar";
import NImageLoader from "../NComponent/NImageLoader";
import FootTab from "./FootTab";

/** Utilitys  */
import { API } from "../../Privet";
import { clearUserLocalStorage, getUserData } from "../../utility/Utils";

import moment from "moment";
import { withRouter } from "../../withRouter";

import Select, { components } from "react-select";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// import Slider, { Range } from 'rc-slider';
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
// import { ScrollView } from 'react-native-gesture-handler';

// import messaging from '@react-native-firebase/messaging';
// import { Notifications } from 'react-native-notifications';
import { io } from "socket.io-client";

import { withTranslation } from "react-i18next";

const userData = getUserData();

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isRefreshing: false,
      productList: [],
      totalPage: 1,
      page: 1,
      isVisible: false,
      isVisibleSearch: false,
      isExpand: false,
      isExpandLoading: false,
      msg: "",
      subId: "",
      type: "",
      input: "",
      active: false,
      sort: "",

      categoryList: [],
      CategoryName: [],
      selectedCategoryName: [],

      subcategoryList: [],
      subcategorySelectedList: [],
      subCategoryName: [],

      low: 0,
      high: 0,

      disabled: true,
      data: "",

      deviceHeight: null,
      deviceWidth: "468",
      currLang: window.localStorage.getItem("lang") ?? "en",
      noOptionsText: "No Options",
    };
    this.socket = io("https://app.ekushk.com:3001");

    /*Notifications.events().registerNotificationReceivedForeground((notification, completion) => {
      completion({ alert: true, sound: true, badge: true });
    });
    
    Notifications.events().registerNotificationOpened(async (notification, completion) => {
      console.log(`Notification opened: A `, notification);
      
      try {
        if (notification?.payload?.data?.type === 'Product Detail') {
          setTimeout(() => {
            Global.main.navigateTo('ProductDetail', { id: notification?.payload?.data?.id })
          }, 1000);
          // this.props.navigation.navigate('ProductDetail', { id: notification?.payload?.data?.id })
        }
        else if (notification?.payload?.data?.type === 'chat_message') { // When Message Open
          const notificationdata = await JSON.parse(notification?.payload.data.message)
          if (notificationdata?.sender_id) {
            setTimeout(() => {
              Global.main.navigateTo('MessageDetails', { sellerid: notificationdata.sender_id, sender_profile: '', sender_name: '' })
            }, 1000);
          }
        }
        
        completion();
      } catch (error) {
        console.log('BG Noti. Open Error: ', error)
      }
      
    });*/
  }

  async componentDidMount() {
    let { deviceHeight, deviceWidth } = JSON.parse(
      await localStorage.getItem("kushkDevice")
    );
    // deviceWidth = deviceWidth;
    await this.setState({ deviceHeight, deviceWidth });

    // const type = await this.props.navigation.getParam('type');
    // const subId = await this.props.navigation.getParam('subId');

    const { type, subId } = this.props.location.state
      ? await this.props.location.state
      : "";

    await this.setState({ type, subId });
    await this.messageusersAPI();

    try {
      this.socket.onAny((key, data) => {
        if (key.endsWith("_" + userData.userid)) {
          this.messageusersAPI();
        }
      });
    } catch (error) {
      console.log(error);
    }

    await this.CategoryList();
    await this.ProductList();

    /* await messaging().setBackgroundMessageHandler(async remoteMessage => {
      
    });
    
    this.unsubscribe = await messaging().onMessage(async remoteMessage => {
      if (remoteMessage.data.type === 'chat_message') {
        const { sender_id } = await JSON.parse(remoteMessage.data.message);
        if (sender_id != Global.main.state.sellerid) {
          let localNotification = await Notifications.postLocalNotification({
            body: remoteMessage.notification.body,
            title: remoteMessage.notification.title,
            silent: false,
            data: remoteMessage.data,
          }, remoteMessage.sentTime);
        }
      }
      else {
        let localNotification = await Notifications.postLocalNotification({
          body: remoteMessage.notification.body,
          title: remoteMessage.notification.title,
          silent: false,
          data: remoteMessage.data,
        }, remoteMessage.sentTime);
      }
    });
    
    messaging()
    .getInitialNotification()
    .then(async remoteMessage => {
      try {
        if (remoteMessage?.data?.type === 'chat_message') {
          const notificationdata = JSON.parse(remoteMessage?.data.message)
          if (notificationdata?.sender_id) {
            this.props.navigation.navigate('MessageDetails', { sellerid: notificationdata.sender_id, sender_profile: '', sender_name: '' })
          }
        }
        else if (remoteMessage?.data?.type === 'Product Detail') {
          this.props.navigation.navigate('ProductDetail', { id: remoteMessage?.data?.id })
        }
      } catch (error) {
        console.log("Error", error)
      }
    });
    
    messaging().setBackgroundMessageHandler(async remoteMessage => {
    });
    
    messaging().onNotificationOpenedApp(remoteMessage => {
      try {
        if (remoteMessage?.data?.type === 'chat_message') {
          const notificationdata = JSON.parse(remoteMessage?.data.message)
          if (notificationdata?.sender_id) {
            this.props.navigation.navigate('MessageDetails', { sellerid: notificationdata.sender_id, sender_profile: '', sender_name: '' })
          }
        }
        
        if (remoteMessage?.data?.type === 'Product Detail') {
          this.props.navigation.navigate('ProductDetail', { id: remoteMessage?.data?.id })
        }
      } catch (error) {
        console.log("Error", error)
      }
    });*/
  }

  componentWillUnmount() {
    // let userData = getUserData();
    // console.log("175", userData);
    // this.unsubscribe;
    // this.socket.close();
  }

  CategoryList() {
    const { token } = getUserData();

    fetch(API.maincategorylist, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          clearUserLocalStorage();
          this.props.navigate("/login", { state: { isReload: true } });
          // this.props.navigate("/login");
        }
        return response.json();
      })
      .then((res) => {
        if (res.success) {
          this.setState({
            isLoading: false,
            isRefreshing: false,
            categoryList: res.data,
          });
          // console.log("Home.js 220", res.data);
        } else {
          this.setState({ isLoading: false, isRefreshing: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, isRefreshing: false });
        // console.log("236 ", error);
      });
  }

  async SubCategoryList(id) {
    const { token } = getUserData();

    this.setState({
      subcategoryList: [],
    });
    const { CategoryName } = this.state;
    this.setState({ selectedCategoryName: CategoryName });
    let ids = "";
    CategoryName.forEach((item) => {
      ids = ids + "," + item.value;
    });
    ids = ids.substring(1);
    if (!ids) {
      this.setState({ subcategoryList: [] });
      return;
    }

    let formdata = new FormData();
    formdata.append("ids", ids);

    await fetch(API.subcategorylist, {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          clearUserLocalStorage();
          this.props.navigate("/login");
        }
        return response.json();
      })
      .then((res) => {
        if (res.success) {
          this.setState({
            isLoading: false,
            isRefreshing: false,
            subcategoryList: res.data,
          });
        } else {
          this.setState({ isLoading: false, isRefreshing: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, isRefreshing: false });
        console.log(error);
      });
  }

  async ProductList() {
    const { token } = getUserData();
    // console.log("Home.js 352", token);
    const {
      totalPage,
      page,
      isLoading,
      isRefreshing,
      productList,
      CategoryName,
      selectedCategoryName,
      subcategorySelectedList,
      subId,
      low,
      high,
      type,
      sort,
      input,
    } = this.state;
    if (totalPage >= page) {
      let formdata = new FormData();
      formdata.append("is_api", "dashboard");
      formdata.append("page", page + "");
      formdata.append("perPage", "10");

      let category_ids = "";
      if (CategoryName.length === 0 && selectedCategoryName.length > 0) {
        selectedCategoryName.forEach((item) => {
          // category_ids = category_ids + "," + item.value ? item.value : item.id;
          category_ids = category_ids + "," + item.value;
        });
      }
      CategoryName.forEach((item) => {
        // category_ids = category_ids + "," + item.value ? item.value : item.id;
        category_ids = category_ids + "," + item.value;
      });
      // console.log("CategoryName 328", CategoryName,subcategorySelectedList,category_ids);
      category_ids = category_ids.substring(1);

      let subcategory_ids = "";
      subcategorySelectedList.forEach((item) => {
        subcategory_ids = subcategory_ids + "," + item.value;
      });
      subcategory_ids = subcategory_ids.substring(1);

      let ids = category_ids;
      if (subcategory_ids) {
        ids = ids + "," + subcategory_ids;
      }

      formdata.append("filter_price_start", low + "");
      formdata.append("filter_price_end", high + "");

      if (type === "Subcategory") {
        formdata.append("filter_category", subId + "");
      } else {
        formdata.append("filter_category", ids + "");
      }

      formdata.append("filter_sort", sort);
      formdata.append("filter_input", input);
      // console.log("Home.js 403", formdata, this.state);
      await fetch(API.productlist, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        body: formdata,
      })
        .then((response) => {
          if (response.status === 401) {
            clearUserLocalStorage();
            this.props.navigate("/login");
            // return <Navigate to="/login" />;
          }
          return response.json();
        })
        .then((res) => {
          // console.log("Home.js 342", res);
          if (res.success) {
            // this.setState({productList: res.data.collection});
            if (isLoading || isRefreshing || productList.length <= 0) {
              // console.log("Home.js 389", res.data);
              this.setState({
                isLoading: false,
                isRefreshing: false,
                productList: res.data.collection,
                totalPage: res.data.lastPage,
                page: page + 1,
                isVisible: false,
              });
            } else {
              // console.log("Home.js 387", res.data);
              this.setState({
                isLoading: false,
                isRefreshing: false,
                productList: [...productList, ...res.data.collection],
                totalPage: res.data.lastPage,
                page: page + 1,
                isVisible: false,
              });
            }
            // console.log("Home.js 349", this.state, this.props);
          } else {
            this.setState({
              isLoading: false,
              isRefreshing: false,
              page: page + 1,
              productList: [],
              isVisible: true,
              msg: res.message,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isRefreshing: false,
            page: page + 1,
          });
          console.log(error);
        })
        .finally(() => {
          this.setState({
            isLoading: false,
            isRefreshing: false,
            page: page + 1,
          });
        });
    }
  }

  async messageusersAPI() {
    const { token } = userData;

    try {
      await fetch(API.chat_connect_users, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.status === 401) {
            clearUserLocalStorage();
            this.props.navigate("/login");
          }
          return response.json();
        })
        .then(async (res) => {
          if (res.success) {
            let count = 0;
            res.data.collection.map((item) => {
              return (count = item.count_unread_message + count);
            });
            let oldLocalStorage = userData;
            oldLocalStorage["messageCount"] = count + "";
            localStorage.setItem(
              "kushkUserData",
              JSON.stringify(oldLocalStorage)
            );
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  onRefresh() {
    this.setState(
      {
        totalPage: 1,
        page: 1,
        subId: "",
        min: 0,
        max: 0,
        low: 0,
        high: 0,
        sort: "",
        input: "",
        isRefreshing: true,
        subcategorySelectedList: [],
        selectedCategoryName: [],
        CategoryName: [],
        type: "",
      },
      () => this.ProductList()
    );
  }

  renderFooter = () => {
    const { totalPage, page, isLoading, isRefreshing } = this.state;
    if ((totalPage >= page && !isRefreshing) || isLoading) {
      return (
        <View
          style={{
            height: 40,
            width: 40,
            borderRadius: 40,
            ...shadow,
            alignSelf: "center",
            marginTop: 20,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator color={color.primeColor} style={{}} />
        </View>
      );
    } else {
      return null;
    }
  };

  timecalculate(expirydate) {
    var date = moment().utcOffset("+03:00").format("YYYY-MM-DD HH:mm:ss");
    var diffr = moment.duration(moment(expirydate).diff(moment(date)));
    var hours = parseInt(diffr.asHours());
    var minutes = parseInt(diffr.minutes());
    var seconds = parseInt(diffr.seconds());
    var d = hours * 60 * 60 + minutes * 60 + seconds;
    return d;
  }

  async willFocus() {}

  async Login() {
    await this.setState({ isVisible: false });
    // await Global.main.setState({ userid: '', token: '' });
    // await AsyncStorage.clear();
    await this.props.navigation.navigate("Login");
  }

  async GetPriceRange() {
    this.setState({ isExpandLoading: true });
    // this.setState({ isExpand: true });
    const { token } = getUserData();
    const { input } = this.state;
    this.setState({ isLoading: true });
    let formdata = new FormData();
    // formdata.append("is_api", "dashboard");
    formdata.append("filter_input", input ? input : "");
    
    console.log("Search Log",formdata);

    await fetch(API.productFilterResponse, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    })
      .then((response) => {
        if (response.status === 401) {
          clearUserLocalStorage();
          this.props.navigate("/login");
        }
        return response.json();
      })
      .then((res) => {
        console.log("Search Log",res);
        if (res.success) {
          this.setState({
            isLoading: false,
            isExpand: true,
            isExpandLoading: false,
            data: res.data,
            // low: res.data.filterPriceMin
            //     ? parseInt(res.data.filterPriceMin)
            //     : 0,
            // high: res.data.filterPriceMax
            //   ? parseInt(res.data.filterPriceMax)
            //   : 0,
            low: res.data.filterPriceMin
              ? parseInt(res.data.filterPriceMin)
              : 0,
            high: res.data.filterPriceMax
              ? parseInt(res.data.filterPriceMax)
              : 0,
            min: res.data.filterPriceMin
              ? parseInt(res.data.filterPriceMin)
              : 0,
            max: res.data.filterPriceMax
              ? parseInt(res.data.filterPriceMax)
              : 0,
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        console.log("err", error);
        this.setState({ isLoading: false });
      });
  }

  clear() {
    this.setState({
      isVisibleSearch: false,
      selectedCategoryName: [],
      CategoryName: [],
      data: [],
      categoryList: [],
      disabled: true,
      isExpand: false,
      subcategoryList: [],
      subcategorySelectedList: [],
      input: "",
      active: false,
      min: 0,
      max: 0,
      low: 0,
      high: 0,
      sort: "",
    });
  }

  render() {
    const {
      isLoading,
      min,
      max,
      isRefreshing,
      isVisibleSearch,
      isExpand,
      isExpandLoading,
      productList,
      input,
      low,
      high,
      sort,
      deviceWidth,
      showDrawer = false,
      categoryList,
      CategoryName,
      subcategoryList,
    } = this.state;
    return (
      <>
        <View style={{ width: "100%", flex: 1 }}>
          <Header
            Limg={require("../../assets/img/homemenu.png")}
            showDrawer={showDrawer}
            closeDrawer={() => this.setState({ showDrawer: false })}
            Lpress={() => this.setState({ showDrawer: true })}
            Rimg={require("../../assets/img/search.png")}
            Rpress={() =>
              this.setState({
                isVisibleSearch: true,
                isExpand: false,
                input: "",
                sort: "",
                CategoryName: [],
                subcategorySelectedList: [],
              })
            }
            _onWillFocus={() => this.willFocus()}
          />
          <FlatList
            contentContainerStyle={{
              paddingBottom: 25,
              flexGrow: 1,
              paddingHorizontal: 20,
              paddingTop: 15,
            }}
            data={productList}
            renderItem={({ item, index }) => (
              <TouchableOpacity
                style={{ flexDirection: "row", marginBottom: 20 }}
                onPress={() =>
                  this.props.navigate("/product-detail", {
                    state: { id: item.id, type: "" },
                  })
                }
              >
                <View
                  style={{
                    height: 88,
                    width: 120,
                    borderRadius: 10,
                    overflow: "hidden",
                    marginLeft: this.state.currLang === "ar" ? 10 : 0,
                  }}
                >
                  <NImageLoader
                    imageSrc={
                      item.image
                        ? item.image
                        : require("../../assets/img/noimg.jpeg")
                    }
                    imageAlt={item.name}
                    imageStyle={{
                      verticalAlign: "middle",
                      height: "100%",
                      width: "100%",
                    }}
                  />
                </View>
                <View style={{ paddingLeft: 18, flex: 1 }}>
                  <Text
                    style={{
                      ...fontBold,
                      color: "#000",
                      fontSize: 15,
                      marginTop: 3,
                      textAlign: this.state.currLang === "ar" ? "right" : "",
                    }}
                  >
                    {/* {index+" "+item.id+" "} */}
                    {item.name}
                  </Text>
                  {item.location ? (
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginRight: this.state.currLang === "ar" ? 0 : 15,
                        marginTop: 5,
                      }}
                    >
                      <Image
                        source={require("../../assets/img/location.png")}
                        style={{
                          height: 15,
                          width: 15,
                          tintColor: color.lableColor,
                          resizeMode: "contain",
                        }}
                      />
                      <Text
                        numberOfLines={2}
                        style={{
                          ...fontReg,
                          color: color.lableColor,
                          marginLeft: this.state.currLang === "ar" ? 0 : 5,
                          marginRight: this.state.currLang === "ar" ? 5 : 0,
                          fontSize: 11,
                          textAlignVertical: "center",
                        }}
                      >
                        {item.location}
                      </Text>
                    </View>
                  ) : null}

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <View
                      style={{
                        justifyContent: "flex-end",
                        alignItems: "baseline",
                      }}
                    >
                      {item.typeofsell === "Buy it Now" ? (
                        <Text
                          style={{
                            ...fontBold,
                            color: "#E2583A",
                            fontSize: 14,
                          }}
                        >
                          SAR{" "}{item.saleprice + ""}
                        </Text>
                      ) : (
                        <Text
                          style={{
                            ...fontBold,
                            color: "#E2583A",
                            fontSize: 14,
                          }}
                        >
                          {/* SAR{" "}{item.saleprice} */}
                        </Text>
                      )}
                      {item.typeofsell === "Auction" ? (
                        <Text
                          style={{
                            ...fontBold,
                            color: "#E2583A",
                            fontSize: 14,
                          }}
                        >
                          SAR{" "}
                          {item.maximum_bid_price
                            ? item.maximum_bid_price
                            : item.aucationminimum_amount + ""}
                        </Text>
                      ) : (
                        <Text
                          style={{
                            ...fontBold,
                            color: "#E2583A",
                            fontSize: 14,
                          }}
                        >
                          {/* SAR{" "}{item.saleprice} */}
                        </Text>
                      )}
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 15,
                      }}
                    >
                      {item.typeofsell === "Auction" ? (
                        <View
                          style={{
                            alignSelf: "flex-end",
                            flexDirection: "row",
                            alignItems: "center",
                            borderRadius: 26,
                            paddingVertical: 8,
                            paddingHorizontal: 10,
                            backgroundColor: color.primeColor,
                          }}
                        >
                          <Image
                            source={require("../../assets/img/clock.png")}
                            style={{ height: 15, width: 15 }}
                          />
                          <Text
                            style={{
                              ...fontBold,
                              color: "#000",
                              marginLeft: this.state.currLang === "ar" ? 0 : 8,
                              marginRight: this.state.currLang === "ar" ? 8 : 0,
                              fontSize: 12,
                              textAlignVertical: "center",
                            }}
                          >
                            <Coundown finishTime={item.auction_endtime} />
                          </Text>
                        </View>
                      ) : null}
                    </View>
                  </View>
                </View>
              </TouchableOpacity>
            )}
            ListEmptyComponent={
              !isRefreshing && (
                <View style={{ flex: 1 }}>
                  {!isLoading ? (
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        source={require("../../assets/img/nodata.png")}
                        style={{
                          height: 60,
                          width: 60,
                          tintColor: color.primeColor,
                        }}
                      />
                      <Text
                        style={{
                          ...fontBold,
                          textAlign: "center",
                          marginTop: 10,
                          color: color.lableColor,
                        }}
                      >
                        {this.props.t("Data not found")}!
                      </Text>
                    </View>
                  ) : (
                    <>
                      {[1, 2, 3, 4, 5].map(() => {
                        return (
                          <View
                            style={{
                              flexDirection: "row",
                              // backgroundColor: "#a1a1a1"
                            }}
                          >
                            <View
                              style={{
                                height: 100,
                                width: 100,
                                overflow: "hidden",                               
                                marginBottom: 10
                              }}                              
                            >
                              <Skeleton
                                height={100}
                                width={100}
                                containerClassName="avatar-skeleton"
                                style={{
                                  marginBottom: 10,
                                }}
                              />
                            </View>
                            <View
                              style={{
                                paddingLeft: 18,
                                flex: 1,
                                width: "100%",
                                // backgroundColor: "#b25b25",
                              }}
                            >
                              <Skeleton
                                width="100%"
                                style={{
                                  marginBottom: 10,
                                }}
                              />
                              <Skeleton
                                width="40%"
                                style={{
                                  marginBottom: 10,
                                }}
                              />
                              <Skeleton
                                width="60%"
                                style={{
                                  marginBottom: 10,
                                }}
                              />
                              <Skeleton
                                width="80%"
                                style={{
                                  marginBottom: 10,
                                }}
                              />
                            </View>
                          </View>
                        );
                      })}
                    </>
                  )}
                </View>
              )
            }
            keyExtractor={(item, index) => index + ""}
            onEndReachedThreshold={0.1}
            onEndReached={() => this.ProductList()}
            ListFooterComponent={this.renderFooter}
            refreshControl={
              <RefreshControl
                refreshing={isRefreshing}
                onRefresh={() => this.onRefresh()}
              />
            }
          />
          <FootTab />
          <Modal
            transparent={true}
            animationType="fade"
            visible={isVisibleSearch}
          >
            <KeyboardAvoidingView
              style={{
                flex: 1,
                backgroundColor: "#FFFFFFF2",
                alignItems: "center",
                marginHorizontal: "auto",
                width: deviceWidth + "px",
              }}
            >
              <SafeAreaView />
              <Image
                source={require("../../assets/img/logo.png")}
                style={{
                  height: "5%",
                  width: "100%",
                  alignSelf: "center",
                  resizeMode: "contain",
                }}
              />
              <View
                borderColor={"#000"}
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  borderWidth: 1,
                  borderRadius: 7,
                  margin: 15,
                  width: "95%",
                }}
              >
                <TextInput
                  style={{
                    flex: 1,
                    paddingLeft: this.state.currLang === "ar" ? 0 : 12,
                    paddingRight: this.state.currLang === "ar" ? 12 : 0,
                    textAlign: this.state.currLang === "ar" ? "end" : "",
                  }}
                  placeholder={this.props.t("Search anything")}
                  value={input}
                  onChangeText={(input) => this.setState({ input })}
                />
                <TouchableOpacity
                  // disabled={input.length >= 3 ? false : true}
                  onPress={() => this.GetPriceRange()}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 12,
                  }}
                >
                  <Image
                    source={require("../../assets/img/filter.png")}
                    style={{ height: 15, width: 15, resizeMode: "contain" }}
                  />
                  <Text
                    style={{
                      ...fontBold,
                      color: "#000",
                      paddingLeft: this.state.currLang === "ar" ? 0 : 8,
                      paddingRight: this.state.currLang === "ar" ? 8 : 0,
                      fontSize: 13,
                    }}
                  >
                    {this.props.t("Filters")}
                  </Text>
                </TouchableOpacity>
              </View>

              {isExpand ? (
                <>
                  {/* <ScrollView bounces={false} contentContainerStyle={{ flexGrow: 1 }} > */}
                  <View
                    style={{
                      backgroundColor: "#fff",
                      flex: 1,
                      marginBottom: 20,
                      // marginTop: 30,
                      paddingHorizontal: 25,
                      borderTopLeftRadius: 30,
                      borderTopRightRadius: 30,
                      width: "100%",
                    }}
                  >
                    <View style={{ flexDirection: "row", marginTop: 30 }}>
                      <Text
                        style={{
                          ...fontBold,
                          color: "#231F20",
                          fontSize: 20,
                          flex: 1,
                        }}
                      >
                        {this.props.t("Filters")}
                      </Text>
                      <Text
                        onPress={() => this.setState({ isExpand: false })}
                        style={{
                          ...fontBold,
                          color: "#231F20",
                          fontSize: 13,
                        }}
                      >
                        {this.props.t("Cancel")}
                      </Text>
                    </View>
                    <Text
                      style={{
                        ...fontBold,
                        color: "#231F20",
                        marginTop: 35,
                      }}
                    >
                      {this.props.t("Price Range")}
                    </Text>
                    <View style={{}}>
                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: 20,
                          marginBottom: 5,
                        }}
                      >
                        <Text
                          style={{
                            ...fontReg,
                            flex: 1,
                            color: "#231F20",
                            fontSize: 13,
                          }}
                        >
                          SAR{" "}{low}
                        </Text>
                        <Text
                          style={{
                            ...fontReg,
                            color: "#231F20",
                            fontSize: 13,
                          }}
                        >
                          SAR{" "}{high}
                        </Text>
                      </View>
                    </View>
                    {max !== 0 ? (
                      <>
                        {/* <Slider /> */}
                        {console.log("filter 1115", this.state)}
                        <Slider
                          range
                          step={1}
                          defaultValue={[low, high]}
                          min={min}
                          max={max}
                          tipFormatter={(value) => `${value}!`}
                          trackStyle={{
                            height: 4,
                            backgroundColor: color.primeColor,
                          }}
                          handleStyle={{
                            // marginLeft: -14,
                            marginTop: -7,
                            height: 20,
                            width: 20,
                            backgroundColor: color.primeColor,
                            borderWidth: 3,
                            border: "2px solid #fff",
                            borderRadius: 20,
                            tintColor: color.primeColor,
                            boxShadow: "unset !important",
                          }}
                          dotStyle={{ borderColor: "#fff", boxShadow: "unset" }}
                          activeDotStyle={{
                            borderColor: color.primeColor,
                            boxShadow: "unset !important",
                          }}
                          // rc-slider-handle-dragging
                          onChange={(_data) =>
                            this.setState({ low: _data[0], high: _data[1] })
                          }
                        />
                        {/* <MultiSlider
                        style={{ flex: 1 }}
                        allowOverlap
                        snapped
                        
                        selectedStyle={{
                          backgroundColor: color.primeColor,
                        }}
                        unselectedStyle={{
                          backgroundColor: color.borderColor,
                        }}
                      // customLabel={CustomLabel}
                      /> */}
                      </>
                    ) : null}

                    {/* Sorting  */}
                    <Text
                      style={{
                        ...fontReg,
                        color: "#231F20",
                        marginTop: 20,
                      }}
                    >
                      {this.props.t("Sort")}
                    </Text>

                    <View style={{ flexDirection: "row", paddingVertical: 15 }}>
                      <TouchableOpacity
                        onPress={() => this.setState({ sort: "mostRecent" })}
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          // justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <View
                          borderColor={
                            sort === "mostRecent" ? color.primeColor : "#231F20"
                          }
                          style={{
                            height: 17,
                            width: 17,
                            borderWidth: 2,
                            borderRadius: 15,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <View
                            style={{
                              height: 10,
                              width: 10,
                              backgroundColor:
                                sort === "mostRecent" ? color.primeColor : null,
                              borderRadius: 10,
                            }}
                          />
                        </View>
                        <Text
                          style={{
                            ...fontReg,
                            fontSize: 12.5,
                            color:
                              sort === "mostRecent"
                                ? color.primeColor
                                : "#231F20",
                            paddingLeft: this.state.currLang === "ar" ? 0 : 7,
                            paddingRight: this.state.currLang === "ar" ? 7 : 0,
                            textAlignVertical: "center",
                          }}
                        >
                          {this.props.t("Most Recent")}
                        </Text>
                      </TouchableOpacity>

                      <TouchableOpacity
                        onPress={() => this.setState({ sort: "bestMatch" })}
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <View
                          borderColor={
                            sort === "bestMatch" ? color.primeColor : "#231F20"
                          }
                          style={{
                            height: 17,
                            width: 17,
                            borderWidth: 2,
                            borderRadius: 15,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <View
                            style={{
                              height: 10,
                              width: 10,
                              backgroundColor:
                                sort === "bestMatch" ? color.primeColor : null,
                              borderRadius: 10,
                            }}
                          />
                        </View>
                        <Text
                          style={{
                            ...fontReg,
                            fontSize: 12.5,
                            color:
                              sort === "bestMatch"
                                ? color.primeColor
                                : "#231F20",
                            paddingLeft: this.state.currLang === "ar" ? 0 : 7,
                            paddingRight: this.state.currLang === "ar" ? 7 : 0,
                            textAlignVertical: "center",
                          }}
                        >
                          {this.props.t("Best Match")}
                        </Text>
                      </TouchableOpacity>

                      <TouchableOpacity
                        onPress={() => this.setState({ sort: "topRated" })}
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <View
                          borderColor={
                            sort === "topRated" ? color.primeColor : "#231F20"
                          }
                          style={{
                            height: 17,
                            width: 17,
                            borderWidth: 2,
                            borderRadius: 15,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <View
                            style={{
                              height: 10,
                              width: 10,
                              backgroundColor:
                                sort === "topRated" ? color.primeColor : null,
                              borderRadius: 10,
                            }}
                          />
                        </View>
                        <Text
                          style={{
                            ...fontReg,
                            fontSize: 12.5,
                            color:
                              sort === "topRated"
                                ? color.primeColor
                                : "#231F20",
                            paddingLeft: this.state.currLang === "ar" ? 0 : 7,
                            paddingRight: this.state.currLang === "ar" ? 7 : 0,
                            textAlignVertical: "center",
                          }}
                        >
                          {this.props.t("Top Rated")}
                        </Text>
                      </TouchableOpacity>
                    </View>

                    {/* Category Selection */}
                    <Text
                      style={{
                        ...fontBold,
                        color: "#231F20",
                        marginTop: 20,
                        marginBottom: 10,
                      }}
                    >
                      {this.props.t("Categories")}
                    </Text>
                    <View style={{ paddingVertical: 15 }}>
                      <Select
                        isMulti
                        // defaultValue={selectedCategoryName}
                        defaultValue={CategoryName}
                        closeMenuOnSelect={() => {
                          this.setState({ subcategoryList: [] });
                          console.log(subcategoryList);
                        }}
                        noOptionsMessage={({ inputValue }) =>
                          !inputValue
                            ? this.props.t(this.state.noOptionsText)
                            : "No results found"
                        }
                        onChange={(CategoryName) => {
                          this.setState(
                            { CategoryName, subcategorySelectedList: [] },
                            () => this.SubCategoryList(CategoryName)
                          );
                        }}
                        options={categoryList.map((item, index) => {
                          return {
                            value: item.id,
                            label:
                              this.state.currLang === "ar"
                                ? item.name_ar
                                : item.name,
                          };
                        })}
                        placeholder={this.props.t("Select category")}
                      />
                      {/* </View>
                    <View style={{ paddingVertical: 15 }}> */}
                      <Select
                        // defaultValue={subcategorySelectedList}
                        defaultValue={subcategoryList}
                        noOptionsMessage={({ inputValue }) =>
                          !inputValue
                            ? this.props.t(this.state.noOptionsText)
                            : "No results found"
                        }
                        onChange={(subcategoryList) =>
                          this.setState({
                            subcategorySelectedList: [subcategoryList],
                          })
                        }
                        options={subcategoryList.map((item, index) => {
                          return {
                            value: item.id,
                            label:
                              this.state.currLang === "ar"
                                ? item.name_ar
                                : item.name,
                          };
                        })}
                        placeholder={this.props.t("Select sub category")}
                      />
                    </View>
                  </View>
                  {/* </ScrollView> */}
                </>
              ) : isExpandLoading ? (
                <View
                  style={{
                    width: "90%",
                  }}
                >
                  <Skeleton />
                  <Skeleton height={20} />
                  <Skeleton />
                  <Skeleton height={40} />
                  <Skeleton />
                </View>
              ) : null}

              {isExpand ? (
                <View
                  style={{
                    flexDirection: "row",
                    paddingHorizontal: 15,
                    marginBottom: 20,
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <View
                    borderColor={"#000"}
                    style={{ flex: 1, borderWidth: 1.5, borderRadius: 50 }}
                  >
                    <TouchableOpacity
                      onPress={() => this.clear()}
                      style={{ paddingVertical: 15, alignItems: "center" }}
                    >
                      <Text style={{ ...fontBold, fontSize: 15 }}>
                        {this.props.t("Clear")}
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <TouchableOpacity
                    onPress={() =>
                      this.setState(
                        {
                          isVisibleSearch: false,
                          page: 1,
                          totalPage: 1,
                          isLoading: true,
                        },
                        () => this.ProductList()
                      )
                    }
                    style={{
                      marginLeft: this.state.currLang === "ar" ? 0 : 15,
                      marginRight: this.state.currLang === "ar" ? 15 : 0,
                      flex: 1,
                      paddingVertical: 15,
                      borderRadius: 50,
                      alignItems: "center",
                      backgroundColor: "#000",
                    }}
                  >
                    <Text
                      style={{
                        ...fontBold,
                        color: "#fff",
                        fontSize: 15,
                      }}
                    >
                      {this.props.t("Apply")}
                    </Text>
                  </TouchableOpacity>
                </View>
              ) : (
                <TouchableOpacity
                  onPress={() => this.setState({ isVisibleSearch: false })}
                  style={{
                    position: "absolute",
                    bottom: 30,
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                >
                  <Text
                    style={{
                      ...fontBold,
                      color: "#000",
                      fontSize: 15,
                    }}
                  >
                    {this.props.t("Cancel")}
                  </Text>
                </TouchableOpacity>
              )}

              <SnackBar ref="SnackBar" />
            </KeyboardAvoidingView>
          </Modal>
        </View>
      </>
    );
  }
}
export default withTranslation()(withRouter(Home));
