import { Platform, Dimensions } from "react-native";

const { height, width } = Dimensions.get("window");

export function textPer(percent) {
  const heightPercent = (percent * width) / 100;
  // return Math.round(heightPercent);
  return heightPercent;
}

export const font = {
  // reg: 'metropolis.bold',
  // bold: 'Metropolis-Bold',

  // reg: "Metropolis-Regular",
  // thin: "metropolis-thin",
  reg: "Metropolis",
  bold: "Metropolis",
  thin: "Metropolis",
  // bold: 'Futura-Medium',
};

export const fontWeight = {
  reg: 200,
  bold: 600,
  thin: 400,
};

export const fontReg = {
  fontFamily: "Metropolis",
  // fontWeight: 200
}

export const fontBold = {
  fontFamily: "Metropolis",
  fontWeight: 600
}

export const shadow = {
  backgroundColor: "#fff",
  shadowOffset: { width: 0, height: 0 },
  shadowOpacity: 0.2,
  shadowRadius: 3,
  elevation: 5,
};

export const color = {
  primeColor: "#F8C041",
  primeColor_op_10: "#F8C04111",
  primeColor_op_20: "#F8C04122",
  primeColor_op_40: "#F8C04144",
  secondColor: "#aad8d3",
  orenge: "#e8910d",
  orenge_op_11: "#e8910d11",
  orenge_light: "#e67016",
  orenge_light_op_05: "#e6701605",
  lableColor: "#a2a2a2",
  iconColor: "#CECECE",
  red: "#F25961",
  red_op_10: "#ed1c2411",
  green: "#31CE36",
  teal: "#00867a",
  hot: "#17a2b8",
  warm: "#6861CE",
  cold: "#FFAD46",

  borderColor: "#e2e2e2",
};

export const authLogoImage = {
  height: 110,
  width: "60%",
  alignSelf: "center",
};

export const activityIndicator = {
  color: "#fff",
  alignItems: "center",
  flexDirection: "row",
};

export const TextInputStyle = {
  borderWidth: 1,
  borderColor: '#DEE1E6',
  borderRadius: 5,
  paddingHorizontal: 15,
  paddingTop:10,
  paddingRight:15,
  paddingBottom:10,
  paddingLeft:15,
  flex: 1,
  color: "#000",
  fontFamily: font.thin,
  fontSize: 16,
};
