import React, { Component } from "react";
import {
  View,
  Text,
  Modal,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ActivityIndicator,
} from "react-native";
import { font, shadow } from "./Styles";
import { Translation } from "react-i18next";
interface MyProps {
  onPressOk: Function;
  onPressCancel: Function;
  cancel: Boolean;
  mute: Boolean;
  isLoading: Boolean;
}

export default class CustomAlert extends Component<MyProps> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      msg: "Hello this message is test message!",
      title: "Lead Tracker",
      data: null,
      deviceHeight: null,
      deviceWidth: "468",
      currLang: window.localStorage.getItem("lang") ?? "en",
    };
    // alert('Hello this message is test message')
  }
  async componentDidMount() {
    let { deviceHeight, deviceWidth } = JSON.parse(
      await localStorage.getItem("kushkDevice")
    );
    deviceWidth = deviceWidth - 31;
    await this.setState({ deviceHeight, deviceWidth });
    // SplashScreen.hide();
  }

  async onShow(msg, title, data) {
    const { mute = false } = this.props;
    this.setState({
      title: title ? title : this.state.title,
      msg,
      visible: true,
      data,
    });
  }
  onPress() {
    const { data } = this.state;
    this.setState({ visible: false }, () =>
      this.props.onPressOk ? this.props.onPressOk(data) : null
    );
  }

  render() {
    const { visible, msg, title, deviceHeight, deviceWidth } = this.state;
    console.log("alert 59", this.state);
    const { cancel, isLoading } = this.props;
    return (
      <Modal
        transparent
        visible={visible}
        onRequestClose={() => cancel && this.setState({ visible: false })}
      >
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => cancel && this.setState({ visible: false })}
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 15,
            backgroundColor: "#0003",
            marginHorizontal: "auto",
          }}
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                padding: 15,
                ...shadow,
                borderRadius: 8,
                width: deviceWidth + "px",
              }}
            >
              <Text style={{ fontFamily: font.bold, fontSize: 16 }}>
                {title}
              </Text>
              <Text
                style={{ fontFamily: font.reg, marginTop: 5, fontSize: 14 }}
              >
                {msg}
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {cancel ? (
                  <>
                    {/* <TouchableOpacity
                    onPress={() =>
                      this.setState({ visible: false }, () => {
                        onPressCancel ? onPressCancel() : null;
                      })
                    }
                    style={{
                      marginTop: 15,
                      marginRight: 30,
                      alignSelf: 'flex-end',
                    }}>
                    <Text
                      style={{
                        fontFamily: font.bold,
                        fontSize: 14,
                        color: color.lableColor,
                      }}>
                      Cancel
                    </Text>
                  </TouchableOpacity> */}
                  </>
                ) : null}
                <TouchableOpacity
                 onPress={() =>
                   this.setState({ visible: false })
                }
                  style={{ marginTop: 15, alignSelf: "flex-end", marginRight: this.state.currLang === 'ar' ? 0 : 30, marginLeft: this.state.currLang === 'ar' ? 30 : 0,  }}
                >
                  <Translation>
                    {(t, { i18n }) => <>{t("Cancel")}</>}
                  </Translation>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => this.onPress()}
                  style={{ marginTop: 15, alignSelf: "flex-end" }}
                >
                  {isLoading ? (
                    <ActivityIndicator color={"#000"} />
                  ) : (
                    <Text style={{ fontFamily: font.bold, fontSize: 14 }}>
                      <Translation>
                        {(t, { i18n }) => <>{t("OK")}</>}
                      </Translation>
                    </Text>
                  )}
                </TouchableOpacity>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </TouchableOpacity>
      </Modal>
    );
  }
}
