import React, { Component } from 'react';
// import SplashScreen from 'react-native-splash-screen';
import { View, Text, Image, RefreshControl, FlatList, Dimensions, ActivityIndicator, Platform, } from 'react-native';
import Header from '../Component/Header';
import { color, font, fontBold, fontReg, shadow } from '../Component/Styles';
import { API } from '../../Privet';
// import Global from '../Global';
// import SkeletonPlaceholder from "react-native-skeleton-placeholder";
import RatingBar from '../Component/RatingBar';
import moment from 'moment';
import { getUserData } from '../../utility/Utils';
import { withRouter } from '../../withRouter';
import { withTranslation } from 'react-i18next';

const { width } = Dimensions.get('window');


// const userData = getUserData();

class UserReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isRefreshing: false,
            totalPage: 1,
            page: 1,
            reviewList: [],
            sellerProfile_id: (this.props.location.state && this.props.location.state.sellerProfile_id) ? this.props.location.state.sellerProfile_id : null, 
            currLang: window.localStorage.getItem("lang") ?? "en",
        };
    }

    async componentDidMount() {
        /*if(this.props.location.state){
            this.props.navigate('/')
            return "";
        }*/
        // SplashScreen.hide();
        this.reviewListApi();
    }

    async reviewListApi() {
        const { token } = getUserData();
        // console.log('UserReview 38', getUserData());
        const { totalPage, page, sellerProfile_id, isLoading, isRefreshing, reviewList, isSearch } = this.state;

        if (totalPage >= page && !isSearch) {

            let formdata = new FormData();
            formdata.append('sellerProfile_id', sellerProfile_id);
            formdata.append('page', page + '');
            formdata.append('perPage', 15);
            // console.log('formdata 46',formdata);
            await fetch(API.sellerRatingReview, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
                body: formdata,
            })
                .then(response => response.json())
                .then(res => {

                    if (res.success) {
                        if (isLoading || isRefreshing || reviewList.length <= 0) {
                            this.setState({ isLoading: false, isRefreshing: false, reviewList: res.data.collection, totalPage: res.data.lastPage, page: page + 1, isVisible: false });
                        } else {
                            this.setState({ isLoading: false, isRefreshing: false, reviewList: [...reviewList, ...res.data.collection], totalPage: res.data.lastPage, page: page + 1, isVisible: false });
                        }
                    } else {
                        this.setState({ isLoading: false, isRefreshing: false, page: page + 1, reviewList: [], isVisible: true, msg: res.message });
                    }
                })
                .catch(error => {
                    this.setState({ isLoading: false, isRefreshing: false, page: page + 1, });
                    console.log(error);
                })
                .finally(() => {
                    this.setState({ isLoading: false, isRefreshing: false, page: page + 1 });
                });
        }
    }


    renderFooter = () => {
        const { totalPage, page, isLoading, isRefreshing, isSearch } = this.state;
        if (totalPage >= page && (!isRefreshing || isLoading) && !isSearch) {
            return (
                <View style={{ height: 40, width: 40, borderRadius: 40, ...shadow, alignSelf: 'center', marginTop: 20, justifyContent: 'center', alignItems: 'center' }}>
                    <ActivityIndicator color={color.primeColor} style={{}} />
                </View>
            )
        } else {
            return null
        }
    }

    onRefresh() {
        this.setState({
            reviewList: [],
            totalPage: 1,
            page: 1,
            isRefreshing: true,
        }, () => this.reviewListApi())
    }

    render() {
        const { isLoading, reviewList, isRefreshing } = this.state;
        return (
            <View style={{ flex: 1, backgroundColor: '#fff' }}>
                <Header
                    title={this.props.t("User Reviews")}
                    Limg={require('../../assets/img/back.png')}
                    Lpress={() => this.props.navigate(-1)}
                />

                <FlatList
                    contentContainerStyle={{ padding: 20, flexGrow: 1 }}
                    data={reviewList}
                    renderItem={({ item, index }) =>
                        <View style={{ marginBottom: 15 }} >
                            <View style={{ flexDirection: 'row', }}>
                                {item.sellerDetail.imageURL
                                    ?
                                    <Image source={{ uri: item.sellerDetail.imageURL }} style={{ height: 50, width: 50, borderRadius: 50, }} />
                                    :
                                    <Image source={require('../../assets/img/user.png')} style={{ height: 50, width: 50, borderRadius: 50, resizeMode: 'contain' }} />
                                }
                                <View style={{ 
                                        flex: 1,    
                                        marginLeft:  this.state.currLang === 'ar' ? 0  : 10,
                                        marginRight:  this.state.currLang === 'ar' ? 10  : 0, 
                                        textAlign: this.state.currLang === 'ar' ? 'end' : '',
                                    }}
                                >
                                    {item.sellerDetail.name
                                        ? <Text numberOfLines={2} style={{ ...fontBold,fontSize: 15, color: '#231F20' }}>{item.sellerDetail.name + " " + item.sellerDetail.lname}</Text> : null}
                                    <Text style={{ ...fontReg,fontSize: 11, color: '#9E9EA7', marginTop: 5 }}>{moment(item.created_at).format('MMM D, YYYY')}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 2 }}>
                                    <RatingBar
                                        value={item.rating}
                                        onChange={rating => alert(rating)}
                                    />
                                    <Text style={{  ...fontBold,fontSize: 15, color: '#231F20', marginLeft: 5, marginTop: Platform.OS === 'android' ? -4 : 0, }}>{item.rating}.0</Text>
                                </View>
                            </View>
                            <Text style={{ 
                                    ...fontReg,color: '#231F20', 
                                    fontSize: 14, 
                                    marginTop: 15, 
                                    marginBottom: 15, 
                                    paddingHorizontal: 5, 
                                    textAlign: this.state.currLang === 'ar' ? 'end' : '', 
                                }}
                            >
                                {item.review}
                            </Text>
                        </View>
                    }
                    ListEmptyComponent={
                        !isRefreshing && <View
                            style={{ flex: 1, }}>
                            {!isLoading ? (
                                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <Image source={require('../../assets/img/nodata.png')} style={{ height: 60, width: 60, tintColor: color.primeColor }} />
                                    <Text style={{  ...fontBold, textAlign: 'center', marginTop: 10, color: color.lableColor }}>{this.props.t('Data not found')}!</Text>
                                </View>
                            ) :
                            <>
                                {/* <SkeletonPlaceholder> */}
                                    <View style={{ flexDirection: 'row', paddingBottom: 10, }}>
                                        <View style={{ height: 70, width: 70, borderRadius: 80, }} />
                                        <View style={{ paddingLeft: 18 }}>
                                            <View style={{ height: 20, width: width / 2, marginTop: 3, borderRadius: 2.5, }} />
                                            <View style={{ height: 20, width: width / 2.5, marginTop: 2, borderRadius: 2.5, }} />
                                            <View style={{ height: 20, width: width / 3, marginTop: 2, borderRadius: 2.5, }} />
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', paddingBottom: 10, }}>
                                        <View style={{ height: 70, width: 70, borderRadius: 80, }} />
                                        <View style={{ paddingLeft: 18 }}>
                                            <View style={{ height: 20, width: width / 2, marginTop: 3, borderRadius: 2.5, }} />
                                            <View style={{ height: 20, width: width / 2.5, marginTop: 2, borderRadius: 2.5, }} />
                                            <View style={{ height: 20, width: width / 3, marginTop: 2, borderRadius: 2.5, }} />
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', paddingBottom: 10, }}>
                                        <View style={{ height: 70, width: 70, borderRadius: 80, }} />
                                        <View style={{ paddingLeft: 18 }}>
                                            <View style={{ height: 20, width: width / 2, marginTop: 3, borderRadius: 2.5, }} />
                                            <View style={{ height: 20, width: width / 2.5, marginTop: 2, borderRadius: 2.5, }} />
                                            <View style={{ height: 20, width: width / 3, marginTop: 2, borderRadius: 2.5, }} />
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', paddingBottom: 10, }}>
                                        <View style={{ height: 70, width: 70, borderRadius: 80, }} />
                                        <View style={{ paddingLeft: 18 }}>
                                            <View style={{ height: 20, width: width / 2, marginTop: 3, borderRadius: 2.5, }} />
                                            <View style={{ height: 20, width: width / 2.5, marginTop: 2, borderRadius: 2.5, }} />
                                            <View style={{ height: 20, width: width / 3, marginTop: 2, borderRadius: 2.5, }} />
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', paddingBottom: 10, }}>
                                        <View style={{ height: 70, width: 70, borderRadius: 80, }} />
                                        <View style={{ paddingLeft: 18 }}>
                                            <View style={{ height: 20, width: width / 2, marginTop: 3, borderRadius: 2.5, }} />
                                            <View style={{ height: 20, width: width / 2.5, marginTop: 2, borderRadius: 2.5, }} />
                                            <View style={{ height: 20, width: width / 3, marginTop: 2, borderRadius: 2.5, }} />
                                        </View>
                                    </View>
                                    {/* </SkeletonPlaceholder> */}
                                </>
                            }
                        </View >
                    }
                    keyExtractor={(item, index) => index + ''}
                    onEndReachedThreshold={0.1}
                    onEndReached={() => this.reviewListApi()}
                    ListFooterComponent={this.renderFooter}
                    refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={() => this.onRefresh()} />}
                />
            </View>
        );
    }
}

export default withTranslation()(withRouter(UserReview));
