// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyClPeqi-bfoDh9oYnIO2lcwRccZILhsC4k",
  authDomain: "notional-access-338807.firebaseapp.com",
  projectId: "notional-access-338807",
  storageBucket: "notional-access-338807.appspot.com",
  messagingSenderId: "830830121550",
  appId: "1:830830121550:web:61d57b609b7e97d922a91f",
  measurementId: "G-VBYF65TVVR",
};

// Initialize Firebase
// initializeApp(firebaseConfig);
export const firebaseApp = initializeApp(firebaseConfig);
// const messaging = getMessaging(firebaseApp);
const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      // console.log("isSupportedBrowser", getMessaging(firebaseApp));
      return getMessaging(firebaseApp);
    }
    console.log("Firebase not supported this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

export const fetchToken = async (setTokenFound) => {
  try {
    const isSupportedBrowser = await isSupported();
    
    if (isSupportedBrowser) {
      const currentToken = await getToken(messaging, {
        vapidKey:
          "BPNAR_UKlMdscZIRO1avs5DkEdOlhIgp4sWHJOf3trGmweWjT15Gsm1p6vzhhUIm4we0WFQCNFKmigYE0zh5Pr4",
      });
      if (currentToken) {
        // console.log("current token for client: ", currentToken);
        localStorage.setItem("deviceToken", currentToken);
        setTokenFound(true);
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
      }
    }
    return null;
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, async (payload) => {
      resolve(payload);
    });
  });

export const onBackgroundMessageListene = () =>
  new Promise((resolve) => {
    onMessage(messaging, async (payload) => {
      // console.log("onBackground", payload);
      resolve(payload);
    });
  });
