import React, { Component } from "react";
import {
  View,
  Text,
  Modal,
  KeyboardAvoidingView,
  Platform,
  Image,
  ScrollView,
  TextInput,
  TouchableOpacity,
  SafeAreaView,
} from "react-native";
import { API } from "../../Privet";
import { color, font, TextInputStyle } from "./Styles";
import { withTranslation } from 'react-i18next';
class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      visible: false,
      text: "",
      predictions: [],
      key: "",
      deviceHeight: null,
      deviceWidth: "468",
    };
  }

  async componentDidMount() {
    let { deviceHeight, deviceWidth } = JSON.parse(
      await localStorage.getItem("kushkDevice")
    );
    deviceWidth = deviceWidth - 31;
    await this.setState({ deviceHeight, deviceWidth });
  }

  show(text, key) {
    this.setState({
      text,
      key,
      visible: true,
      predictions: [],
      isLoading: false,
    });
  }

  async autoComplete(text) {
    this.setState({ text });
    console.log("AutoComplete 51", text, API.googleMapKey);
    

    await fetch(
      `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${text}&key=${API.googleMapKey}&types=address&components=country:sa`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          // "Access-Control-Allow-Origin": "*",
          // "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("response 65", res);
        if (res.status === "OK") {
          this.setState({ isLoading: false, predictions: res.predictions });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log("AutoComplet 74", error);
      });
  }

  getVehicalTypes(text) {
    this.setState({ text });
    console.log("AutoComplete 70", text, API.googleMapKey);
    fetch(
      `https://admin-driver2u.websitetech.in/api/getSuburbs?search=${text}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("response 84", res);
        if (res.status === "OK") {
          this.setState({ isLoading: false, predictions: res.predictions });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log("AutoComplet 93", error);
      });
  }

  async select(item) {
    const { onSelect } = this.props;
    const { key } = this.state;
    const location = await this.getLatLong(item.place_id);
    (await onSelect) && onSelect(item, location, key);
    this.setState({
      visible: false,
      text: "",
      key: "",
      predictions: [],
      isLoading: false,
    });
  }

  async getLatLong(place_id) {
    this.setState({ isLoading: true });
    // console.log("AutoComplete",place_id,API.googleMapKey);
    return await fetch(
      `https://maps.googleapis.com/maps/api/place/details/json?place_id=${place_id}&key=${API.googleMapKey}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          // 'Content-Type': 'application/json'
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.status === "OK") {
          this.setState({ isLoading: false });
          return res.result.geometry.location;
        } else {
          this.setState({ isLoading: false });
          return {};
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
        return {};
      });
  }

  render() {
    const { text, predictions, visible, deviceWidth } = this.state;
    return (
      <View>
        <Modal
          animationType="slide"
          visible={visible}
          onRequestClose={() =>
            this.setState({
              visible: false,
              text: "",
              key: "",
              predictions: [],
            })
          }
          onShow={() => this.TI.focus()}
        >
          <KeyboardAvoidingView
            style={{
              backgroundColor: "#fff",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: 15,
              marginHorizontal: "auto",
              border:1,
            }}
          >
            <SafeAreaView />
            <View
              style={{
                paddingLeft: 15,
                paddingVertical: 15,
                borderBottomWidth: 1,
                flexDirection: "row",
                alignItems: "center",
                width: deviceWidth + "px",
              }}
            >
              <View
                style={{
                  flex: 1,
                  backgroundColor: "#F1F1F1",
                  borderRadius: 6,
                  flexDirection: "row",
                  alignItems: "center",
                  paddingHorizontal: 12,
                }}
              >
                <Image
                  source={require("../../assets/img/search.png")}
                  style={{
                    height: 16,
                    width: 16,
                    resizeMode: "contain",
                    tintColor: color.lableColor,
                  }}
                />
                <TextInput
                  ref={(ref) => (this.TI = ref)}
                  style={{
                    ...TextInputStyle,
                    flex: 1,
                    color: "#000",
                    fontSize: 14,
                    paddingTop: 10,
                    paddingLeft: 5,
                    paddingRight: 5,
                    marginRight: 5,
                    marginLeft: 5,
                    borderColor: "#ffffff00",
                  }}
                  placeholder={this.props.t("Search location...")}
                  value={text ?? ""}
                  onChangeText={(text) => this.autoComplete(text)}
                  // onChangeText={(text) => this.getVehicalTypes(text)}
                />
                <TouchableOpacity
                  onPress={() => this.setState({ text: "", predictions: [] })}
                  style={{
                    height: 20,
                    width: 20,
                    borderRadius: 17,
                    backgroundColor: color.lableColor,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={require("../../assets/img/cancle_icon.png")}
                    style={{
                      height: "45%",
                      width: "45%",
                      resizeMode: "contain",
                      tintColor: "#f1f1f1",
                    }}
                  />
                </TouchableOpacity>
              </View>

              <TouchableOpacity
                onPress={() =>
                  this.setState({
                    visible: false,
                    text: "",
                    key: "",
                    predictions: [],
                  })
                }
                style={{
                  paddingHorizontal: 12,
                  paddingVertical: 3,
                  marginRight: 3,
                }}
              >
                <Text style={{ fontFamily: font.reg, fontSize: 16 }}>
                  Cancel
                </Text>
              </TouchableOpacity>
            </View>

            <ScrollView
              contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 15 }}
            >
              {predictions.map((item, index) => (
                <TouchableOpacity
                  key={index + ""}
                  onPress={() => this.select(item)}
                  style={{
                    borderBottomWidth: 1,
                    paddingVertical: 12,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={require("../../assets/img/location.png")}
                    style={{ height: 17, width: 17, resizeMode: "contain" }}
                  />
                  <Text
                    style={{
                      flex: 1,
                      fontFamily: font.reg,
                      fontSize: 13,
                      marginLeft: 10,
                    }}
                  >
                    {item.description}
                  </Text>
                </TouchableOpacity>
              ))}
            </ScrollView>
          </KeyboardAvoidingView>
        </Modal>
      </View>
    );
  }
}

export default withTranslation(AutoComplete);