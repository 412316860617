import React, { Component } from "react";
// import SplashScreen from 'react-native-splash-screen';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  TextInput,
  Keyboard,
  ActivityIndicator,
} from "react-native";
import Header from "../Component/Header";
import { font } from "../Component/Styles";
import { API } from "../../Privet";
import ImagePicker from "../Component/ImagePicker";
import SnackBar from "../Component/SnackBar";

import { getUserData } from "../../utility/Utils";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { withRouter } from "../../withRouter";

import { withTranslation } from 'react-i18next';

const userData = getUserData();

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingprofile: true,
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      image: "",
      data: "",
      currLang: window.localStorage.getItem("lang") ?? "en",
    };
  }

  async componentDidMount() {
    // SplashScreen.hide();
    
    await this.ProfileApi();
  }

  willFocus() {
    
    this.ProfileApi();
  }

  onSelectIMG(img) {
    // const { image } = this.state;
    let tempImg = img.target.files[0];
    this.setState({ image: tempImg });
  }

  async ProfileApi() {
    const { token } = userData;
    this.setState({ isLoadingprofile: true });
    await fetch(API.userProfile, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            isLoadingprofile: false,
            firstname: res.data.name,
            lastname: res.data.lname,
            email: res.data.email,
            mobile: res.data.mobile,
            data: res.data.imageURL,
          });
        } else {
          this.setState({ isLoadingprofile: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoadingprofile: false });
      })
      .finally(() => {
        this.setState({ isLoadingprofile: false });
      });
  }

  CheckValidation() {
    const { firstname, lastname, email, mobile } = this.state;
    Keyboard.dismiss();
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const numreg = /[^0-9]/g;

    if (!firstname) {
      this.refs.RNSnackBar.show(this.props.t("Please enter first name."));
    } else if (!lastname) {
      this.refs.RNSnackBar.show(this.props.t("Please enter last name."));
    } else if (!email) {
      this.refs.RNSnackBar.show(this.props.t("Please enter email id."));
    } else if (!reg.test(email)) {
      this.refs.RNSnackBar.show(this.props.t("Please enter valid email id."));
    } else if (!mobile) {
      this.refs.RNSnackBar.show(this.props.t("Please enter mobile."));
    } else {
      this.EditProfileApi();
    }
  }

  // EditProfile API
  async EditProfileApi() {
    const { firstname, lastname, image, email, mobile } = this.state;
    const { token, userid } = userData;
    this.setState({ isLoading: true });
    const formdata = new FormData();
    formdata.append("edit_id", userid);
    formdata.append("name", firstname);
    formdata.append("lname", lastname);
    formdata.append("email", email);
    formdata.append("mobile", mobile);
    // formdata.append(
    //   "file",
    //   newImage
    //     ? { uri: newImage, name: "profile.png", type: "image/jpeg" }
    //     : ""
    // );

    image && formdata.append("image", image);

    // console.log('Edit Profile 131',newImage);
    await fetch(API.updateUserProfile, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        // Accept: "* /*",
        // "Content-Type": "multipart/form-data",
      },
      body: formdata,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          this.refs.RNSnackBar.show(response.message);
          let items = {};
          items = {
            userid: userData.userid + "",
            mobile: userData.mobile + "",
            token: userData.token + "",
            firstname: response.data.name + "",
            lastname: response.data.lname + "",
            email: response.data.email + "",
            image: response.data.image + "",
            imageURL: response.data.imageURL + "",
          };
          console.log("items 154", items, response.data);
          localStorage.setItem("kushkUserData", JSON.stringify(items));
          this.props.navigate("/profile");
          // window.location.reload();
          this.setState({ isLoading: false });
        } else {
          this.setState({ isLoading: false });
          this.refs.RNSnackBar.show(response.data.mobile);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const {
      isLoading,
      data,
      firstname,
      lastname,
      image,
      email,
      mobile,
      isLoadingprofile,
    } = this.state;
    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <Header
          title={this.props.t("Edit Profile")}
          Limg={require("../../assets/img/back.png")}
          Lpress={() => this.props.navigate(-1)}
        />
        <ScrollView contentContainerStyle={{ flexGrow: 1, padding: 15 }}>
          {!isLoadingprofile ? (
            <View style={{ flex: 1, backgroundColor: "#fff" }}>
              <TouchableOpacity
                // onPress={() => this.ImagePicker.show()}
                onPress={() => {
                  this.upload.click();
                }}
                style={{
                  height: 120,
                  width: 120,
                  borderRadius: 120,
                  borderWidth: 1,
                  alignSelf: "center",
                  overflow: "hidden",
                  marginTop: 25,
                  justifyContent: "center",
                }}
              >
                <input
                  type="file"
                  id="file"
                  ref={(ref) => (this.upload = ref)}
                  style={{ display: "none" }}
                  // webkitdirectory="true"
                  onChange={this.onSelectIMG.bind(this)}
                />
                {image ? (
                  <img
                    // src={item}
                    src={URL.createObjectURL(image)}
                    style={{ height: "100%", width: "100%" }}
                    alt="profile"
                  />
                ) : data ? (
                  <img
                    // src={item}
                    src={data}
                    style={{ height: "100%", width: "100%" }}
                    alt="profile"
                  />
                ) : (
                  <Image
                    source={require("../../assets/img/user.png")}
                    style={{
                      height: "100%",
                      width: "100%",
                      alignSelf: "center",
                    }}
                  />
                )}
              </TouchableOpacity>
              <View style={{ marginTop: 30, marginHorizontal: 20 }}>
                <View style={{ marginBottom: 15 }}>
                  <Text style={{ fontFamily: font.reg, color: "#000" }}>
                    {this.props.t('First Name')}
                  </Text>
                  <TextInput
                    style={{
                      fontSize: 14,
                      color: "#000",
                      borderBottomWidth: 1,
                      textAlign: this.state.currLang === 'ar' ? "end" : ""
                    }}
                    placeholder={this.props.t("Enter first name")}
                    value={firstname}
                    onChangeText={(firstname) => this.setState({ firstname })}
                    returnKeyType="next"
                    onSubmitEditing={() => this.refs.lastname.focus()}
                  />
                </View>
                <View style={{ marginBottom: 15 }}>
                  <Text style={{ fontFamily: font.reg, color: "#000" }}>
                    {this.props.t('Last Name')}
                  </Text>
                  <TextInput
                    ref="lastname"
                    style={{
                      fontSize: 14,
                      color: "#000",
                      borderBottomWidth: 1,
                      textAlign: this.state.currLang === 'ar' ? "end" : ""
                    }}
                    placeholder={this.props.t("Enter last name")}
                    value={lastname}
                    onChangeText={(lastname) => this.setState({ lastname })}
                    returnKeyType="next"
                    onSubmitEditing={() => this.refs.mobile.focus()}
                  />
                </View>
                <View
                  borderColor={"#000"}
                  style={{ marginBottom: 15, borderBottomWidth: 1 }}
                >
                  <Text style={{ fontFamily: font.reg, color: "#000" }}>
                    {this.props.t('Email Id')}
                  </Text>
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#000",
                      fontFamily: font.reg,
                      // paddingTop: 10,
                      paddingVertical: 3,
                      textAlign: this.state.currLang === 'ar' ? "end" : ""
                    }}
                  >
                    {email}
                  </Text>
                </View>
                <View
                  borderColor={"#000"}
                  style={{ marginBottom: 15, borderBottomWidth: 1 }}
                >
                  <Text style={{ fontFamily: font.reg, color: "#000" }}>
                    {this.props.t('Mobile')}
                  </Text>
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#000",
                      fontFamily: font.reg,
                      // paddingTop: 10,
                      paddingVertical: 3,
                      textAlign: this.state.currLang === 'ar' ? "end" : ""
                    }}
                  >
                    {mobile}
                  </Text>
                </View>
                {/* <View style={{ marginBottom: 15 }}>
                  <Text style={{ fontFamily: font.reg, color: "#000" }}>
                    Mobile
                  </Text>
                  <TextInput
                    ref="mobile"
                    style={{
                      fontSize: 14,
                      color: "#000",
                      borderBottomWidth: 1,
                    }}
                    placeholder="Enter mobile no."
                    keyboardType="number-pad"
                    value={mobile}
                    onChangeText={(mobile) => this.setState({ mobile })}
                    returnKeyType="done"
                  />
                </View> */}
                <TouchableOpacity
                  onPress={() => this.CheckValidation()}
                  style={{
                    backgroundColor: "#000",
                    paddingVertical: 13,
                    marginTop: 20,
                    borderRadius: 25,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {isLoading ? (
                    <ActivityIndicator color={"#fff"} />
                  ) : (
                    <Text
                      style={{
                        fontFamily: font.bold,
                        color: "#ffff",
                        fontSize: 16,
                      }}
                    >
                      {this.props.t('Next')}
                    </Text>
                  )}
                </TouchableOpacity>
              </View>
            </View>
          ) : (
            <View style={{ padding: 15 }}>
              <View style={{ padding: 15, alignItems: "center" }}>
                <Skeleton
                  circle
                  style={{
                    height: 100,
                    width: 100,
                  }}
                />
              </View>
              <Skeleton
                style={{
                  height: 20,
                  borderRadius: 7,
                  marginBottom: 10,
                }}
              />
              <Skeleton
                style={{
                  height: 20,
                  borderRadius: 7,
                  marginBottom: 10,
                }}
              />
              <Skeleton
                style={{
                  height: 20,
                  borderRadius: 7,
                  marginBottom: 10,
                }}
              />
              <Skeleton
                style={{
                  height: 20,
                  borderRadius: 7,
                  marginBottom: 10,
                }}
              />
              <Skeleton
                style={{
                  height: 20,
                  borderRadius: 7,
                  marginBottom: 10,
                }}
              />
              <Skeleton
                style={{
                  height: 40,
                  borderRadius: 50,
                  marginBottom: 10,
                }}
              />
            </View>
          )}
        </ScrollView>
        <ImagePicker
          ref={(ref) => (this.ImagePicker = ref)}
          onSelect={(img) => this.onSelectIMG(img)}
        />
        <SnackBar ref="RNSnackBar" />
      </View>
    );
  }
}
export default withTranslation()(withRouter(EditProfile));
