import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const withRouter = (Component) => {
//   console.log(Component);
  const Wrapper = (props) => {
    // console.log(props);
    const navigate = useNavigate();
    const location = useLocation();

    return <Component navigate={navigate} location={location} {...props} />;
  };

  return Wrapper;
};
