import moment from "moment";
import React, { Component } from "react";
import { Text } from "react-native";
import { Translation } from "react-i18next";

export default class Coundown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  componentDidMount() {
    this.coundown(this.props.finishTime);
  }

  coundown(_endDate) {
    // Set the date we're counting down to
    // var ed = new Date(moment().utcOffset('+03:00').format('YYYY-MM-DD HH:mm:ss')).getTime();
    var endDate = new Date(moment(_endDate)).getTime();
    // var now = new Date().getTime();
    const GTM3 = moment().utcOffset("+03:00").format("YYYY-MM-DD HH:mm:ss");
    // var now = new Date(moment().utcOffset('+03:00').format('YYYY-MM-DD HH:mm:ss')).getTime();
    var now = new Date(moment(GTM3)._d).getTime();
    var distance = endDate - now;
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    this.setState({
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    });

    if (distance < 0 && this.interval) {
      this.props.isExpired && this.props.isExpired(true);
      clearInterval(this.interval);
    }

    this.interval = setInterval(() => {
      // var now = new Date().getTime();
      const GTM3 = moment().utcOffset("+03:00").format("YYYY-MM-DD HH:mm:ss");
      // var now = new Date(moment().utcOffset('+03:00').format('YYYY-MM-DD HH:mm:ss')).getTime();
      var now = new Date(moment(GTM3)._d).getTime();
      var distance = endDate - now;
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      });

      if (distance < 0) {
        this.props.isExpired && this.props.isExpired(true);
        clearInterval(this.interval);
      }
    }, 1000);
  }

  render() {
    const { days, hours, minutes, seconds } = this.state;
    if (days >= 0) {
      return (
        <Text>
          {days}d:{hours}h:{minutes}m
        </Text>
      );
    }
    return (
      <Text>
        <Translation>{(t, { i18n }) => <>{t("Expired")}</>}</Translation>
      </Text>
    );
  }
}
