import React, { Component } from "react";
import {
  View,
  Text,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
} from "react-native";
// import AsyncStorage from '@react-native-async-storage/async-storage';
import CustomAlert from "./CustomAlert";
import { font } from "./Styles";
import { API } from "../../Privet";
import { clearUserLocalStorage, getUserData } from "../../utility/Utils";
import { withRouter } from "../../withRouter";

import { withTranslation } from "react-i18next";

const userData = getUserData();

const menuItems = [
  {
    title: "My posts",
    route: "/my-posts"
  },
  {
    title: "Active bids",
    route: "/active-bids"
  },
  {
    title: "Watch List",
    route: "/watch-list"
  },
  {
    title: "Purchase list",
    route: "/purchase-list"
  },
  {
    title: "Favourite sellers",
    route: "/favourite-seller"
  },
  // {
  //   title: "Financial Informations",
  //   route: "/"
  // },
  {
    title: "Privacy Policy",
    route: "/privacy-policy"
  },
  {
    title: "Terms & Conditions",
    route: "/terms-conditions"
  },
  {
    title: "Settings",
    route: "/settings"
  },
];
class CustomDrawerMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // logoutVisible: false,
      isLoading: false,
      currLang: window.localStorage.getItem("lang") ?? "en",
    };
  }


  componentDidMount() {}

  async onPress(key) {
    if (key) {
      this.props.navigate(key);
      this.props.closeDrawer();
    }
  }

  async logout() {
    const { token } = userData;

    clearUserLocalStorage();
    // console.log("Logout 64");
    await fetch(API.logout, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.result === "success") {
          // await this.props.props.navigation.closeDrawer();
          // await Global.main.setState({ user_id: '', username: '' });
          // await AsyncStorage.clear();
          await this.props.closeDrawer();
          await this.props.navigate("/login", { state: { isReload: true } });
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log("e", error);
      });
  }

  render() {
    // const { drawerOpen, logoutVisible, isLoading } = this.state;
    // const { props } = this.props;
    /*const routes =
      this.props.props.navigation.state.routes[
        [props.navigation.state.routes.length - 1]
      ].routes;
    const routeName = routes[routes.length - 1].routeName;*/

    return (
      <View
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "#0003",
          flexDirection: "row",
          position: "absolute",
          zIndex: 1000,
          top: 0,
          left: 0,
          transition: ".5s ease",
        }}
      >
        <View
          style={{
            height: " 100%",
            width: "75%",
            maxWidth: 300,
            // position: "fixed",
            zIndex: 1000,
            top: 0,
            left: 0,
            backgroundColor: "#fff",
            overflowX: "hidden",
            paddingTop: "20px",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <SafeAreaView style={{ backgroundColor: "#fff" }} />
          <TouchableOpacity
            onPress={() => this.onPress("/profile")}
            style={{
              paddingTop: 50,
              paddingBottom: 25,
              paddingHorizontal: 20,
            }}
          >
            <View
              style={{
                height: 70,
                width: 70,
                justifyContent: "center",
                alignItems: "center",
                borderWidth: 1,
                marginRight: 15,
                borderRadius: 200,
                overflow: "hidden",
              }}
            >
              {userData.imageURL ? (
                <Image
                  source={userData.imageURL}
                  style={{ height: "100%", width: "100%" }}
                />
              ) : (
                <Image
                  source={require("../../assets/img/user.png")}
                  style={{ height: "100%", width: "100%" }}
                />
              )}
            </View>
            <View style={{ paddingTop: 12 }}>
              <Text
                numberOfLines={1}
                style={{
                  fontFamily: font.bold,
                  fontSize: 18,
                  color: "#000",
                  textAlign: this.state.currLang === "ar" ? "end" : "",
                }}
              >
                {userData.firstname} {userData.lastname}
              </Text>
            </View>
          </TouchableOpacity>
          <View
            style={{
              width: "100%",
              borderWidth: 1,
              borderBottomColor: "#DEE1E6",
            }}
          ></View>

          <ScrollView bounces={false} contentContainerStyle={{ flexGrow: 1 }}>
            <View
              style={{
                flex: 1,
                paddingTop: 20,
                paddingLeft: this.state.currLang === "ar" ? 0 : 25,
                paddingRight: this.state.currLang === "ar" ? 25 : 0,
              }}
            >
              {menuItems.map((item, index) => {
                return (
                  <TouchableOpacity
                    onPress={() => this.onPress(item.route)}
                    style={{ paddingVertical: 15 }}
                  >
                    <Text
                      style={{
                        fontFamily: font.reg,
                        fontSize: 15,
                        color: "#000",
                      }}
                    >
                      {this.props.t(item.title)}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          </ScrollView>
          <TouchableOpacity
            onPress={() =>
              this.refs.CustomAlert.onShow(
                this.props.t("Are you sure you want to logout!"),
                this.props.t("Logout")
              )
            }
            style={{
              marginTop: 5,
              paddingBottom: 30,
              paddingLeft: this.state.currLang === "ar" ? 0 : 25,
              paddingRight: this.state.currLang === "ar" ? 25 : 0,
            }}
          >
            {/* <Text style={{ fontFamily: font.reg, fontSize: 15, color: routeName === 'Logout' ? color.primeColor : '#000', marginTop: 5 }}>Logout</Text> */}
            <Text style={{ fontFamily: font.reg, fontSize: 15, marginTop: 5 }}>
              {this.props.t("Logout")}
            </Text>
          </TouchableOpacity>

          <CustomAlert
            ref="CustomAlert"
            onPressOk={(e) => {
              this.logout();
            }}
            cancel
          />
        </View>
        <TouchableOpacity
          style={{
            flex: 1,
            zIndex: 1000,
            // position: "absolute",
            // right: 0,
            // backgroundColor:'red',
            transition: ".5s ease",
            overflowX: "hidden",
            paddingTop: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onPress={() => {
            this.props.closeDrawer();
          }}
        ></TouchableOpacity>
      </View>
    );
  }
}

export default withTranslation()(withRouter(CustomDrawerMenu));
