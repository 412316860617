import React from "react";
import {
  FlatList,
  Image,
  PixelRatio,
  ScrollView,
  Text,
  Platform,
  TouchableOpacity,
  View,
  Modal,
  TouchableWithoutFeedback,
  TextInput,
  ActivityIndicator,
  KeyboardAvoidingView,
  Keyboard,
} from "react-native";

import {
  color,
  font,
  fontBold,
  fontReg,
  shadow,
  TextInputStyle,
} from "../Component/Styles";
import { API } from "../../Privet";
import Header from "../Component/Header";

import SnackBar from "../Component/SnackBar";

import moment from "moment";
import RatingBar from "../Component/RatingBar";

import Coundown from "../Component/Coundown";

import Skeleton from "react-loading-skeleton";
import {
  checkOnlyNumber,
  clearUserLocalStorage,
  getUserData,
} from "../../utility/Utils";
import { withRouter } from "../../withRouter";

import { withTranslation } from "react-i18next";

// import Global from '../Global';

const wp = (widthPercent) => {
  const { deviceWidth } = JSON.parse(localStorage.getItem("kushkDevice"));

  const width = deviceWidth;
  const elemWidth =
    typeof widthPercent === "number" ? widthPercent : parseFloat(widthPercent);
  return PixelRatio.roundToNearestPixel((width * elemWidth) / 100);
};

const hp = (heightPercent) => {
  const { deviceHeight } = JSON.parse(localStorage.getItem("kushkDevice"));
  const height = deviceHeight;
  const elemHeight =
    typeof heightPercent === "number"
      ? heightPercent
      : parseFloat(heightPercent);
  return PixelRatio.roundToNearestPixel((height * elemHeight) / 100);
};

const userData = getUserData();

class ProductDetail extends React.Component {
  state = {
    data: "",
    SellerDetail: "",
    isSelected: { index: 0 },
    isLoading: false,
    isLoadingBid: false,
    isLoadingBidedit: false,
    isSelectedPath: null,
    // ProductId: this.props.navigation.getParam("id"),
    // type: this.props.navigation.getParam("type"),
    ProductId: this.props.location.state.id,
    type: this.props.location.state.type,
    // type: this.props.navigation.getParam("type"),
    WatchListStatus: false,
    WatchLisId: "",
    isVisible: false,
    amount: "",
    productuserid: "",
    placebidList: [],
    isVisibleBidStatus: false,
    isLoadingStatusWin: false,
    isVisibleconfirm: false,
    placedbidid: "",
    Winallow: "",
    qty: "",
    isVisibleQty: false,
    isLoadingwatch: false,
    commissionPr: "",
    isVisibleedit: false,
    amountedit: "",
    isExpired: false,

    isVisibleAddOrder: false,
    displayqty: "",
    admincommission: "",
    isOrderLoading: false,

    deviceHeight: null,
    deviceWidth: "468",

    payment: "",
    payment_open: false,
    newWindow: null,
    newWindowSecond: 300,
    currLang: window.localStorage.getItem("lang") ?? "en",
  };

  async componentDidMount() {
    // console.log("Product Detail 86", userData);
    // console.log("Product Detail 89", this.props.location);
    // Global.main.isTabBar(false);
    await this.productdetail();
    await this.bidListApi();
    await this.getCommission();

    let { deviceHeight, deviceWidth } = JSON.parse(
      await localStorage.getItem("kushkDevice")
    );
    // deviceWidth = deviceWidth;
    await this.setState({ deviceHeight, deviceWidth });
  }

  async willFocus() {
    // Global.main.isTabBar(false);
    await this.productdetail();
    await this.bidListApi();
    await this.getCommission();
  }

  async productdetail() {
    const { token, userid } = userData;
    const { ProductId } = this.state;
    this.setState({ isLoading: true, isVisibleedit: false });

    let formdata = new FormData();
    formdata.append("id", ProductId + "");
    formdata.append("loginAppUserid", userid + "");
    formdata.append("getWatchlist", true);
    formdata.append("getSellerDetail", true);

    // console.log("Product Detail 114", formdata);

    await fetch(API.productdetail, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    })
      .then((response) => {
        if (response.status === 401) {
          clearUserLocalStorage();
          this.props.navigate("/login");
        }
        return response.json();
      })
      .then((res) => {
        if (res.success) {
          this.setState({
            data: res.data,
            SellerDetail: res.data.SellerDetail,
            WatchListStatus: res.data.in_watchlist,
            WatchLisId: res.data.watchlist_id ? res.data.watchlist_id : "",
            productuserid: res.data.id_appuser + "",
            amountedit: res.data?.loginUserPlacedBidDetail?.product_bid_amount,
            amount: res.data?.loginUserPlacedBidDetail?.product_bid_amount,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }

  async AddRemoveWatchListApi() {
    const { token, userid } = userData;
    const { ProductId, WatchLisId, WatchListStatus } = this.state;
    this.setState({ isLoadingwatch: true });

    let formdata = new FormData();

    if (WatchListStatus === false) {
      formdata.append("appuser_id", userid);
      formdata.append("product_id", ProductId);
    } else {
      formdata.append("appuser_id", userid);
      formdata.append("product_id", ProductId);
      formdata.append("status", "remove");
      formdata.append("watchlist_id", WatchLisId);
    }
    await fetch(API.add_removeToWatchlist, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    })
      .then((response) => {
        if (response.status === 401) {
          clearUserLocalStorage();
          this.props.navigate("/login");
        }
        return response.json();
      })
      .then((res) => {
        if (res.success) {
          // console.log("Product Detail 185", res.message);
          this.refs.SnackBar.show(res.message + "");
          this.productdetail();
          this.setState({ isLoadingwatch: false });
        } else {
          this.refs.SnackBar.show(res.message);
          this.setState({ isLoadingwatch: false });
          this.productdetail();
        }
      })
      .catch((error) => {
        this.setState({ isLoadingwatch: false });
        console.log(error);
      });
  }

  renderImages = (item, index) => {
    return (
      <View
        style={{
          padding: 5,
          borderRadius: 10,
          overflow: "hidden",
          backgroundColor: "#fff",
          marginRight: 10,
          marginBottom: 10,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            this.setState({
              isSelected: item,
              isSelectedPath: item.item.product_image,
            });
          }}
        >
          <Image
            style={{
              width: wp(12),
              height: wp(12),
              borderRadius: 10,
              borderColor: "#000",
              resizeMode: "contain",
            }}
            source={{
              uri: item.item.product_image ? item.item.product_image : "",
            }}
          />
        </TouchableOpacity>
      </View>
    );
  };

  rating() {
    const {
      onChange,
      disabled = false,
      value = 0,
      size = 5,
      imageStyle,
    } = this.props;
    let ratingBar = [];
    for (let i = 1; i <= size; i++) {
      ratingBar.push(
        <TouchableOpacity
          key={i + ""}
          disabled={disabled}
          onPress={() => onChange && onChange(i)}
          style={{ marginRight: size === i ? 0 : 3 }}
        >
          <Image
            source={require("../../assets/img/rating-gray.png")}
            style={{
              height: 13,
              width: 13,
              resizeMode: "contain",
              tintColor: value >= i ? color.primeColor : color.lableColor,
              ...imageStyle,
            }}
          />
        </TouchableOpacity>
      );
    }
    return ratingBar;
  }

  PlaceBid() {
    const { amount, data } = this.state;

    if (!amount) {
      this.refs.BidSnackBar.show("Please enter amount.");
    } else if (
      data.aucationminimum === 1 &&
      amount <= data.aucationminimum_amount
    ) {
      this.refs.BidSnackBar.show(this.props.t("Place bid amount grathen of this amount."));
    } else {
      this.PlaceBidApi();
    }
  }

  // Add Bid
  async PlaceBidApi() {
    const { token, userid } = userData;
    const { ProductId, amount } = this.state;
    this.setState({ isLoadingBid: true });

    let formdata = new FormData();

    formdata.append("appuser_id", userid);
    formdata.append("product_id", ProductId + "");
    formdata.append("product_bid_amount", amount);

    await fetch(API.placeBidOnAuctionProduct, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    })
      .then((response) => {
        if (response.status === 401) {
          clearUserLocalStorage();
          this.props.navigate("/login");
        }
        return response.json();
      })
      .then((res) => {
        this.refs.SnackBar.show(res.message);
        if (res.success) {
          this.productdetail();
          this.setState({ isVisible: false, isVisibleconfirm: false, isLoadingBid: false });
        } else {
          this.setState({ isVisibleconfirm: false, isLoadingBid: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoadingBid: false, isVisibleconfirm: false });
        console.log(error);
      });
  }

  async PlaceEditBid(id) {
    const { token, userid } = userData;
    const { ProductId, amountedit, data } = this.state;
    Keyboard.dismiss();

    if (!amountedit) {
      this.refs.EdSnackBar.show("Please enter amount.");
    } else if (
      data.aucationminimum === 1 &&
      amountedit <= data.aucationminimum_amount
    ) {
      this.refs.EdSnackBar.show(this.props.t("Place bid amount grathen of this amount."));
    } else {
      this.setState({ isLoadingBidedit: true });

      let formdata = new FormData();

      formdata.append("appuser_id", userid);
      formdata.append("product_id", ProductId);
      formdata.append("product_bid_amount", amountedit);
      formdata.append("placed_bid_id", id);

      await fetch(API.placeBidOnAuctionProduct, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formdata,
      })
        .then((response) => {
          if (response.status === 401) {
            clearUserLocalStorage();
            this.props.navigate("/login");
          }
          return response.json();
        })
        .then((res) => {
          this.refs.SnackBar.show(res.message);
          if (res.success) {
            this.productdetail();
            this.setState({ isVisibleconfirm: false, isLoadingBidedit: false });
          } else {
            this.setState({ isVisibleconfirm: false, isLoadingBid: false });
          }
        })
        .catch((error) => {
          this.setState({ isLoadingBid: false, isVisibleconfirm: false });
          console.log(error);
        });
    }
  }

  timecalculate(expirydate) {
    var date = moment().utcOffset("+03:00").format("YYYY-MM-DD HH:mm:ss");
    var diffr = moment.duration(moment(expirydate).diff(moment(date)));
    var hours = parseInt(diffr.asHours());
    var minutes = parseInt(diffr.minutes());
    var seconds = parseInt(diffr.seconds());
    var d = hours * 60 * 60 + minutes * 60 + seconds;

    return d;
  }

  // Get List of Bid for loginadmin
  bidListApi() {
    const { token } = userData;
    const { ProductId } = this.state;
    this.setState({ isLoading: true });

    fetch(
      `${API.productdetail_auctioned_product_placed_bids}?product_id=${ProductId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 401) {
          clearUserLocalStorage();
          this.props.navigate("/login");
        }
        return response.json();
      })
      .then((res) => {
        if (res.success) {
          this.setState({
            isLoading: false,
            placebidList: res.data.collection,
            Winallow: res.data.win_open,
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }

  // status change Win Bid by Loginadmin
  async addPlacebid() {
    const { token, userid } = userData;
    const { placedbidid } = this.state;
    this.setState({ isLoadingStatusWin: true });

    let formdata = new FormData();

    formdata.append("placed_bid_id", placedbidid);

    await fetch(API.productdetail_auction_product_win, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    })
      .then((response) => {
        if (response.status === 401) {
          clearUserLocalStorage();
          // this.props.navigate("/login");
        }
        return response.json();
      })
      .then((res) => {
        this.setState({ isVisibleBidStatus: false, isLoadingStatusWin: false });
        this.bidListApi();
      })
      .catch((error) => {
        this.setState({ isLoadingStatusWin: false, isVisibleBidStatus: false });
        console.log(error);
      });
  }

  // GetComminssion for Admin
  getCommission() {
    const { token } = userData;

    fetch(API.admin_commission, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          clearUserLocalStorage();
          this.props.navigate("/login");
        }
        return response.json();
      })
      .then((res) => {
        if (res.success) {
          this.setState({ commissionPr: res.data.value });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async addQty() {
    const { qty, data, commissionPr } = this.state;
    Keyboard.dismiss();

    if (!qty) {
      this.refs.MSnackBar.show(this.props.t("Please enter quantity."));
    } else if (qty <= 0) {
      this.refs.MSnackBar.show(this.props.t("Please enter valid quantity."));
    } else if (qty > parseInt(data.available_stocks)) {
      this.refs.MSnackBar.show(this.props.t("Stock not available."));
    } else {
      let admincommission = ((data.saleprice * commissionPr) / 100) * qty;
      // await this.buyNow(admincommission);
      this.setState({
        isVisibleQty: false,
        displayqty: qty,
        isVisibleAddOrder: true,
        admincommission: admincommission,
      });
    }
  }

  async buyNow(admincommission) {
    const { token, userid } = userData;
    const {
      ProductId,
      qty,
      commissionPr,
      // , deviceWidth, deviceHeight
    } = this.state;
    const { deviceWidth, deviceHeight } = userData;
    // const { commissionPr } = this.state;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("appuser_id", userid);
    formdata.append("product_id", ProductId);
    formdata.append("admin_commission", commissionPr);
    formdata.append("ordered_quantity", qty);
    // formdata.append("debugMode", "true");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    await fetch(API.order_product_hold_quntiry, requestOptions)
      .then((response) => {
        if (response.status === 401) {
          clearUserLocalStorage();
          this.props.navigate("/login");
        }
        return response.json();
      })
      .then((result) => {
        if (result.success) {
          // console.log("Product Detail 564", result);
          if (result.data && result.data.payment) {
            this.setState({
              payment: result.data.payment + "",
              payment_open: true,
            });
            setTimeout(() => {
              let newWindow = window.open(result.data.payment, "", [
                "width=" + (deviceWidth - 50),
                "height=" + (deviceHeight - 50),
              ]);
              this.setState({ newWindow: newWindow, newWindowSecond: 180 });
              this.timer();
            }, 500);
          }
        }
      })
      .catch((error) => console.log("error", error));
  }

  timer() {
    const { data } = this.state;
    // this.setState({ second: 30 });
    this.interval = setInterval(() => {
      const { newWindow, payment_open, newWindowSecond } = this.state;
      if (newWindow.closed && payment_open) {
        this.setState({
          newWindow: "",
          newWindowSecond: 300,
          payment_open: false,
        });
        let kushkRedirect = localStorage.getItem("kushkRedirect");
        if (kushkRedirect) {
          this.props.navigate("/seller-profile", {
            state: {
              sellerid: data.SellerDetail.id,
            },
          });
        }
        localStorage.removeItem("kushkRedirect");
        // console.log("608", kushkRedirect);
      }
      if (newWindow !== "" && payment_open) {
        console.log(
          // "timer 621",
          "t",
          // newWindow,
          newWindowSecond,
          newWindow.closed + ""
        );

        // Second not 0
        if (newWindowSecond > 0) {
          this.setState({ newWindowSecond: newWindowSecond - 1 });
        } else {
          // Second time out automatically close the window
          if (this.interval) {
            newWindow.close();
            this.interval = clearInterval(this.interval);
          } else {
            console.log("timer 644");
            this.interval = null;
          }
          this.setState({
            newWindow: "",
            newWindowSecond: 300,
            payment_open: false,
          });
        }
      }
    }, 1000);
  }

  async addOrder(
    payment_response = null,
    transation_reference = null,
    admincommission
  ) {
    const { token, userid } = userData;
    const { ProductId, qty, commissionPr, data } = this.state;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("appuser_id", userid);
    formdata.append("product_id", ProductId);
    formdata.append("ordered_quantity", qty);
    formdata.append("admin_commission", commissionPr);
    formdata.append(
      "admin_commission_amount",
      parseFloat(admincommission) + ""
    );
    formdata.append("transaction_response", payment_response);
    formdata.append("transation_reference", transation_reference);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    await fetch(API.order, requestOptions)
      .then((response) => {
        if (response.status === 401) {
          clearUserLocalStorage();
          this.props.navigate("/login");
        }
        return response.json();
      })
      .then((result) => {
        console.log("reslut 702", result);
        if (result.success) {
          this.setState({ isOrderLoading: false, isVisibleAddOrder: false });
          // alert(result.message);
          console.log("product detail 705 ", this.state.data.SellerDetail);
          this.props.navigate("/payment/" + this.state.data.SellerDetail.id, {
            state: { sellerid: this.state.data.SellerDetail.id },
          });
        } else {
          this.setState({ isOrderLoading: false, isVisibleAddOrder: false });
          alert("Payment Cancel.");
        }
      })
      .catch((error) => console.log("error", error));
  }

  checkifDisabled() {
    if (this.state.data.typeofsell === "Auction") {
      if (this.state.data.loginUserPlacedBidDetail.product_bid_amount) {
        return false;
      } else {
        return true;
      }
    } else if (this.state.data.typeofsell != "Auction") {
      return false;
    }
  }

  render() {
    const { userid } = userData;
    const {
      data,
      displayqty,
      isOrderLoading,
      isVisibleconfirm,
      isLoadingBidedit,
      amountedit,
      isVisibleedit,
      type,
      qty,
      isLoadingwatch,
      placedbidid,
      isVisibleQty,
      isLoadingStatusWin,
      isVisibleBidStatus,
      Winallow,
      placebidList,
      isLoading,
      SellerDetail,
      WatchListStatus,
      mainStyle,
      isLoadingBid,
      isVisible,
      amount,
      productuserid,
      isExpired,
      deviceHeight,
      deviceWidth,
      payment_open,
      newWindow,
      payment,
      admincommission,
      isVisibleAddOrder,
    } = this.state;
    // console.log("Product Detail 707", this.state);

    return (
      <>
        {payment_open ? (
          <View
            style={{
              flex: 1,
              height: deviceHeight,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: 20,
                fontWeight: 700,
                marginVertical: 20,
              }}
            >
              {this.props.t("Do not close or change this screen")}
            </Text>
            <ActivityIndicator color={"#000"} />
          </View>
        ) : (
          <View
            style={{ flex: 1, backgroundColor: "#fff", width: deviceWidth }}
          >
            {/* {console.log("Product Detail 637", this.state)} */}
            <Header
              title={
                type === "ActiveBid"
                  ? this.props.t("Active Bid")
                  : type === "watchlist"
                  ? this.props.t("Watch List")
                  : this.props.t("Product Details")
              }
              Limg={require("../../assets/img/back.png")}
              Lpress={() => this.props.navigate(-1)}
              edit={userid === productuserid ? true : false}
              EditPress={() =>
                this.props.navigate("/product-add", {
                  state: {
                    type: "Edit",
                    data: data,
                  },
                })
              }
              _onWillFocus={() => this.willFocus()}
              // saveimg={type === 'ActiveBid' && userid != productuserid ? WatchListStatus ? require('../../assets/img/fill.png') : require('../assets/img/bookmark.png') : null}
              // savepress={() => this.AddRemoveWatchListApi()}
              Rimg={
                type === "ActiveBid" && userid !== productuserid
                  ? WatchListStatus
                    ? require("../../assets/img/fill.png")
                    : require("../../assets/img/bookmark.png")
                  : null
              }
              Rpress={() => this.AddRemoveWatchListApi()}
            />
            {data !== "" ? (
              <ScrollView
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{ flexGrow: 1 }}
              >
                <>
                  {data.images.length !== 0 ? (
                    <View>
                      <View style={{ backgroundColor: "#F2F2F2", height: 250 }}>
                        <Image
                          style={{
                            height: "100%",
                            width: "100%",
                            resizeMode: "contain",
                          }}
                          source={{
                            uri: this.state.isSelectedPath
                              ? this.state.isSelectedPath
                              : data.image
                              ? data.image
                              : null,
                          }}
                        />
                      </View>
                      <FlatList
                        contentContainerStyle={{
                          flexDirection: "row",
                          height: 260,
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        data={data.images}
                        renderItem={this.renderImages}
                        value={this.state.data}
                        showsVerticalScrollIndicator={false}
                        horizontal={true}
                        extraData={this.state.refresh}
                      />
                    </View>
                  ) : (
                    <View
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: 250,
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Image
                        style={{
                          height: "55%",
                          width: "55%",
                          resizeMode: "contain",
                        }}
                        source={require("../../assets/img/nodata.png")}
                      />
                    </View>
                  )}
                  <View
                    style={{
                      backgroundColor: "#fff",
                      paddingTop: 16,
                      paddingHorizontal: 20,
                    }}
                  >
                    <View style={{ flexDirection: "row" }}>
                      <View style={{ flex: 1, marginBottom: 10 }}>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row",
                            marginTop: 7,
                          }}
                        >
                          <Text
                            style={{
                              ...fontReg,
                              fontSize: 13,
                              color: "#9E9EA7",
                            }}
                          >
                            { this.state.currLang === 'ar' ?  data?.category_name_ar : data?.category_name}
                          </Text>
                        </View>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={{
                              ...fontBold,
                              fontSize: 16,
                              color: "#231F20",
                              // textTransform: "capitalize",
                            }}
                          >
                            {data.name}
                          </Text>
                        </View>
                        {data.typeofsell === "Auction" ? (
                          <View style={{ flex: 1, flexDirection: "row" }}>
                            <Text
                              style={{
                                ...fontReg,
                                fontSize: 13,
                                color: "#9E9EA7",
                              }}
                            >
                              {data.bidCount} {this.props.t("bids")}
                            </Text>
                          </View>
                        ) : null}
                      </View>
                      {/* Time Display */}
                      {data.typeofsell === "Auction" ? (
                        <View
                          style={{
                            height: 30,
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "row",
                            borderRadius: 26,
                            paddingVertical: 7,
                            paddingHorizontal: 10,
                            backgroundColor: color.primeColor,
                          }}
                        >
                          <Image
                            source={require("../../assets/img/clock.png")}
                            style={{
                              height: 17,
                              width: 17,
                              resizeMode: "cover",
                            }}
                          />
                          <Text
                            style={{
                              ...fontBold,
                              color: "#000",
                              marginLeft: this.state.currLang === "ar" ? 0 : 6,
                              marginRight: this.state.currLang === "ar" ? 6 : 0,
                              fontSize: 12.5,
                            }}
                          >
                            <Coundown
                              finishTime={data.auction_endtime}
                              isExpired={() =>
                                this.setState({ isExpired: true })
                              }
                            />
                          </Text>
                        </View>
                      ) : null}
                    </View>
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 20,
                      }}
                    >
                      {data.aucationminimum === 1 &&
                      data.maximum_bid_price != null ? (
                        <>
                          {data.maximum_bid_price ? (
                            <Text
                              style={{
                                ...fontBold,
                                color: "#231F20",
                                fontSize: 13,
                              }}
                            >
                              SAR{" "}{data.maximum_bid_price}
                            </Text>
                          ) : null}
                        </>
                      ) : (
                        <>
                          {data.aucationminimum_amount ? (
                            <Text
                              style={{
                                ...fontBold,
                                color: "#231F20",
                                fontSize: 13,                                
                              }}
                            >
                              SAR{" "}{data.aucationminimum_amount}
                            </Text>
                          ) : null}
                        </>
                      )}
                      {data.typeofsell === "Buy it Now" ? (
                        <View style={{ marginTop: -20 }}>
                          <Text
                            style={{
                              ...fontBold,
                              color: "#231F20",
                              fontSize: 13,
                              textAlign: this.state.currLang === 'ar' ? 'end' : ''
                            }}
                          >
                            SAR{" "}{data.saleprice}
                          </Text>
                          <Text
                            style={{
                              ...fontBold,
                              color: "#231F20",
                              fontSize: 13,
                              marginBottom: 3,
                            }}
                          >
                            {data.bidCount} {this.props.t("Total Stock")} -{" "}
                            {parseInt(data.available_stocks) +
                              parseInt(data.sellingCount)}
                          </Text>
                          <Text
                            style={{
                              ...fontBold,
                              color: "#231F20",
                              fontSize: 13,
                              marginBottom: 3,
                            }}
                          >
                            {data.bidCount} {this.props.t("Sold")} -{" "}
                            {data.sellingCount}
                          </Text>
                          <Text
                            style={{
                              ...fontBold,
                              color: "#231F20",
                              fontSize: 13,
                              marginBottom: 3,
                            }}
                          >
                            {data.bidCount} {this.props.t("In Stock")} -{" "}
                            {data.available_stocks}
                          </Text>
                        </View>
                      ) : null}
                    </View>
                    <View style={{ marginTop: 32, flexDirection: "row" }}>
                      {/* user details */}
                      <TouchableOpacity
                        onPress={() =>
                          this.props.navigate("/seller-profile", {
                            state: {
                              sellerid: data.SellerDetail.id,
                              isBid: data.loginUserPlacedBidDetail
                                .product_bid_amount
                                ? data.loginUserPlacedBidDetail
                                    .product_bid_amount
                                : null,
                              productType: data.typeofsell,
                            },
                          })
                        }
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          marginRight: 10,
                          overflow: "hidden",
                          alignItems: "center",
                        }}
                      >
                        {SellerDetail.imageURL ? (
                          <Image
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: 40,
                              borderColor: "#000",
                            }}
                            source={{ uri: SellerDetail.imageURL }}
                          />
                        ) : (
                          <Image
                            style={{
                              width: 40,
                              height: 40,
                              tintColor: color.lableColor,
                            }}
                            source={require("../../assets/img/user.png")}
                          />
                        )}
                        <View
                          style={{
                            marginLeft: this.state.currLang === "ar" ? 0 : 6,
                            marginRight: this.state.currLang === "ar" ? 6 : 0,
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={{
                              ...fontBold,
                              fontSize: 14,
                              color: "#231F20",
                              marginRight: this.state.currLang == "ar" ? 0 : 40,
                            }}
                          >
                            {SellerDetail.name
                              ? SellerDetail.name + " " + SellerDetail.lname
                              : null}
                          </Text>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              ...mainStyle,
                              marginTop: 5,
                            }}
                          >
                            <RatingBar
                              value={SellerDetail.rating}
                              // onChange={rating => alert(rating)}
                            />
                          </View>
                        </View>
                      </TouchableOpacity>
                      {/* contact seller */}
                      {userid !== productuserid ? (
                        <TouchableOpacity
                          disabled={this.checkifDisabled()}
                          onPress={() =>
                            this.props.navigate("/message-details", {
                              state: {
                                sellerid: data.SellerDetail.id,
                                sender_profile: SellerDetail.imageURL,
                                sender_name:
                                  data.SellerDetail.name +
                                  " " +
                                  data.SellerDetail.lname,
                              },
                            })
                          }
                          style={{ flex: 1, alignItems: "flex-end" }}
                        >
                          <View
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <View
                              style={{
                                backgroundColor: "#F1F1F1",
                                width: 40,
                                height: 40,
                                borderRadius: 50,
                                justifyContent: "center",
                                alignItems: "center",
                                transform: this.state.currLang === 'ar' ? "scaleX(-1)" : ""
                              }}
                            >
                              <Image
                                style={{ width: 16, height: 15 }}
                                source={require("../../assets/img/Message.png")}
                              />
                            </View>
                            <View
                              style={{
                                marginLeft:
                                  this.state.currLang === "ar" ? 0 : 6,
                                marginRight:
                                  this.state.currLang === "ar" ? 6 : 0,
                              }}
                            >
                              <Text
                                style={{
                                  ...fontBold,
                                  fontSize: 14,
                                  color: "#231F20",
                                }}
                              >
                                {this.props.t("Contact Seller")}
                              </Text>
                            </View>
                          </View>
                        </TouchableOpacity>
                      ) : null}
                    </View>
                    {userid !== productuserid ? (
                      <View style={{ marginVertical: 32 }}>
                        {data.typeofsell === "Auction" &&
                        data.auction_win_by == null &&
                        !isExpired ? (
                          <>
                            {data.typeofsell === "Auction" &&
                            data?.loginUserPlacedBidDetail
                              ?.product_bid_amount != null ? (
                              <TouchableOpacity
                                onPress={() =>
                                  this.setState({
                                    isVisibleedit: true,
                                    amount: "",
                                  })
                                }
                                style={{ marginTop: 8, overflow: "hidden" }}
                              >
                                <View
                                  style={{
                                    paddingVertical: 13,
                                    borderRadius: 24,
                                    borderColor: "#231F20",
                                    borderWidth: 1,
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Image
                                    source={require("../../assets/img/edit.png")}
                                    style={{ height: 18, width: 18 }}
                                  />
                                  <Text
                                    style={{
                                      ...fontBold,
                                      textAlign: "center",
                                      marginLeft:  this.state.currLang === 'ar' ? 0  : 15,
                                      marginRight:  this.state.currLang === 'ar' ? 15  : 0,
                                      fontSize: 15,
                                      color: "#231F20",
                                    }}
                                  >
                                    {this.props.t("Bid Amount")}{" "}
                                    {data.loginUserPlacedBidDetail
                                      .product_bid_amount
                                      ? data.loginUserPlacedBidDetail
                                          .product_bid_amount + ""
                                      : null}
                                  </Text>
                                </View>
                              </TouchableOpacity>
                            ) : (
                              <TouchableOpacity
                                onPress={() =>
                                  this.setState({ isVisible: true, amount: "" })
                                }
                              >
                                <View
                                  style={{
                                    backgroundColor: "#231F20",
                                    paddingVertical: 13,
                                    borderRadius: 24,
                                    flex: 1,
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Text
                                    style={{
                                      ...fontBold,
                                      fontSize: 15,
                                      color: "#fff",
                                    }}
                                  >
                                    {this.props.t("Place Bid")}
                                  </Text>
                                </View>
                              </TouchableOpacity>
                            )}
                          </>
                        ) : null}
                        {data.typeofsell === "Buy it Now" ? (
                          <>
                            {data.available_stocks > 0 ? (
                              <TouchableOpacity
                                disabled={
                                  isVisibleQty && payment_open
                                    ? isVisibleQty
                                    : null
                                }
                                onPress={() =>
                                  this.setState({ isVisibleQty: true, qty: "" })
                                }
                              >
                                <View
                                  style={{
                                    backgroundColor: "#FFC041",
                                    paddingVertical: 13,
                                    borderRadius: 24,
                                    flex: 1,
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Text
                                    style={{
                                      ...fontBold,
                                      fontSize: 15,
                                      color: "#fff",
                                    }}
                                  >
                                    {this.props.t("Buy Now")}
                                  </Text>
                                </View>
                              </TouchableOpacity>
                            ) : (
                              <View
                                style={{
                                  backgroundColor: "#000",
                                  paddingVertical: 13,
                                  borderRadius: 24,
                                  flex: 1,
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Text
                                  style={{
                                    ...fontBold,
                                    fontSize: 15,
                                    color: "#fff",
                                  }}
                                >
                                  {this.props.t("Stock Not Available")}
                                </Text>
                              </View>
                            )}
                          </>
                        ) : null}
                        <TouchableOpacity
                          onPress={() => this.AddRemoveWatchListApi()}
                          style={{ marginTop: 8, overflow: "hidden" }}
                        >
                          <View
                            style={{
                              paddingVertical: 13,
                              borderRadius: 24,
                              borderColor: "#231F20",
                              borderWidth: 1,
                              flex: 1,
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {!isLoadingwatch ? (
                              <Image
                                style={{ height: 15, width: 12 }}
                                source={
                                  WatchListStatus
                                    ? require("../../assets/img/fill.png")
                                    : require("../../assets/img/add-to-watchlist.png")
                                }
                              />
                            ) : null}
                            {isLoadingwatch ? (
                              <ActivityIndicator color={"#000"} />
                            ) : (
                              <Text
                                style={{
                                  ...fontBold,
                                  marginLeft:
                                    this.state.currLang === "ar" ? 0 : 12,
                                  marginRight:
                                    this.state.currLang === "ar" ? 12 : 0,
                                  fontSize: 15,
                                  color: "#231F20",
                                  textAlignVertical: "center",
                                }}
                              >
                                {this.props.t("Add to Watchlist")}
                              </Text>
                            )}
                          </View>
                        </TouchableOpacity>
                      </View>
                    ) : null}
                    {data.description ? (
                      <View
                        style={{
                          flexDirection: "column",
                          paddingTop: 15,
                          marginBottom: 25,
                        }}
                      >
                        <Text
                          style={{
                            ...fontBold,
                            fontSize: 15,
                            color: "#231F20",
                          }}
                        >
                          {this.props.t("Description")}
                        </Text>
                        <View
                          style={{
                            textAlign:
                              this.state.currLang === "ar" ? "end" : "",
                            paddingTop: wp(3),
                          }}
                        >
                          <Text
                            style={{
                              ...fontReg,
                              color: "#9E9EA7",
                              fontSize: 13,
                            }}
                          >
                            {data.description}
                          </Text>
                        </View>
                      </View>
                    ) : null}

                    {/* List of Bidding */}
                    {userid === productuserid &&
                    data.typeofsell === "Auction" ? (
                      <>
                        {placebidList.length != null ? (
                          <View style={{ flex: 1, marginBottom: 80 }}>
                            {placebidList.length > 0 ? (
                              <Text
                                style={{
                                  ...fontBold,
                                  color: "#231F20",
                                  marginBottom: 20,
                                  fontSize: 15,
                                }}
                              >
                                {this.props.t("Bid List")}
                              </Text>
                            ) : null}
                            {placebidList.map((item, index) => (
                              <View
                                style={{
                                  borderWidth: 1,
                                  paddingVertical: 10,
                                  paddingHorizontal: 10,
                                  flexDirection: "row",
                                  borderRadius: 8,
                                  marginBottom: 10,
                                }}
                              >
                                <View style={{ flex: 1 }}>
                                  <Text
                                    style={{
                                      ...fontBold,
                                      color: "#000",
                                      fontSize: 13,
                                      marginBottom: 5,
                                    }}
                                  >
                                    {this.props.t("Amount")} -{" "}
                                    {item.product_bid_amount}
                                  </Text>
                                  <Text
                                    style={{
                                      ...fontBold,
                                      color: color.lableColor,
                                      fontSize: 13,
                                    }}
                                  >
                                    {this.props.t("Date")} -{" "}
                                    {moment(item.place_bid_date).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </Text>
                                </View>
                                <TouchableOpacity
                                  disabled={
                                    item.status === "1" || Winallow === "no"
                                      ? true
                                      : false
                                  }
                                  onPress={() =>
                                    this.setState({
                                      isVisibleBidStatus: true,
                                      placedbidid: item.id,
                                    })
                                  }
                                  style={{
                                    borderRadius: 8,
                                    backgroundColor:
                                      item.status === "1"
                                        ? color.primeColor
                                        : color.borderColor,
                                    paddingHorizontal: 20,
                                    height: 25,
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Text
                                    style={{
                                      ...fontBold,
                                      color: "#000",
                                      fontSize: 13,
                                    }}
                                  >
                                    {this.props.t("WIN")}
                                  </Text>
                                </TouchableOpacity>
                              </View>
                            ))}
                          </View>
                        ) : (
                          <View style={{ flex: 1 }}>
                            {!isLoading ? (
                              <View style={{ flex: 1 }}>
                                <Text
                                  style={{
                                    ...fontBold,
                                    color: "#000",
                                    fontSize: 13,
                                  }}
                                >
                                  {this.props.t("Bidlist not available")}!
                                </Text>
                              </View>
                            ) : Platform.OS === "ios" ? (
                              <ActivityIndicator
                                color={color.primeColor}
                                size="large"
                              />
                            ) : null}
                          </View>
                        )}
                      </>
                    ) : null}
                  </View>
                </>
              </ScrollView>
            ) : (
              <>
                {!isLoading ? (
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      source={require("../../assets/img/nodata.png")}
                      style={{
                        height: 60,
                        width: 60,
                        tintColor: color.primeColor,
                      }}
                    />
                    <Text
                      style={{
                        ...fontBold,
                        textAlign: "center",
                        marginTop: 10,
                        color: color.lableColor,
                      }}
                    >
                      {this.props.t("Product details not found")}!
                    </Text>
                  </View>
                ) : (
                  <>
                    <View style={{ padding: 15 }}>
                      <View
                        style={{
                          height: 250,
                          width: "100%",
                          borderRadius: 7,
                          marginBottom: 10,
                        }}
                      >
                        <Skeleton height={250} />
                      </View>
                      <View
                        style={{
                          height: 30,
                          width: "90%",
                          borderRadius: 7,
                          marginBottom: 10,
                        }}
                      >
                        <Skeleton />
                      </View>
                      <View
                        style={{
                          height: 30,
                          width: "70%",
                          borderRadius: 7,
                          marginBottom: 10,
                        }}
                      >
                        <Skeleton />
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        <View
                          style={{
                            height: 60,
                            width: 60,
                            borderRadius: 50,
                            marginBottom: 10,
                          }}
                        >
                          <Skeleton />
                        </View>
                        <View style={{ marginLeft: 10 }}>
                          <View
                            style={{
                              height: 20,
                              width: 160,
                              borderRadius: 7,
                              marginBottom: 10,
                            }}
                          >
                            <Skeleton />
                          </View>
                          <View
                            style={{
                              height: 20,
                              width: 130,
                              borderRadius: 7,
                              marginBottom: 10,
                            }}
                          >
                            <Skeleton />
                          </View>
                        </View>
                      </View>
                      <View
                        style={{
                          height: 100,
                          width: "100%",
                          borderRadius: 7,
                          marginBottom: 10,
                        }}
                      >
                        <Skeleton />
                      </View>
                      <View
                        style={{
                          height: 100,
                          width: "100%",
                          borderRadius: 7,
                          marginBottom: 10,
                        }}
                      >
                        <Skeleton />
                      </View>
                    </View>
                  </>
                )}
              </>
            )}

            {/* Click on the Place Bid and open the Modal */}
            <Modal
              transparent={true}
              animationType="fade"
              visible={isVisible}
              onDismiss={() => this.setState({ isVisible: false })}
              onRequestClose={() => this.setState({ isVisible: false })}
            >
              <KeyboardAvoidingView
                style={{
                  flex: 1,
                  backgroundColor: "#fff",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingHorizontal: 15,
                  marginHorizontal: "auto",
                }}
              >
                <TouchableWithoutFeedback>
                  <View
                    style={{
                      overflow: "scroll",
                      flex: 1,
                      paddingHorizontal: 20,
                      backgroundColor: "#FFFFFFF1",
                      justifyContent: "center",
                      width: deviceWidth,
                    }}
                  >
                    <View style={{ flex: 1, justifyContent: "center" }}>
                      <Text style={{ ...fontBold, color: "#231F20" }}>
                        {this.props.t("Enter maximum bid")}
                      </Text>
                      <TextInput
                        placeholder={this.props.t("Enter bid amount")}
                        style={{
                          ...TextInputStyle,
                          ...fontReg,
                          flex: "unset",
                          marginTop: 20,
                        }}
                        keyboardType="number-pad"
                        value={amount}
                        onChangeText={(amount) => {
                          let checkItsNumber = checkOnlyNumber(amount);
                          if (amount !== checkItsNumber) {
                            this.refs.BidSnackBar.show(checkItsNumber + "");
                          } else {
                            this.setState({ amount });
                          }
                        }}
                      />
                    </View>
                    <View style={{}}>
                      <TouchableOpacity
                        onPress={() => {
                          this.PlaceBid();
                          // this.setState({
                          //   isVisible: false,
                          //   isVisibleconfirm: true,
                          // });
                        }}
                        style={{
                          backgroundColor: "#000",
                          paddingVertical: 13,
                          marginBottom: 15,
                          borderRadius: 25,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={{
                            ...fontBold,
                            color: "#ffff",
                            fontSize: 15,
                          }}
                        >
                          {this.props.t('Submit')} 
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => this.setState({ isVisible: false })}
                      >
                        <Text
                          style={{
                            ...fontBold,
                            alignSelf: "center",
                            marginBottom: 30,
                            color: "#231F20",
                          }}
                        >
                          {this.props.t("Cancel")}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </TouchableWithoutFeedback>
                <SnackBar ref="BidSnackBar" />
              </KeyboardAvoidingView>
            </Modal>

            {/* Click on the Edit Place Bid and open the Modal */}
            <Modal
              transparent={true}
              animationType="fade"
              visible={isVisibleedit}
              onDismiss={() => this.setState({ isVisibleedit: false })}
              onRequestClose={() => this.setState({ isVisibleedit: false })}
            >
              <KeyboardAvoidingView
                style={{
                  flex: 1,
                  backgroundColor: "#fff",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingHorizontal: 15,
                  marginHorizontal: "auto",
                }}
              >
                <TouchableWithoutFeedback>
                  <View
                    style={{
                      overflow: "scroll",
                      flex: 1,
                      paddingHorizontal: 20,
                      backgroundColor: "#FFFFFFF1",
                      justifyContent: "center",
                      width: deviceWidth,
                    }}
                  >
                    <View style={{ flex: 1, justifyContent: "center" }}>
                      <Text
                        style={{
                          ...fontBold,
                          color: "#231F20",
                        }}
                      >
                        {this.props.t("Edit bid amount")}
                      </Text>
                      <TextInput
                        placeholder={this.props.t("Enter bid amount")}
                        style={{
                          ...TextInputStyle,
                          ...fontReg,
                          flex: "unset",
                          marginTop: 20,
                          textAlign: this.state.currLang === 'ar' ? 'end' : ''
                        }}
                        keyboardType="number-pad"
                        value={amountedit}
                        onChangeText={(amountedit) => {
                          let checkItsNumber = checkOnlyNumber(amountedit);
                          if (amountedit !== checkItsNumber) {
                            this.refs.EdSnackBar.show(checkItsNumber + "");
                          } else {
                            this.setState({ amountedit });
                          }
                        }}
                      />
                    </View>
                    <View style={{}}>
                      <TouchableOpacity
                        onPress={() =>
                          this.PlaceEditBid(data.loginUserPlacedBidDetail.id)
                        }
                        style={{
                          backgroundColor: "#000",
                          paddingVertical: 13,
                          marginBottom: 15,
                          borderRadius: 25,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {isLoadingBidedit ? (
                          <ActivityIndicator color={"#fff"} />
                        ) : (
                          <Text
                            style={{
                              ...fontBold,
                              color: "#ffff",
                              fontSize: 15,
                            }}
                          >
                            {this.props.t("Submit")}
                          </Text>
                        )}
                      </TouchableOpacity>
                      <TouchableOpacity
                        // onPress={() => this.setState({ isVisibleedit: false })}
                        onPress={() => this.productdetail()}
                      >
                        <Text
                          style={{
                            ...fontBold,
                            alignSelf: "center",
                            marginBottom: 30,
                            color: "#231F20",
                          }}
                        >
                          {this.props.t("Cancel")}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </TouchableWithoutFeedback>
                <SnackBar ref="EdSnackBar" />
              </KeyboardAvoidingView>
            </Modal>

            {/* Bid Sale Product Quantity Modal */}
            <Modal
              transparent={true}
              animationType="fade"
              visible={isVisibleQty}
              onDismiss={() => this.setState({ isVisibleQty: false })}
              onRequestClose={() => this.setState({ isVisibleQty: false })}
            >
              <KeyboardAvoidingView
                style={{
                  flex: 1,
                  // backgroundColor: "#fff",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingHorizontal: 15,
                  marginHorizontal: "auto",
                }}
              >
                {/* <TouchableOpacity activeOpacity={1} onPress={() => this.setState({ isVisibleQty: false })} style={{ flex: 1, justifyContent: 'center', backgroundColor: '#0004', }}>
                  <TouchableWithoutFeedback> */}
                <View
                  style={{
                    backgroundColor: "#0004",
                    justifyContent: "center",

                    flex: 1,
                    overflowY: "scroll",
                    paddingHorizontal: 20,
                    width: deviceWidth,
                  }}
                >
                  <View
                    style={{
                      ...shadow,
                      padding: 20,
                      marginHorizontal: 20,
                      backgroundColor: "#fff",
                      borderRadius: 25,
                    }}
                  >
                    <View style={{ flexDirection: "row" }}>
                      <Text
                        style={{
                          ...fontBold,
                          flex: 1,
                          color: "#000",
                          fontSize: 15,
                          marginBottom: 15,
                        }}
                      >
                        {this.props.t("Enter Quantity")}
                      </Text>
                      <TouchableOpacity
                        onPress={() => this.setState({ isVisibleQty: false })}
                        style={{
                          height: 25,
                          width: 25,
                          justifyContent: "center",
                          alignItems: "flex-end",
                        }}
                      >
                        <Image
                          source={require("../../assets/img/cancle_icon.png")}
                          style={{
                            height: "60%",
                            width: "60%",
                            tintColor: "#000",
                            resizeMode: "contain",
                          }}
                        />
                      </TouchableOpacity>
                    </View>
                    <TextInput
                      placeholder={this.props.t("Enter Quantity")}
                      style={{
                        ...TextInputStyle,
                        ...fontReg,
                        fontSize: 13,
                        borderWidth: 1,
                        borderRadius: 25,
                        paddingHorizontal: 10,
                        textAlign: this.state.currLang === 'ar' ? 'end' : ''
                      }}
                      keyboardType="number-pad"
                      maxLength={6}
                      value={qty}
                      // onChangeText={(qty) => this.setState({ qty })}
                      onChangeText={(qty) => {
                        let checkItsNumber = checkOnlyNumber(qty);
                        if (qty !== checkItsNumber) {
                          this.refs.MSnackBar.show(checkItsNumber + "");
                        } else {
                          this.setState({ qty });
                        }
                      }}
                    />
                    <TouchableOpacity
                      onPress={() => this.addQty()}
                      style={{
                        backgroundColor: color.primeColor,
                        borderRadius: 25,
                        justifyContent: "center",
                        alignItems: "center",
                        marginVertical: 15,
                        paddingVertical: 12,
                      }}
                    >
                      <Text
                        style={{
                          ...fontBold,
                          color: "#fff",
                          fontSize: 15,
                        }}
                      >
                        {this.props.t("Add Quantity")}
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <SnackBar ref="MSnackBar" />
                </View>
                {/* </TouchableWithoutFeedback>
              </TouchableOpacity> */}
              </KeyboardAvoidingView>
            </Modal>

            <Modal
              transparent={true}
              animationType="fade"
              visible={isVisibleAddOrder}
              onDismiss={() => this.setState({ isVisibleAddOrder: false })}
              onRequestClose={() => this.setState({ isVisibleAddOrder: false })}
            >
              {/* <TouchableOpacity activeOpacity={1} onPress={() => this.setState({ isVisibleAddOrder: false })} style={{ flex: 1, justifyContent: 'center', backgroundColor: '#0004', }}> */}
              <KeyboardAvoidingView
                style={{
                  flex: 1,
                  // backgroundColor: "#fff",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingHorizontal: 15,
                  marginHorizontal: "auto",
                }}
              >
                <View
                  style={{
                    backgroundColor: "#0004",
                    justifyContent: "center",

                    flex: 1,
                    overflowY: "scroll",
                    paddingHorizontal: 20,
                    width: deviceWidth,
                  }}
                >
                  <View
                    style={{
                      ...shadow,
                      borderRadius: 15,
                      backgroundColor: "#ffff",
                      marginHorizontal: 30,
                      paddingBottom: 15,
                      overflow: "hidden",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: font.bold,
                        color: "#000",
                        fontSize: 18,
                        paddingHorizontal: 15,
                        textAlign: "center",
                        backgroundColor: color.primeColor,
                        paddingVertical: 15,
                      }}
                    >
                      {this.props.t("Order Details")}{" "}
                    </Text>
                    <View style={{ marginHorizontal: 20, marginTop: 15 }}>
                      <Text
                        style={{
                          fontFamily: font.reg,
                          color: "#000",
                          fontSize: 15,
                          marginBottom: 10,
                        }}
                      >
                        {this.props.t("Product Name")} :{" "}
                        <Text style={{ fontFamily: font.bold }}>
                          {data.name}
                        </Text>
                      </Text>
                      <Text
                        style={{
                          fontFamily: font.reg,
                          color: "#000",
                          fontSize: 15,
                          marginBottom: 10,
                        }}
                      >
                        {this.props.t("Price")} :{" "}
                        <Text style={{ fontFamily: font.bold }}>
                          SAR{" "}{data.saleprice}
                        </Text>
                      </Text>
                      <Text
                        style={{
                          fontFamily: font.reg,
                          color: "#000",
                          fontSize: 15,
                          marginBottom: 10,
                        }}
                      >
                        {this.props.t("Qty")} :{" "}
                        <Text style={{ fontFamily: font.bold }}>
                          {displayqty}
                        </Text>
                      </Text>
                      <TouchableOpacity
                        onPress={() =>
                          this.addOrder(null, null, admincommission)
                        }
                        style={{
                          backgroundColor: color.primeColor,
                          borderRadius: 25,
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 15,
                          paddingVertical: 12,
                        }}
                      >
                        {isOrderLoading ? (
                          <ActivityIndicator color={"#fff"} />
                        ) : (
                          <Text
                            style={{
                              fontFamily: font.bold,
                              color: "#fff",
                              fontSize: 15,
                            }}
                          >
                            {this.props.t("Order Placed")}
                          </Text>
                        )}
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </KeyboardAvoidingView>
              {/* </TouchableOpacity> */}
              <SnackBar ref="AddOrderSnackBar" />
            </Modal>

            <Modal
              transparent={true}
              animationType="fade"
              visible={isVisibleBidStatus}
              onDismiss={() => this.setState({ isVisibleBidStatus: false })}
              onRequestClose={() =>
                this.setState({ isVisibleBidStatus: false })
              }
            >
              <View
                style={{
                  flex: 1,
                  backgroundColor: "#0004",
                  justifyContent: "center",

                  alignItems: "center",
                  paddingHorizontal: 15,
                  marginHorizontal: "auto",
                }}
              >
                <TouchableWithoutFeedback
                  onPress={() => this.setState({ isVisibleBidStatus: false })}
                >
                  <View
                    style={{
                      ...shadow,
                      borderRadius: 15,
                      backgroundColor: "#ffff",
                      marginHorizontal: 30,
                      paddingVertical: 15,
                    }}
                  >
                    <Text
                      style={{
                        ...fontBold,
                        color: "#000",
                        fontSize: 15,
                        paddingHorizontal: 15,
                      }}
                    >
                      {this.props.t("Are you sure you win to this bid")}.
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        marginHorizontal: 20,
                        marginTop: 15,
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <View
                        borderColor="#000"
                        style={{
                          borderWidth: 1.5,
                          borderRadius: 20,
                          paddingVertical: 5,
                          marginRight: 10,
                          paddingHorizontal: 15,
                        }}
                      >
                        <TouchableOpacity
                          onPress={() =>
                            this.setState({ isVisibleBidStatus: false })
                          }
                        >
                          <Text
                            style={{
                              ...fontBold,
                              color: "#000",
                              fontSize: 13,
                            }}
                          >
                            {this.props.t("No")}
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <TouchableOpacity
                        onPress={() => this.addPlacebid(placedbidid)}
                        style={{
                          borderRadius: 20,
                          backgroundColor: "#000",
                          paddingHorizontal: 15,
                        }}
                      >
                        {isLoadingStatusWin ? (
                          <ActivityIndicator
                            style={{ paddingVertical: 4 }}
                            color={"#ffff"}
                          />
                        ) : (
                          <Text
                            style={{
                              ...fontBold,
                              color: "#ffff",
                              fontSize: 13,
                              paddingVertical: 6,
                            }}
                          >
                            {this.props.t("Yes")}
                          </Text>
                        )}
                      </TouchableOpacity>
                    </View>
                  </View>
                </TouchableWithoutFeedback>
              </View>
            </Modal>

            {/* Confirmation modal */}
            <Modal
              transparent={true}
              animationType="fade"
              visible={isVisibleconfirm}
              onDismiss={() => this.setState({ isVisibleconfirm: false })}
              onRequestClose={() => this.setState({ isVisibleconfirm: false })}
            >
              <View
                style={{
                  flex: 1,
                  backgroundColor: "#0004",
                  justifyContent: "center",

                  alignItems: "center",
                  paddingHorizontal: 15,
                  marginHorizontal: "auto",
                }}
              >
                <TouchableWithoutFeedback
                  onPress={() => this.setState({ isVisibleconfirm: false })}
                >
                  <View
                    style={{
                      padding: 15,
                      ...shadow,
                      borderRadius: 8,
                      backgroundColor: "#ffff",
                      width: deviceWidth - 31 + "px",
                    }}
                  >
                    <Text
                      style={{
                        ...fontBold,
                        color: "#000",
                        fontSize: 15,
                        paddingHorizontal: 15,
                      }}
                    >
                      {this.props.t("Are you sure you want to add this bid")}.
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        marginHorizontal: 20,
                        marginTop: 15,
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <View
                        borderColor="#000"
                        style={{
                          borderWidth: 1.5,
                          borderRadius: 20,
                          paddingVertical: 5,
                          marginRight: 10,
                          paddingHorizontal: 15,
                        }}
                      >
                        <TouchableOpacity
                          onPress={() =>
                            this.setState({ isVisibleconfirm: false })
                          }
                        >
                          <Text
                            style={{
                              ...fontBold,
                              color: "#000",
                              fontSize: 13,
                            }}
                          >
                            {this.props.t("No")}
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <TouchableOpacity
                        // onPress={() => this.PlaceBid()}
                        onPress={() => this.PlaceBidAdd()}
                        style={{
                          borderRadius: 20,
                          backgroundColor: "#000",
                          paddingHorizontal: 15,
                        }}
                      >
                        {isLoadingBid ? (
                          <ActivityIndicator
                            style={{ paddingVertical: 4 }}
                            color={"#ffff"}
                          />
                        ) : (
                          <Text
                            style={{
                              ...fontBold,
                              color: "#ffff",
                              fontSize: 13,
                              paddingVertical: 6,
                            }}
                          >
                            {this.props.t("Yes")}
                          </Text>
                        )}
                      </TouchableOpacity>
                    </View>
                  </View>
                </TouchableWithoutFeedback>
                <SnackBar ref="AddBidSnackBar" />
              </View>
            </Modal>

            {/* Payment Capture */}
            {/* <Modal
              transparent={true}
              animationType="fade"
              visible={payment_open === -1}
              onRequestClose={() => this.setState({ payment_open: false })}
            >
              <View
                style={{
                  flex: 1,
                  backgroundColor: "#FFF",
                  justifyContent: "center",
                  // alignItems: "center",
                  paddingHorizontal: 15,
                  marginHorizontal: "auto",
                }}
              >
                <iframe
                  style={{
                    paddingTop: 10,
                    width: deviceWidth - 21 + "px",
                    height: "100%",
                  }}
                  src={payment}
                />
                <View style={{}}>
                  <View>
                    <Text>Auto Expire</Text>
                  </View>
                  <View
                    style={{
                      display: "none",
                      flexDirection: "row",
                      paddingHorizontal: 15,
                      marginBottom: 20,
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <View
                      borderColor={"#000"}
                      style={{ flex: 1, borderWidth: 1.5, borderRadius: 50 }}
                    >
                      <TouchableOpacity
                        onPress={() =>
                          this.setState({ payment_open: false, payment: "" })
                        }
                        style={{ paddingVertical: 15, alignItems: "center" }}
                      >
                        <Text style={{ fontFamily: font.bold, fontSize: 15 }}>
                          Clear
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ flex: 1 }}>
                      <TouchableOpacity
                        onPress={() =>
                          this.setState(
                            {
                              isVisibleSearch: false,
                              page: 1,
                              totalPage: 1,
                              isLoading: true,
                            },
                            () => this.ProductList()
                          )
                        }
                        style={{
                          marginLeft: 15,
                          flex: 1,
                          paddingVertical: 15,
                          borderRadius: 50,
                          alignItems: "center",
                          backgroundColor: "#000",
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: font.bold,
                            color: "#fff",
                            fontSize: 15,
                          }}
                        >
                          Complete
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>
            </Modal> */}
            <SnackBar ref="SnackBar" />
          </View>
        )}
      </>
    );
  }
}

export default withTranslation()(withRouter(ProductDetail));
