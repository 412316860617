import React, { Component } from "react";
import {
  View,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  KeyboardAvoidingView,
  Modal,
  Keyboard,
  // Platform,
} from "react-native";

import { API } from "../../Privet";

import { color, font, fontBold, fontReg, shadow, TextInputStyle } from "../Component/Styles";
import Header from "../Component/Header";

import AutoComplete from "../Component/AutoComplete";
import SnackBar from "../Component/SnackBar";
import { clearUserLocalStorage, getUserData } from "../../utility/Utils";
import { withRouter } from "../../withRouter";
// import Picker from '../Component/Picker';
// import ImagePicker from "../Component/ImagePicker";

// import { request, PERMISSIONS, RESULTS, openSettings } from 'react-native-permissions';
// import { TextInput } from 'react-native-gesture-handler';

import ReactGoogleAutocomplete from "react-google-autocomplete";

import { withTranslation } from 'react-i18next';

const userData = getUserData();

class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isRefreshing: false,
      isFormSubmit: false,
      isVisible: false,
      isVisibleamount: false,
      productstatus: "Active",
      images: [],

      ProductId: "",
      productTitle: "",
      salesPrice: "",
      description: "",

      categoryList: [],
      CategoryName: [],

      subcategoryList: [],
      subCategoryName: [],
      second: 0,
      location: {},

      typesell: "",
      auctionlenght: "",
      minimum: "",
      otherdays: "",
      minimumamount: "",
      stock: "",
      days: "",
      type: "",
      data: "",
      imagelength: "",

      address: "",

      deviceHeight: null,
      deviceWidth: "468",
      currLang: window.localStorage.getItem("lang") ?? "en",
    };
  }

  async componentDidMount() {
    let { deviceHeight, deviceWidth } = JSON.parse(
      await localStorage.getItem("kushkDevice")
    );
    deviceWidth = deviceWidth - 51;
    await this.setState({ deviceHeight, deviceWidth });

    // this.AutoComplete.show(this.props.description);
    // await Global.main.isTabBar(false);
    // const data = userData;
    let location_state = await this.props.location.state;
    const type = location_state?.type;
    const data = location_state?.data;
    // const type = await this.props.navigation.getParam('type');
    // console.log("Add Product", this.props.location);
    if (type === "Edit") {
      await this.setState({ ProductId: data.id });
      // await this.productdetail();
    }

    await this.setState({
      data,
      type,
      imagelength:
        data != null && data.images != null ? data.images.length : "",
    });

    await this.CategoryList();
    // data.id_category ? this.SubCategoryList(data.id_category) : "";
    this.SubCategoryList(data.id_category ? data.id_category + "" : "");
    if (data != null) {
      this.setState({
        productTitle: data.name,
        description: data.description,
        typesell: data.typeofsell,
        CategoryName:
          data.id_category != null
            ? { id: data.id_category, name: data.category_name }
            : { id: "2" },
        // : [],
        subCategoryName:
          data.id_subcategory != null
            ? { id: data.id_subcategory, name: data.sub_category_name }
            : [],
        minimum: data.aucationminimum === 1 ? "Yes" : "No",
        salesPrice: data.saleprice ? data.saleprice + "" : "",
        minimumamount: data.aucationminimum_amount
          ? data.aucationminimum_amount + ""
          : "",
        stock: data.available_stocks ? data.available_stocks + "" : "",
        location: {
          description: data.location ? data.location : data.google_location,
        },
      });
      if (data.auctiondays === "5") {
        this.setState({ auctionlenght: "5" });
      } else if (data.auctiondays === "10") {
        this.setState({ auctionlenght: "10" });
      } else {
        this.setState({
          auctionlenght: "other",
          otherdays: data.auctiondays ? data.auctiondays : 1,
          days: data.auctiondays ? data.auctiondays : 1,
        });
      }
    }
  }

  willFocus() {
    
  }

  onSelectIMG(img) {
    console.log("Add Product 129", img);
    const { images } = this.state;
    let tempImg = img.target.files[0];
    // tempImg = URL.createObjectURL(tempImg);
    if (typeof tempImg !== undefined+"") {
      console.log("Add Product 155", tempImg);
      images.push(tempImg);
      this.setState({ images });
    }
  }

  removeIndexIMG(index) {
    const { images } = this.state;
    images.splice(index, 1);
    this.setState({ images });
  }

  async productdetail() {
    const { token, userid } = userData;
    const { ProductId } = this.state;
    this.setState({ isLoading: true });

    let formdata = new FormData();
    formdata.append("id", ProductId + "");
    formdata.append("loginAppUserid", userid + "");
    formdata.append("getWatchlist", true);
    formdata.append("getSellerDetail", true);

    // console.log("Product Detail 114", formdata);

    await fetch(API.productdetail, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    })
      .then((response) => {
        if (response.status === 401) {
          clearUserLocalStorage();
          this.props.navigate("/login");
        }
        return response.json();
      })
      .then((res) => {
        if (res.success) {
          this.setState({
            data: res.data,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }

  CategoryList() {
    const { token } = userData;
    // console.log("Category List 134", userData);
    fetch(API.maincategorylist, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          clearUserLocalStorage();
          this.props.navigate("/login");
        }
        return response.json();
      })
      .then((res) => {
        if (res.success) {
          this.setState({
            isLoading: false,
            isRefreshing: false,
            categoryList: res.data,
          });
          this.SubCategoryList();
        } else {
          this.setState({ isLoading: false, isRefreshing: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, isRefreshing: false });
        console.log(error);
      });
  }

  SubCategoryList(id) {
    const { token } = userData;
    if (typeof id !== "undefined" && id != null && id !== "") {
      console.log("AddProject.js 164", id);
      let formdata = new FormData();
      formdata.append("id", id);

      fetch(API.subcategorylist, {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (response.status === 401) {
            clearUserLocalStorage();
            this.props.navigate("/login");
          }
          return response.json();
        })
        .then((res) => {
          if (res.success) {
            this.setState({
              isLoading: false,
              isRefreshing: false,
              subcategoryList: res.data,
            });
          } else {
            this.setState({ isLoading: false, isRefreshing: false });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false, isRefreshing: false });
          console.log(error);
        });
    }
  }

  EnterDays() {
    let { otherdays } = this.state;
    // Keyboard.dismiss();

    if (!otherdays) {
      this.ModalOtherDays.show(this.props.t("Please enter days."));
    } else if (otherdays === "5" || otherdays === "10") {
      this.ModalOtherDays.show(this.props.t("Please enter different days."));
    } else {
      if (otherdays + "" === "") {
        otherdays = 0;
      }
      this.setState({
        isVisible: false,
        auctionlenght: "other",
        days: otherdays,
      });
    }
  }

  EnterminAmount() {
    const { minimumamount } = this.state;
    Keyboard.dismiss();
    if (!minimumamount) {
      this.ModalSnackbar.show(this.props.t("Please enter minimum amount."));
    } else {
      this.setState({ isVisibleamount: false, minimum: "Yes" });
    }
  }

  AddProduct = async () => {
    const { userid, token } = userData;
    const {
      productTitle,
      images,
      auctionlenght,
      description,
      CategoryName,
      subCategoryName,
      typesell,
      salesPrice,
      stock,
      otherdays,
      minimumamount,
      location,
    } = this.state;
    Keyboard.dismiss();

    if (images.length === 0) {
      this.refs.Snackbar.show(this.props.t("Please select images."));
    } else if (!productTitle) {
      this.refs.Snackbar.show(this.props.t("Please enter product title."));
    } else if (!description) {
      this.refs.Snackbar.show(this.props.t("Please enter product description."));
    } else if (!location.description) {
      this.refs.Snackbar.show(this.props.t("Please enter location."));
    } else if (!CategoryName.id) {
      this.refs.Snackbar.show(this.props.t("Please select category."));
    } else if (!subCategoryName.id) {
      this.refs.Snackbar.show(this.props.t("Please select subCategory."));
    } else if (!typesell) {
      this.refs.Snackbar.show(this.props.t("Please select type of sell."));
    } else if (typesell === "Auction" && !auctionlenght) {
      this.refs.Snackbar.show(this.props.t("Please select auction length."));
    } else if (
      typesell === "Auction" &&
      auctionlenght === "other" &&
      !otherdays
    ) {
      this.refs.Snackbar.show(this.props.t("Please enter other days."));
    } else if (typesell === "Auction" && !minimumamount) {
      this.refs.Snackbar.show(this.props.t("Please enter minimum amount."));
    } else if (typesell === "Buy it Now" && !salesPrice) {
      this.refs.Snackbar.show(this.props.t("Please enter sale price."));
    } else if (typesell === "Buy it Now" && !stock) {
      this.refs.Snackbar.show(this.props.t("Please enter available stock."));
    } else {
      this.setState({ isLoading: true });
      const formdata = new FormData();
      let index = 0;

      formdata.append("id_appuser", userid);
      formdata.append("name", productTitle);
      formdata.append("description", description);
      formdata.append("id_category", CategoryName.id);
      formdata.append("id_subcategory", subCategoryName.id);
      formdata.append("typeofsell", typesell);

      if (typesell === "Auction") {
        formdata.append("auctiondays", auctionlenght);
        formdata.append("auction_lenght", otherdays);
        formdata.append("aucationminimum", 1);
        formdata.append("aucationminimum_amount", minimumamount);
      }
      if (typesell === "Buy it Now") {
        formdata.append("saleprice", salesPrice);
        formdata.append("available_stocks", stock);
      }

      formdata.append("location", location.description);
      formdata.append("latitude", location.lat + "");
      formdata.append("longitude", location.lng + "");
      formdata.append("google_location", location.description);

      for (const item of images) {
        formdata.append(`attachment[${index}][attachfile]`, item);
        index = index + 1;
      }
      await fetch(API.productadd, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          // Accept: "application/json",
          // 'Content-Type': 'multipart/form-data',
        },
        body: formdata,
      })
        .then((response) => {
          if (response.status === 401) {
            clearUserLocalStorage();
            this.props.navigate("/login");
          }
          return response.json();
        })
        .then((res) => {
          if (res.result === "success") {
            this.setState({ isLoading: false });
            this.props.navigate('/');
            // this.props.navigation.navigate("Home", { type: "AddProduct" });
            this.refs.Snackbar.show(res.message);
          } else {
            this.refs.Snackbar.show(res.message);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  /*timer() {
    this.setState({ second: 30 });
    this.interval = setInterval(() => {
      const { second } = this.state;
      if (second) {
        this.setState({ second: second - 1 });
      } else {
        this.interval ? clearInterval(this.interval) : null;
      }
    }, 1000);
  }*/

  EditProduct = async (ProductEditId) => {
    const { userid, token } = getUserData();
    const {
      productTitle,
      data,
      location,
      productstatus,
      images,
      auctionlenght,
      minimum,
      description,
      CategoryName,
      subCategoryName,
      typesell,
      salesPrice,
      stock,
      otherdays,
      minimumamount,
    } = this.state;

    if (images.length === 0 && data.images.length === 0) {
      this.refs.Snackbar.show(this.props.t("Please select images."));
    } else if (!productTitle) {
      this.refs.Snackbar.show(this.props.t("Please enter product title."));
    } else if (!description) {
      this.refs.Snackbar.show(this.props.t("Please enter product description.."));
    } else if (!location.description) {
      this.refs.Snackbar.show(this.props.t("Please enter location."));
    } else if (!CategoryName.id) {
      this.refs.Snackbar.show(this.props.t("Please select category."));
    } else if (!subCategoryName.id) {
      this.refs.Snackbar.show(this.props.t("Please select subCategory."));
    } else if (!typesell) {
      this.refs.Snackbar.show(this.props.t("Please select type of sell."));
    } else if (typesell === "Auction" && !auctionlenght) {
      this.refs.Snackbar.show(this.props.t("Please select auction length."));
    } else if (
      typesell === "Auction" &&
      auctionlenght === "other" &&
      !otherdays
    ) {
      this.refs.Snackbar.show(this.props.t("Please enter other days."));
    } else if (typesell === "Auction" && !minimumamount) {
      this.refs.Snackbar.show(this.props.t("Please enter minimum amount."));
    } else if (typesell === "Buy it Now" && !salesPrice) {
      this.refs.Snackbar.show(this.props.t("Please enter sale price."));
    } else if (typesell === "Buy it Now" && !stock) {
      this.refs.Snackbar.show(this.props.t("Please enter available stock."));
    } else {
      this.setState({ isLoading: true });
      let formdata = new FormData();
      let index = 0;

      formdata.append("edit_id", ProductEditId);
      formdata.append("id_appuser", userid);
      formdata.append("name", productTitle);
      formdata.append("description", description);
      formdata.append("id_category", CategoryName.id);
      formdata.append("id_subcategory", subCategoryName.id);
      formdata.append("typeofsell", typesell);

      if (typesell === "Auction") {
        formdata.append("auctiondays", auctionlenght);
        formdata.append("auction_lenght", otherdays);
        formdata.append("aucationminimum", 1);
        formdata.append("aucationminimum_amount", minimumamount);
      }
      if (typesell === "Buy it Now") {
        formdata.append("saleprice", salesPrice);
        formdata.append("available_stocks", stock);
      }

      formdata.append(
        "location",
        location.description ? location.description : data.location
      );
      formdata.append(
        "latitude",
        location.lat ? location.lat + "" : data.latitude + ""
      );
      formdata.append(
        "longitude",
        location.lng ? location.lng + "" : data.longitude + ""
      );

      formdata.append(
        "google_location",
        location.description ? location.description : data.location
      );

      // for (const item of images) {
      //   formdata.append(`attachment[${index}][attachfile]`, {
      //     uri: item.path,
      //     name: "image.png",
      //     type: "image/png",
      //   });
      //   index = index + 1;
      // }
      for (const item of images) {
        formdata.append(`attachment[${index}][attachfile]`, item);
        index = index + 1;
      }
      formdata.append("status", productstatus === "Active" ? "1" : "0");

      await fetch(API.productedit, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          // Accept: "application/json",
        },
        body: formdata,
      })
        .then((response) => {
          if (response.status === 401) {
            clearUserLocalStorage();
            this.props.navigate("/login");
          }
          return response.json();
        })
        .then((res) => {
          if (res.success) {
            this.setState({ isLoading: false });
            this.refs.Snackbar.show(res.message);
            this.props.navigate(-1);
            // this.props.navigation.navigate("ProductDetail");
          } else {
            this.refs.Snackbar.show(res.message);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  checkPermission(description) {
    this.AutoComplete.show(this.props.description);
    /*request(Platform.OS === 'ios' ? PERMISSIONS.IOS.LOCATION_WHEN_IN_USE : PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION)
      .then((result) => {
        if (result === RESULTS.UNAVAILABLE) {
        }
        else if (result === RESULTS.DENIED) {
          openSettings();
        }
        else if (result === RESULTS.LIMITED) {
          this.AutoComplete.show(description);
        }
        else if (result === RESULTS.GRANTED) {
          this.AutoComplete.show(description);
        }
        else if (result === RESULTS.BLOCKED) {
          openSettings();
        }
      })
      .catch(e => {
        console.log(e)
      });*/
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  // handleSelect = (address) => {
  //   geocodeByAddress(address)
  //     .then((response) => {
  //       if (response.status === 401) {
  //         clearUserLocalStorage();
  //         this.props.navigate("/login");
  //       }
  //       return response.json();
  //     })
  //     .then((response) => {
  //       console.log("Success", response);
  //       getLatLng(response[0]);
  //     })
  //     .then((latLng) => console.log("Success", latLng));
  // };

  render() {
    const {
      images,
      days,
      isLoading,
      productstatus,
      type,
      isVisible,
      data,
      stock,
      CategoryName,
      categoryList,
      minimumamount,
      isVisibleamount,
      otherdays,
      subCategoryName,
      subcategoryList,
      typesell,
      auctionlenght,
      productTitle,
      salesPrice,
      description,
      location,
      deviceWidth,
    } = this.state;
    console.log("AddProduct.js 520", this.state);

    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <Header
          title={
            type === "Edit" ? this.props.t("Edit product to sell") : this.props.t("Add product to sell")
          }
          Limg={require("../../assets/img/back.png")}
          Lpress={() => this.props.navigate(-1)}
        />

        <KeyboardAvoidingView style={{ flex: 1, backgroundColor: "#ffff" }}>
          <ScrollView bounces={false} contentContainerStyle={{ flexGrow: 1 }}>
            <View style={{ flex: 1 }}>
              <View
                style={{
                  paddingBottom: 30,
                  width: "100%",
                  backgroundColor: "#F5F6FA",
                }}
              >
                <Image
                  source={require("../../assets/img/Camera.png")}
                  style={{
                    height: 120,
                    width: 120,
                    alignSelf: "center",
                    marginTop: 60,
                  }}
                />
                <Text
                  style={{
                    ...fontReg,
                    color: "##A19E9E",
                    alignSelf: "center",
                  }}
                >
                  {this.props.t('Tap to upload picture')}
                </Text>

                {/* Add More Image */}
                <View style={{ flex: 1, paddingTop: 30 }}>
                  {/* Multipal Image or Add More image */}
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      paddingLeft: 12,
                    }}
                  >
                    {type === "Edit" ? (
                      <>
                        {data.images
                          ? data.images.map((item, index) => (
                              <View
                                style={{
                                  height: 60,
                                  width: 60,
                                  borderRadius: 10,
                                  marginRight: 12,
                                  overflow: "hidden",
                                }}
                              >
                                <Image
                                  source={{ uri: item.product_image }}
                                  style={{ height: "100%", width: "100%" }}
                                />
                              </View>
                            ))
                          : null}
                      </>
                    ) : (
                      <></>
                    )}
                    {/* Image Preview */}
                    {images.map((item, index) => (
                      <View
                        style={{
                          height: 60,
                          width: 60,
                          borderRadius: 10,
                          marginRight: 12,
                          overflow: "hidden",
                        }}
                      >
                        {/* <Image
                              source={{ uri: item }}
                              style={{ height: "100%", width: "100%" }}
                            /> */}
                        <img
                          // src={item}
                          src={URL.createObjectURL(item)}
                          style={{ height: "100%", width: "100%" }}
                          alt={index}
                        />
                      </View>
                    ))}

                    {this.state.imagelength + images.length < 4 ? (
                      <TouchableOpacity
                        // onPress={() => this.ImageUploadWithPreview}
                        onPress={() => {
                          this.upload.click();
                        }}
                        style={{
                          ...shadow,
                          elevation: 3,
                          height: 60,
                          width: 60,
                          borderRadius: 10,
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: 12,
                        }}
                      >
                        <Image
                          source={require("../../assets/img/plus-sign.png")}
                          style={{ height: "50%", width: "50%" }}
                        />
                        <input
                          type="file"
                          id="file"
                          ref={(ref) => (this.upload = ref)}
                          style={{ display: "none" }}
                          // webkitdirectory="true"
                          onChange={this.onSelectIMG.bind(this)}
                        />
                      </TouchableOpacity>
                    ) : null}
                  </View>
                </View>
              </View>
              <View style={{ paddingHorizontal: 20, paddingTop: 20 }}>
                <Text
                  style={{ ...fontBold, color: "#000", fontSize: 14 }}
                >
                 {this.props.t("Product Title")}
                </Text>
                <TextInput
                  placeholder={this.props.t("Enter product title")}
                  // style={{ borderWidth: 1, borderRadius: 5, borderColor: '#DEE1E6', paddingHorizontal: 15, marginTop: 15, color: '#000', marginBottom: 25, fontFamily: font.reg, }}
                  style={{ ...TextInputStyle, marginTop: 15, marginBottom: 25, textAlign: this.state.currLang === 'ar' ? 'end' : ''}}
                  value={productTitle}
                  onChangeText={(productTitle) =>
                    this.setState({ productTitle })
                  }
                />
                <Text
                  style={{ ...fontBold, color: "#000", fontSize: 14 }}
                >
                  {this.props.t('Description')}
                </Text>
                <TextInput
                  placeholder={this.props.t("Write description")}
                  multiline
                  style={{
                    ...TextInputStyle,
                    height: 120,
                    marginTop: 15,
                    textAlignVertical: "top",
                    marginBottom: 25,
                    flex: "auto",
                    textAlign: this.state.currLang === 'ar' ? 'end' : ''
                  }}
                  value={description}
                  onChangeText={(description) => this.setState({ description })}
                />

                <View style={{ flexDirection: "row", display: "none;" }}>
                  <Text
                    style={{
                      ...fontBold,
                      flex: 1,
                      color: "#000",
                      fontSize: 14,
                      textAlignVertical: "center",
                    }}
                  >
                    {this.props.t('Location')}
                  </Text>

                  {/* <TouchableOpacity
                    onPress={() => this.checkPermission(location.description)}
                    style={{
                      backgroundColor: color.primeColor,
                      borderRadius: 5,
                      paddingHorizontal: 15,
                      paddingVertical: 5,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: font.bold,
                        color: "#000",
                        fontSize: 14,
                      }}
                    >
                      Select Location
                    </Text>
                  </TouchableOpacity> */}
                </View>
                <View>
                  <ReactGoogleAutocomplete
                    placeholder={this.props.t("Enter a Location")}
                    style={{
                      ...TextInputStyle,
                      marginHorizontal: 0,
                      borderBottomWidth: 1,
                      marginTop: 5,
                      marginBottom: 25,
                    }}
                    apiKey={API.googleMapKey}
                    options={{ types: ["address"], components: ["sa"] }}
                    defaultValue={location.description}
                    // onPlaceSelected={(place) => console.log(place)}
                    onPlaceSelected={(item) => {
                      // console.log("AddProduct 868", { ...item },item?.geometry?.location);
                      let location = {
                        description: item.formatted_address + "",
                        lat: item?.geometry?.location?.lat() + "",
                        lng: item?.geometry?.location?.lng() + "",
                      };
                      this.setState({ location: { ...location } });
                    }}
                  />
                </View>
                {/* {location.description ? (
                  <Text
                    style={{
                      paddingVertical: 10,
                      borderRadius: 5,
                      borderWidth: 1,
                      borderColor: "#DEE1E6",
                      marginVertical: 15,
                      paddingHorizontal: 10,
                      fontFamily: font.reg,
                      color: "#000",
                    }}
                  >
                    {location.description}
                  </Text>
                ) : null} */}
                <View>
                  <Text
                    style={{
                      ...fontBold,
                      color: "#000",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t('Category')}
                  </Text>
                  <select
                    style={{
                      ...TextInputStyle,
                      marginHorizontal: 0,
                      borderBottomWidth: 1,
                      marginTop: 5,
                      marginBottom: 25,
                    }}
                    onChange={(e) => {
                      this.setState({
                        CategoryName: { id: e.target.value },
                        subCategoryName: [],
                      });
                      this.SubCategoryList(e.target.value + "");
                    }}
                    placeholder={this.props.t('Select category')}
                    >
                    {/* <option>{this.props.t('Select category')}</option> */}
                    {categoryList.length ? (
                      categoryList.map((option) => (
                        <option
                          key={"category" + option.id}
                          value={option.id}
                          selected={
                            CategoryName &&
                            option.id !== undefined &&
                            CategoryName.id + "" === option.id + ""
                              ? true
                              : false
                          }
                        >
                          {this.state.currLang === 'ar' ? option.name_ar === null ? option.name : option.name_ar : option.name}
                        </option>
                      ))
                    ) : (
                      <option>{this.props.t('Select category')}</option>
                    )}
                  </select>
                  {/* <Picker
                  mainViewStyle={{ marginHorizontal: 0, borderBottomWidth: 1, marginBottom: 25, }}
                  data={categoryList}
                  value={CategoryName.name}
                  onSelect={CategoryName => this.setState({ CategoryName, subCategoryName: '' }, () => this.SubCategoryList(CategoryName.id + ''),)}
                  placeholder="Select category"
                /> */}
                </View>
                <View>
                  <Text
                    style={{
                      ...fontBold,
                      color: "#000",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t('Sub Category')}
                  </Text>
                  <select
                    style={{
                      ...TextInputStyle,
                      marginHorizontal: 0,
                      borderBottomWidth: 1,
                      marginTop: 5,
                      marginBottom: 25,
                    }}
                    onChange={(e) => {
                      this.setState({
                        subCategoryName: { id: e.target.value },
                      });
                    }}
                    placeholder={this.props.t("Select sub category")}
                  >
                    {subcategoryList.length ? (
                      <>
                        {/* <option value="">{this.props.t('Select sub category')}</option> */}
                        {subcategoryList.map((option) => (
                          <option
                            key={"sub_category" + option.id}
                            value={option.id}
                            selected={
                              subCategoryName &&
                              option.id !== undefined &&
                              subCategoryName.id + "" === option.id + ""
                                ? true
                                : false
                            }
                          >
                            {/* {this.state.currLang === 'ar' ? option.name_ar : option.name} */}
                            {this.state.currLang === 'ar' ? option.name_ar === null ? option.name : option.name_ar : option.name}
                          </option>
                        ))}
                      </>
                    ) : (
                      <option>{this.props.t('Select sub category')}</option>
                    )}
                  </select>
                  {/* <Picker mainViewStyle={{ marginHorizontal: 0, borderBottomWidth: 1, marginBottom: 25, }}
                  data={subcategoryList}
                  value={subCategoryName.name}
                  onSelect={subCategoryName => this.setState({ subCategoryName })}
                  placeholder="Select sub category"
                /> */}
                </View>
                <Text
                  style={{ 
                    ...fontBold, color: "#000", fontSize: 14 }}
                >
                  {this.props.t('Type of sell')}
                </Text>

                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <TouchableOpacity
                    onPress={() =>
                      this.setState({
                        typesell: "Auction",
                        salesPrice: "",
                        stock: "",
                      })
                    }
                    style={{ flexDirection: "row", alignItems: "center",  marginLeft:  this.state.currLang === 'ar' ? 50  : 0, }}
                  >
                    <View
                      style={{
                        height: 16,
                        width: 16,
                        borderRadius: 16,
                        borderWidth: 2,
                        borderColor:
                          typesell === "Auction" ? color.primeColor : "#000",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {typesell === "Auction" ? (
                        <View
                          style={{
                            height: 9,
                            width: 9,
                            borderRadius: 9,
                            backgroundColor: color.primeColor,
                          }}
                        />
                      ) : null}
                    </View>
                    <Text
                      style={{
                        ...fontReg,
                        textAlignVertical: "center",
                        marginLeft:  this.state.currLang === 'ar' ? 0  : 8,
                        marginRight:  this.state.currLang === 'ar' ? 8  : 0,
                        color:
                          typesell === "Auction" ? color.primeColor : "#000",
                      }}
                    >
                      {this.props.t('Auction')}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() =>
                      this.setState({
                        typesell: "Buy it Now",
                        auctionlenght: "",
                        otherdays: "",
                        minimumamount: "",
                        minimum: "",
                      })
                    }
                    style={{
                      marginLeft:  this.state.currLang === 'ar' ? 0  : 50,
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        height: 16,
                        width: 16,
                        borderRadius: 16,
                        borderWidth: 2,
                        borderColor:
                          typesell === "Buy it Now" ? color.primeColor : "#000",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {typesell === "Buy it Now" ? (
                        <View
                          style={{
                            height: 9,
                            width: 9,
                            borderRadius: 9,
                            backgroundColor: color.primeColor,
                          }}
                        />
                      ) : null}
                    </View>
                    <Text
                      style={{
                        ...fontReg,
                        textAlignVertical: "center",
                        marginLeft:  this.state.currLang === 'ar' ? 0  : 8,
                        marginRight:  this.state.currLang === 'ar' ? 8  : 0,
                        color:
                          typesell === "Buy it Now" ? color.primeColor : "#000",
                      }}
                    >
                      {this.props.t('Buy it now')}
                    </Text>
                  </TouchableOpacity>
                </View>
                {typesell === "Auction" ? (
                  <>
                    <Text
                      style={{
                        ...fontBold,
                        marginTop: 25,
                        color: "#000",
                        fontSize: 14,
                      }}
                    >
                      {this.props.t('Auction length')}
                    </Text>
                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                      <TouchableOpacity
                        onPress={() => this.setState({ auctionlenght: "5" })}
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <View
                          style={{
                            height: 16,
                            width: 16,
                            borderRadius: 16,
                            borderWidth: 2,
                            borderColor:
                              auctionlenght === "5" ? color.primeColor : "#000",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {auctionlenght === "5" ? (
                            <View
                              style={{
                                height: 9,
                                width: 9,
                                borderRadius: 9,
                                backgroundColor: color.primeColor,
                              }}
                            />
                          ) : null}
                        </View>
                        <Text
                          style={{
                            ...fontReg,
                            textAlignVertical: "center",
                            marginLeft: this.state.currLang === 'ar' ? 0 : 8,
                            marginRight: this.state.currLang === 'ar' ? 8 : 0,
                            color:
                              auctionlenght === "5" ? color.primeColor : "#000",
                          }}
                        >
                          5 {this.props.t('days')}
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => this.setState({ auctionlenght: "10" })}
                        style={{
                          marginLeft: this.state.currLang === 'ar' ? 0 : 30,
                          marginRight: this.state.currLang === 'ar' ? 30 : 0,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <View
                          style={{
                            height: 16,
                            width: 16,
                            borderRadius: 16,
                            borderWidth: 2,
                            borderColor:
                              auctionlenght === "10"
                                ? color.primeColor
                                : "#000",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {auctionlenght === "10" ? (
                            <View
                              style={{
                                height: 9,
                                width: 9,
                                borderRadius: 9,
                                backgroundColor: color.primeColor,
                              }}
                            />
                          ) : null}
                        </View>
                        <Text
                          style={{
                            ...fontReg,
                            textAlignVertical: "center",
                            marginLeft: this.state.currLang === 'ar' ? 0 : 8,
                            marginRight: this.state.currLang === 'ar' ? 8 : 0,
                            color:
                              auctionlenght === "10"
                                ? color.primeColor
                                : "#000",
                          }}
                        >
                          10 {this.props.t('days')}
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() =>
                          this.setState({
                            auctionlenght: "other",
                            isVisible: true,
                          })
                        }
                        style={{
                          marginLeft: this.state.currLang === 'ar' ? 0 : 30,
                          marginRight: this.state.currLang === 'ar' ? 30 : 0,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <View
                          style={{
                            height: 16,
                            width: 16,
                            borderRadius: 16,
                            borderWidth: 2,
                            borderColor:
                              auctionlenght === "other"
                                ? color.primeColor
                                : "#000",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {auctionlenght === "other" ? (
                            <View
                              style={{
                                height: 9,
                                width: 9,
                                borderRadius: 9,
                                backgroundColor: color.primeColor,
                              }}
                            />
                          ) : null}
                        </View>
                        <Text
                          style={{
                            ...fontReg,
                            textAlignVertical: "center",
                            marginLeft: this.state.currLang === 'ar' ? 0 : 8,
                            marginRight: this.state.currLang === 'ar' ? 8 : 0,
                            color:
                              auctionlenght === "other"
                                ? color.primeColor
                                : "#000",
                          }}
                        >
                          {this.props.t('other')} {days ? "(" + days + " )  days" : null}
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <Text
                      style={{
                        ...fontBold,
                        color: "#000",
                        fontSize: 13,
                        paddingVertical: 15,
                        marginTop: 10,
                      }}
                    >
                      {this.props.t('Enter Minimum Amount')}
                    </Text>
                    <TextInput
                      placeholder={this.props.t("Enter your amount")}
                      // style={{ borderWidth: 1, borderRadius: 5, borderColor: '#DEE1E6', paddingHorizontal: 15, color: '#000', fontSize: 13, fontFamily: font.reg, }}
                      style={{
                        ...TextInputStyle,
                        textAlign: this.state.currLang === 'ar' ? 'end' : '',
                        marginBottom: 15,
                        flex: "auto",
                      }}
                      keyboardType="number-pad"
                      value={minimumamount}
                      onChangeText={(minimumamount) =>
                        this.setState({ minimumamount })
                      }
                    />
                  </>
                ) : null}

                {typesell === "Buy it Now" ? (
                  <View style={{ marginTop: 25 }}>
                    <Text
                      style={{
                        ...fontBold,
                        color: "#000",
                        fontSize: 14,
                      }}
                    >
                      {this.props.t('Enter Sale price')}
                    </Text>
                    <TextInput
                      placeholder={this.props.t("Enter Sale price")}
                      style={{
                        ...TextInputStyle,
                        textAlign: this.state.currLang === 'ar' ? 'end' : '',
                        marginTop: 10,
                        marginBottom: 10,
                        flex: "auto",
                      }}
                      keyboardType="number-pad"
                      value={salesPrice}
                      onChangeText={(salesPrice) =>
                        this.setState({ salesPrice })
                      }
                    />
                    <Text
                      style={{
                        ...fontBold,
                        color: "#000",
                        fontSize: 14,
                      }}
                    >
                      {this.props.t('Enter Available Stock')}
                    </Text>
                    <TextInput
                      placeholder={this.props.t("Enter Available Stock")}
                      style={{
                        ...TextInputStyle,
                        textAlign: this.state.currLang === 'ar' ? 'end' : '',
                        marginTop: 10,
                        marginBottom: 10,
                        flex: "auto",
                      }}
                      keyboardType="number-pad"
                      value={stock}
                      onChangeText={(stock) => this.setState({ stock })}
                    />
                  </View>
                ) : null}

                {type === "Edit" ? (
                  <>
                    <Text
                      style={{
                        ...fontBold,
                        color: "#000",
                        fontSize: 14,
                        marginTop: 15,
                      }}
                    >
                      {this.props.t('Status')}
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        paddingTop: 15,
                        marginBottom: 20,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() =>
                          this.setState({ productstatus: "Active" })
                        }
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <View
                          style={{
                            height: 16,
                            width: 16,
                            borderRadius: 16,
                            borderWidth: 2,
                            borderColor:
                              productstatus === "Active"
                                ? color.primeColor
                                : "#000",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {productstatus === "Active" ? (
                            <View
                              style={{
                                height: 9,
                                width: 9,
                                borderRadius: 9,
                                backgroundColor: color.primeColor,
                              }}
                            />
                          ) : null}
                        </View>
                        <Text
                          style={{
                            ...fontReg,
                            textAlignVertical: "center",
                            marginLeft: 8,
                            color:
                              productstatus === "Active"
                                ? color.primeColor
                                : "#000",
                          }}
                        >
                          {this.props.t('Active')}
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() =>
                          this.setState({ productstatus: "In-Active" })
                        }
                        style={{
                          marginLeft: 50,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <View
                          style={{
                            height: 16,
                            width: 16,
                            borderRadius: 16,
                            borderWidth: 2,
                            borderColor:
                              productstatus === "In-Active"
                                ? color.primeColor
                                : "#000",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {productstatus === "In-Active" ? (
                            <View
                              style={{
                                height: 9,
                                width: 9,
                                borderRadius: 9,
                                backgroundColor: color.primeColor,
                              }}
                            />
                          ) : null}
                        </View>
                        <Text
                          style={{
                            ...fontReg,
                            textAlignVertical: "center",
                            marginLeft: 8,
                            color:
                              productstatus === "In-Active"
                                ? color.primeColor
                                : "#000",
                          }}
                        >
                          {this.props.t('In-Active')}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </>
                ) : null}

                {type === "Edit" ? (
                  <TouchableOpacity
                    onPress={() => this.EditProduct(data.id)}
                    style={{
                      backgroundColor: "#000",
                      paddingVertical: 13,
                      borderRadius: 25,
                      marginTop: 25,
                      marginBottom: 20,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {isLoading ? (
                      <ActivityIndicator color={"#fff"} />
                    ) : (
                      <Text
                        style={{
                          ...fontBold,
                          color: "#ffff",
                          fontSize: 16,
                        }}
                      >
                        {this.props.t('Update')}
                      </Text>
                    )}
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    onPress={() => this.AddProduct()}
                    style={{
                      backgroundColor: "#000",
                      paddingVertical: 13,
                      borderRadius: 25,
                      marginTop: 25,
                      marginBottom: 20,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {isLoading ? (
                      <ActivityIndicator color={"#fff"} />
                    ) : (
                      <Text
                        style={{
                          ...fontBold,
                          color: "#ffff",
                          fontSize: 16,
                        }}
                      >
                        {this.props.t('Submit')}
                      </Text>
                    )}
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </ScrollView>

          {/* Add OtherDays  */}
          <Modal
            animationType="slide"
            transparent
            visible={isVisible}
            onRequestClose={() => {
              this.setState({ isVisible: false, backgroundColor: "#FFFFFFF9" });
            }}
          >
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                marginHorizontal: "auto",
              }}
            >
              <View
                borderColor={color.primeColor}
                style={{
                  backgroundColor: "#fff",
                  marginHorizontal: 25,
                  borderRadius: 15,
                  borderWidth: 2,
                  padding: 15,
                  ...shadow,
                  width: deviceWidth + "px",
                }}
              >
                <TouchableOpacity
                  onPress={() => this.setState({ isVisible: false })}
                  style={{
                    height: 40,
                    width: 40,
                    position: "absolute",
                    right: 0,
                    top: 0,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={require("../../assets/img/cancle_icon.png")}
                    style={{
                      height: "30%",
                      width: "30%",
                      resizeMode: "contain",
                      tintColor: "#000",
                    }}
                  />
                </TouchableOpacity>
                <Text
                  style={{
                    ...fontBold,
                    color: "#000",
                    fontSize: 13,
                    paddingVertical: 15,
                  }}
                >
                  {this.props.t('Enter Days')}
                </Text>
                <TextInput
                  placeholder={this.props.t("Enter your days")}
                  // style={{ color: '#000', fontSize: 13, fontFamily: font.reg, borderBottomWidth: 1 }}
                  style={{
                    ...TextInputStyle,
                    marginTop: 5,
                    marginBottom: 5,
                    borderBottomWidth: 1,
                    flex: "auto",
                  }}
                  keyboardType="number-pad"
                  maxLength={2}
                  value={otherdays}
                  onChangeText={(otherdays) => this.setState({ otherdays })}
                />
                <TouchableOpacity
                  onPress={() => this.EnterDays()}
                  style={{
                    backgroundColor: "#000",
                    paddingVertical: 10,
                    marginTop: 25,
                    borderRadius: 25,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      ...fontBold,
                      color: "#ffff",
                      fontSize: 16,
                    }}
                  >
                    {this.props.t('Submit')}
                  </Text>
                </TouchableOpacity>
              </View>
              <SnackBar ref={(ref) => (this.ModalOtherDays = ref)} />
            </View>
          </Modal>

          {/* Add Minimum Amount  */}
          <Modal
            animationType="slide"
            transparent
            visible={isVisibleamount}
            onRequestClose={() => {
              this.setState({
                isVisibleamount: false,
                backgroundColor: "#FFFFFFF9",
              });
            }}
          >
            <View style={{ flex: 1, justifyContent: "center" }}>
              <View
                borderColor={color.primeColor}
                style={{
                  backgroundColor: "#fff",
                  marginHorizontal: 25,
                  borderRadius: 15,
                  padding: 20,
                  borderWidth: 2,
                }}
              >
                <TouchableOpacity
                  onPress={() => this.setState({ isVisibleamount: false })}
                  style={{
                    height: 40,
                    width: 40,
                    position: "absolute",
                    right: 0,
                    top: 0,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={require("../../assets/img/cancle_icon.png")}
                    style={{
                      height: "30%",
                      width: "30%",
                      resizeMode: "contain",
                      tintColor: "#000",
                    }}
                  />
                </TouchableOpacity>
                <Text
                  style={{
                    ...fontReg,
                    color: "#000",
                    fontSize: 13,
                    paddingVertical: 15,
                  }}
                >
                  {this.props.t('Enter Minimum Amount')}
                </Text>
                <input
                  placeholder={this.props.t("Enter your amount")}
                  type="number"
                  style={{
                    ...fontReg,
                    color: "#000",
                    fontSize: 13,
                    borderBottomWidth: 1,
                  }}
                  pattern="[0-9]*"
                  value={minimumamount}
                  onChange={(e) => {
                    console.log("minimumamount 1547", e.target);
                  }}
                  /*onChangeText={(e) =>
                    { 
                      // if(e.target.validity.valid){}
                      this.setState({ minimumamount:  e.replace(/[^0-9]/g, '') })
                      console.log('minimumamount 1550', e.target);
                    }
                  }*/
                />
                <TouchableOpacity
                  onPress={() => this.EnterminAmount()}
                  style={{
                    backgroundColor: "#000",
                    paddingVertical: 10,
                    marginTop: 50,
                    borderRadius: 25,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: font.bold,
                      color: "#ffff",
                      fontSize: 16,
                    }}
                  >
                    {this.props.t('Submit')}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
            <SnackBar ref={(ref) => (this.ModalSnackbar = ref)} />
          </Modal>
        </KeyboardAvoidingView>
        {/* <ImagePicker
          ref={ref => (this.ImagePicker = ref)}
          onSelect={img => this.onSelectIMG(img)}
        /> */}
        <SnackBar ref="Snackbar" />
        <AutoComplete
          ref={(ref) => (this.AutoComplete = ref)}
          onSelect={(item, location) => {
            console.log("AddProduct 1611", { ...item, ...location });
            this.setState({ location: { ...item, ...location } });
          }}
        />
      </View>
    );
  }
}

export default withTranslation()(withRouter(AddProduct));
