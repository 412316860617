import React from "react";

export default function NImageLoader(
  { imageSrc, imageAlt, imageStyle },
  props
) {
  return (
    <>
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#e2e2e2",
          textAlign: "center",
        }}
      >
        {imageSrc ? (
          <>
            <img src={imageSrc} alt={imageAlt} style={{...imageStyle}} />
          </>
        ) : (
          <>
          No Image
          </>
        )}
      </div>
    </>
  );
  // <div
  //   style={{
  //     position: "absolute",
  //     height: "100%",
  //     width: "100%",
  //     justifyContent: "center",
  //     alignItems: "center",
  //     backgroundColor: "#e2e2e2",
  //   }}
  // >
  //   {imageSrc ? (
  //     <>
  //       <img src={imageSrc} alt={imageAlt} style={imageStyle} />
  //     </>
  //   ) : (
  //     <></>
  //   )}
  // </div>;
}
