import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  TextInput,
  KeyboardAvoidingView,
  StatusBar,
  SafeAreaView,
  ActivityIndicator,
} from "react-native";
import { color, font, fontBold, fontReg, shadow } from "../Component/Styles";
import { API } from "../../Privet";
import moment from "moment";
import { io } from "socket.io-client";
import { getUserData } from "../../utility/Utils";
import { withRouter } from "../../withRouter";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { withTranslation } from 'react-i18next';

const userData = getUserData();

class MessageDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      text: "",

      sender_id: "",
      sender_profile: "",
      sender_name: "",

      messageList: [],
      totalPage: 1,
      page: 1,
      scrollPosition: "",
      scrollevent: "",
      currLang: window.localStorage.getItem("lang") ?? "en",
    };
    // this.socket = io('http://192.168.1.53:3001');

    // Main
    this.socket = io("https://app.ekushk.com:3001");
  }

  async componentDidMount() {
    // const sellerid = await this.props.navigation.getParam('sellerid');
    // Global.main.setState({ sellerid: sellerid + '' });
    // const sender_profile = await this.props.navigation.getParam('sender_profile');
    // const sender_name = await this.props.navigation.getParam('sender_name');
    const { sellerid, sender_profile, sender_name } = await this.props.location.state;
    this.setState({
      sender_id: sellerid + "",
      sender_profile: sender_profile,
      sender_name: sender_name,
    });
    await this.messageListApi();    
    const read_receipts = {
      sender_id: userData.userid,
      reciver_id: this.state.sender_id + "",
    };
    this.socket.emit("read_receipts", read_receipts);

    this.socket.on(
      "sendto_" + this.state.sender_id + "_" + userData.userid,
      (data) => {
        this.setState({ messageList: [data, ...this.state.messageList] });

        const read_receipts = {
          sender_id: userData.userid,
          reciver_id: this.state.sender_id + "",
        };
        this.socket.emit("read_receipts", read_receipts);
      }
    );

    this.socket.on(
      "read_receipt_to_" + this.state.sender_id + "_" + userData.userid + "",
      (data) => {
        for (let i = 0; i < this.state.messageList.length; i++) {
          if (
            this.state.messageList[i].sender_id === userData.userid + "" &&
            this.state.messageList[i].message_status !== "read"
          ) {
            this.state.messageList[i].message_status = "read";
          }
        }
        this.setState({ catch: 0 });
      }
    );
  }

  componentWillUnmount() {
    this.socket.disconnect();
  }

  messageListApi() {
    const { token } = userData;
    const { sender_id, messageList, page, isLoading, totalPage } = this.state;
    if (totalPage >= page) {
      fetch(
        API.chat_message_list +
          "user_id=" +
          sender_id +
          "&page=" +
          page +
          "&perPage=20",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {
            if (isLoading || messageList.length <= 0) {
              this.setState({
                isLoading: false,
                messageList: res.data.collection,
                totalPage: res.data.lastPage,
                page: page + 1,
                sender_profile: res.data.user_detail.image_url,
                sender_name:
                  res.data.user_detail.name + " " + res.data.user_detail.lname,
              });
            } else {
              this.setState({
                isLoading: false,
                messageList: [...messageList, ...res.data.collection],
                totalPage: res.data.lastPage,
                page: page + 1,
              });
            }
          } else {
            this.setState({
              isLoading: false,
              page: page + 1,
              messageList: [],
            });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false, page: page + 1 });
          console.log(error);
        });
    }
  }

  renderFooter = () => {
    const { totalPage, page, isLoading } = this.state;
    if (totalPage >= page && !isLoading) {
      return (
        <View
          style={{
            height: 40,
            width: 40,
            borderRadius: 40,
            ...shadow,
            alignSelf: "center",
            marginTop: 20,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator color={color.primeColor} style={{}} />
        </View>
      );
    } else {
      return null;
    }
  };

  send() {
    const { text, sender_id, messageList } = this.state;
    const { userid } = userData;
    if (text.trim()) {
      const send_data = {
        booking_id: "0",
        sender_id: userid + "",
        reciver_id: sender_id + "",
        message: text.trim(),
        attechment_name: "",
        attechment_extension: "",
        attechment_mime_type: "",
        message_type: "text",
        attechment_url: "",
      };
      // console.log("chat_message--->", send_data);
      this.socket.emit("chat_message", send_data);
      this.setState({
        text: "",
        messageList: [
          {
            ...send_data,
            created_at: new Date(),
            message_status: "unread",
            istrue: true,
          },
          ...messageList,
        ],
      });
      setTimeout(() => {
        // this.flatList.scrollToEnd({ animated: true })
        this.flatList.scrollToOffset({ y: 0, animated: true });
      }, 100);
    }
  }

  handleScroll = (event) => {
    let yOffset = event.nativeEvent.contentOffset.y;
    this.setState({ scrollPosition: yOffset, scrollevent: yOffset });
  };

  render() {
    const {
      isLoading,
      sender_id,
      messageList,
      text,
      scrollPosition,
      sender_profile,
      sender_name,
    } = this.state;

    const { userid } = userData;
    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <KeyboardAvoidingView style={{ flex: 1, backgroundColor: "#ffff" }}>
          <SafeAreaView style={{ backgroundColor: "#fff" }} />
          <StatusBar backgroundColor={"#ffff"} barStyle="dark-content" />
          <View
            style={{
              flexDirection: "row",
              height: 50,
              paddingVertical: 13,
              borderBottomWidth: 1,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#fff",
            }}
          >
            <TouchableOpacity
              onPress={() => this.props.navigate(-1)}
              style={{
                height: "90%",
                width: 35,
                paddingLeft: this.state.currLang === 'ar' ? 0 : 15,
                alignItems: "center",
                justifyContent: "center",
                transform: this.state.currLang === 'ar' ? "rotate(180deg)" : ""
              }}
            >
              <Image
                source={require("../../assets/img/back.png")}
                style={{ height: "85%", width: "55%" }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() =>
                this.props.navigate("/seller-profile", {
                  state: {
                    sellerid: sender_id,
                  },
                })
              }
              style={{
                height: 33,
                width: 33,
                borderRadius: 35,
                overflow: "hidden",
                marginLeft: 10,
                borderWidth: 1,
              }}
            >
              {sender_profile ? (
                <Image
                  source={{ uri: sender_profile }}
                  style={{ height: "100%", width: "100%" }}
                />
              ) : (
                <Image
                  source={require("../../assets/img/user.png")}
                  style={{
                    height: "100%",
                    width: "100%",
                    resizeMode: "contain",
                    tintColor: color.lableColor,
                  }}
                />
              )}
            </TouchableOpacity>
            <Text
              onPress={() =>
                this.props.navigate("/seller-profile", {
                  state: {
                    sellerid: sender_id,
                  },
                })
              }
              numberOfLines={1}
              style={{
                ...fontBold,
                flex: 1,
                color: "#000",
                fontSize: 15,
                marginLeft:  this.state.currLang === 'ar' ? 0 : 15,
                marginRight:  this.state.currLang === 'ar' ? 15 : 0,
                textAlign: this.state.currLang === 'ar' ? 'end' : '',
              }}
            >
              {sender_name}
            </Text>
          </View>
          <View style={{ flex: 1 }}>
            <FlatList
              contentContainerStyle={{ flexGrow: 1, paddingBottom: 15 }}
              inverted
              data={messageList}
              ref={(ref) => (this.flatList = ref)}
              // onScroll={(event) => this.handleScroll(event)}
              onScroll={(event) => {
                // console.log('scroll event',event.nativeEvent.contentOffset,event);
                this.setState({
                  scrollPosition: event.nativeEvent.contentOffset.y,
                  scrollevent: event,
                });
              }}
              renderItem={({ item, index }) => (
                <View style={{ paddingHorizontal: 15, marginBottom: 0 }}>
                  {userid !== item.sender_id + "" ? (
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        maxWidth: "70%",
                        marginBottom: 20,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => this.props.navigate(-1)}
                        style={{
                          height: 33,
                          width: 33,
                          borderRadius: 35,
                          overflow: "hidden",
                          borderWidth: 1,
                        }}
                      >
                        {sender_profile ? (
                          <Image
                            source={{ uri: sender_profile }}
                            style={{ height: "100%", width: "100%" }}
                          />
                        ) : (
                          <Image
                            source={require("../../assets/img/user.png")}
                            style={{
                              height: "100%",
                              width: "100%",
                              resizeMode: "contain",
                              tintColor: color.lableColor,
                            }}
                          />
                        )}
                      </TouchableOpacity>
                      <View style={{}}>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: 5,
                            paddingLeft:  this.state.currLang === 'ar' ? 0 : 15,
                            paddingRight:  this.state.currLang === 'ar' ? 15 : 0,
                          }}
                        >
                          <Text
                            numberOfLines={1}
                            style={{
                              ...fontReg,
                              color: "#000",
                              fontSize: 13.5,
                            }}
                          >
                            {sender_name}
                          </Text>
                          <Text
                            style={{
                              ...fontReg,
                              color: color.lableColor,
                              fontSize: 11,
                              marginLeft:  this.state.currLang === 'ar' ? 0 : 10,
                              marginRight:  this.state.currLang === 'ar' ? 10 : 0, 
                            }}
                          >
                            {moment(item.created_at).format("h:mm A")}
                          </Text>
                        </View>
                        <View
                          style={{
                            borderTopRightRadius: this.state.currLang === 'ar' ? 0 : 8,
                            borderTopLeftRadius: this.state.currLang === 'ar' ? 8 : 0,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            backgroundColor: "#F2F3F4",
                            padding: 10,
                            marginLeft: this.state.currLang === 'ar' ? 0 : 10,
                            marginRight: this.state.currLang === 'ar' ? 10 : 0,
                          }}
                        >
                          <Text
                            style={{
                              ...fontReg,
                              color: "#000",
                              fontSize: 15,
                              textAlignVertical: "center",
                              textAlign: this.state.currLang === 'ar' ? 'end' : '',
                            }}
                          >
                            {item.message}
                          </Text>
                        </View>
                      </View>
                    </View>
                  ) : (
                    <View
                      style={{
                        flex: 1,
                        maxWidth: "70%",
                        alignSelf: "flex-end",
                      }}
                    >
                      <Text
                        style={{
                          ...fontReg,
                          color: color.lableColor,
                          fontSize: 11,
                          marginBottom: 5,
                        }}
                      >
                        {moment(item.created_at).format("h:mm A")}
                      </Text>
                      <View
                        style={{
                          padding: 10,
                          borderTopRightRadius: 8,
                          borderTopLeftRadius: 8,
                          borderBottomLeftRadius: this.state.currLang === 'ar' ? 0 : 8,
                          borderBottomRightRadius: this.state.currLang === 'ar' ? 8 : 0,
                          backgroundColor: "#000",
                        }}
                      >
                        <Text
                          style={{
                            ...fontReg,
                            color: "#fff",
                            fontSize: 15,
                            letterSpacing: 0.5,
                            textAlign: this.state.currLang === 'ar' ? 'end' : '',
                          }}
                        >
                          {item.message}
                        </Text>
                      </View>
                      <View
                        style={{
                          height: 15,
                          width: 15,
                          alignSelf: "flex-end",
                          marginBottom: 5,
                        }}
                      >
                        <Image
                          source={require("../../assets/img/seen.png")}
                          style={{
                            height: "100%",
                            width: "100%",
                            resizeMode: "contain",
                            transform:  this.state.currLang === 'ar' ? 'scaleX(-1)' : '',
                            tintColor:
                              item.message_status === "unread"
                                ? color.lableColor
                                : color.primeColor,
                          }}
                        />
                      </View>
                    </View>
                  )}
                </View>
              )}
              ListEmptyComponent={
                <View style={{ flex: 1 }}>
                  {!isLoading ? (
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        source={require("../../assets/img/messageemplty.png")}
                        style={{
                          height: 60,
                          width: 60,
                          tintColor: color.primeColor,
                        }}
                      />
                      <Text
                        style={{
                          ...fontBold,
                          textAlign: "center",
                          marginTop: 10,
                          color: "#000",
                        }}
                      >
                        {this.props.t('Start Messages...')}
                      </Text>
                    </View>
                  ) : (
                    [1, 2, 3, 4, 5, 6, 7].map(
                      (item_skeleton, index_skeleton) => (
                        <View
                          style={{ paddingHorizontal: 15, marginBottom: 0 }}
                        >
                          <View
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              maxWidth: "70%",
                              marginBottom: 20,
                              alignSelf:
                                index_skeleton % 3 === 0 ? "flex-end" : "",
                            }}
                          >
                            {index_skeleton % 3 ? (
                              <Skeleton circle width={40} height={40} />
                            ) : null}
                            <View style={{}}>
                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  marginBottom: 5,
                                  paddingLeft: 15,
                                }}
                              >
                                <Skeleton width={70} />
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  marginBottom: 5,
                                  paddingLeft: 15,
                                }}
                              >
                                <Skeleton width={100} height={30} />
                              </View>
                            </View>
                          </View>
                        </View>
                      )
                    )
                  )}
                </View>
              }
              keyExtractor={(item, index) => index + ""}
              onEndReachedThreshold={0.1}
              onEndReached={() => this.messageListApi()}
              ListFooterComponent={this.renderFooter}
              // refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={() => this.messageListApi()} />}
            />

            {scrollPosition >= "1500" && (
              <TouchableOpacity
                onPress={() =>
                  this.flatList.scrollToOffset({ y: 0, animated: true })
                }
                style={{
                  height: 25,
                  width: 25,
                  backgroundColor: "#fff",
                  alignSelf: "flex-end",
                  borderRadius: 15,
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  bottom: 15,
                  right: 15,
                  ...shadow,
                }}
              >
                <Image
                  source={require("../../assets/img/back.png")}
                  style={{
                    height: "55%",
                    width: "55%",
                    resizeMode: "contain",
                    transform: [{ rotate: "270deg" }],
                  }}
                />
              </TouchableOpacity>
            )}
          </View>
          <View style={{ flexDirection: "row", height: 50 }}>
            <TextInput
              style={{
                ...fontReg,
                backgroundColor: "#F2F3F4",
                paddingHorizontal: 15,
                color: "#000",
                fontSize: 15,
                flex: 1,
                paddingTop: 15,
                textAlign: this.state.currLang === 'ar' ? 'end' : '',
              }}
              placeholder={this.props.t("Type message here...")}
              value={text}
              multiline
              onChangeText={(text) => this.setState({ text })}
            />
            <TouchableOpacity
              disabled={text ? false : true}
              onPress={() => this.send()}
              style={{
                backgroundColor: color.primeColor,
                paddingHorizontal: 30,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  ...fontBold,
                  color: "#000",
                  fontSize: 16,
                }}
              >
                {this.props.t('Send')}
              </Text>
            </TouchableOpacity>
          </View>
        </KeyboardAvoidingView>
        <SafeAreaView />
      </View>
    );
  }
}

export default withTranslation()(withRouter(MessageDetails));
