import moment from "moment";
import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  RefreshControl,
} from "react-native";
import Header from "../Component/Header";
import { color, font, fontBold } from "../Component/Styles";
import { API } from "../../Privet";
import { io } from "socket.io-client";
import { getUserData } from "../../utility/Utils";
import FootTab from "./FootTab";
import { withRouter } from "../../withRouter";
import { withTranslation } from 'react-i18next';

const userData = getUserData();

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isRefreshing: false,
      connectUserLiast: [],
      currLang: window.localStorage.getItem("lang") ?? "en",
    };

    this.socket = io("https://app.ekushk.com:3001");
  }

  async componentDidMount() {
    await this.messageusersAPI();

    this.socket.onAny((key, data) => {
      if (key.endsWith("_" + userData.userid)) {
        this.messageusersAPI();
      }
    });
  }

  componentWillUnmount() {
    this.socket.close();
  }

  willFocus() {
    // Global.main.setState({ activeRoute: 'Message' });
    // Global.main.isTabBar(true);
    this.messageusersAPI();
  }

  async messageusersAPI() {
    const { token } = userData;
    this.setState({ isLoading: true });

    await fetch(API.chat_connect_users, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.success) {
          this.setState({
            isLoading: false,
            isRefreshing: false,
            connectUserLiast: res.data.collection,
          });
          let count = 0;
          res.data.collection.map((item) => {
            return (count = item.count_unread_message + count);
          });
          let oldLocalStorage = userData;
          oldLocalStorage["messageCount"] = count + "";
          localStorage.setItem(
            "kushkUserData",
            JSON.stringify(oldLocalStorage)
          );
        } else {
          this.setState({ isLoading: false, isRefreshing: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, isRefreshing: false });
        console.log(error);
      });
  }

  render() {
    const {
      isLoading,
      connectUserLiast,
      isRefreshing,
      showDrawer = false,
    } = this.state;

    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <Header
          title={this.props.t("Message")}
          Limg={require("../../assets/img/homemenu.png")}
          showDrawer={showDrawer}
          closeDrawer={() => this.setState({ showDrawer: false })}
          Lpress={() => this.setState({ showDrawer: true })}
          _onWillFocus={() => this.willFocus()}
        />
        <FlatList
          contentContainerStyle={{
            flexGrow: 1,
            paddingTop: 10,
            paddingHorizontal: 10,
          }}
          data={connectUserLiast}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              onPress={() =>
                this.props.navigate("/message-details", {
                  state: {
                    sellerid: item.id,
                    sender_profile: item.image_url,
                    sender_name: item.name + " " + item.lname,
                  },
                })
              }
              style={{
                flex: 1,
                flexDirection: "row",
                marginBottom: 5,
                paddingHorizontal: 15,
                backgroundColor: "#F2F3F4",
                paddingVertical: 10,
                borderRadius: 6,
              }}
            >
              {item.image_url ? (
                <Image
                  source={{ uri: item.image_url }}
                  style={{ height: 45, width: 45, borderRadius: 50 }}
                />
              ) : (
                <Image
                  source={require("../../assets/img/user.png")}
                  style={{
                    height: 45,
                    width: 45,
                    borderRadius: 50,
                    tintColor: color.lableColor,
                  }}
                />
              )}
              <View
                style={{ 
                  marginLeft:  this.state.currLang === 'ar' ? 0 : 10,
                  marginRight:  this.state.currLang === 'ar' ? 10 : 0, 
                  justifyContent: "center", 
                  flex: 1, 
                  textAlign: this.state.currLang === 'ar' ? 'end' : '' 
                }}
              >
                <Text
                  numberOfLines={1}
                  style={{
                    ...fontBold,
                    color: "#000",
                    marginBottom: 5,
                    fontSize: 13,
                  }}
                >
                  {item.name} {item.lname}
                </Text>
                <Text
                  numberOfLines={1}
                  style={{ color: color.lableColor, fontSize: 11 }}
                >
                  {item.get_last_message.message}
                </Text>
              </View>
              <View style={{ marginLeft: 10, justifyContent: "center" }}>
                <Text
                  numberOfLines={1}
                  style={{
                    ...fontBold,
                    color: color.primeColor,
                    marginBottom: 5,
                    fontSize: 10,
                  }}
                >
                  {moment(item.get_last_message.created_at).format("h:mm A")}
                </Text>
                {item.count_unread_message > 0 ? (
                  <View
                    style={{
                      height: 18,
                      width: 18,
                      backgroundColor: color.primeColor,
                      borderRadius: 18,
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "flex-end",
                    }}
                  >
                    {item.count_unread_message.toString().length > 2 ? (
                      <View
                        style={{
                          height: 10,
                          width: 10,
                          position: "absolute",
                          top: -2,
                          right: -2,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          source={require("../../assets/img/plus-symbol-button.png")}
                          style={{
                            height: "55%",
                            width: "55%",
                            resizeMode: "contain",
                            tintColor: "red",
                          }}
                        />
                      </View>
                    ) : null}
                    <Text
                      numberOfLines={1}
                      style={{ color: "#000", fontSize: 9 }}
                    >
                      {item.count_unread_message.toString().length > 2
                        ? "99"
                        : item.count_unread_message}
                    </Text>
                  </View>
                ) : (
                  <View style={{ height: 18, width: 18 }} />
                )}
              </View>
            </TouchableOpacity>
          )}
          ListEmptyComponent={
            <View style={{ flex: 1 }}>
              {!isLoading ? (
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={require("../../assets/img/nodata.png")}
                    style={{
                      height: 60,
                      width: 60,
                      tintColor: color.primeColor,
                    }}
                  />
                  <Text
                    style={{
                      ...fontBold,
                      textAlign: "center",
                      marginTop: 10,
                      color: color.lableColor,
                    }}
                  >
                    {this.props.t('Data not found')}!
                  </Text>
                </View>
              ) : null}
            </View>
          }
          keyExtractor={(item, index) => index + ""}
          onEndReachedThreshold={0.1}
          // onEndReached={() => this.messageListApi()}
          // ListFooterComponent={this.renderFooter}
          refreshControl={
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={() => this.messageusersAPI()}
            />
          }
        />
        <FootTab />
      </View>
    );
  }
}

export default withTranslation()(withRouter(Message));
