import React, { Component } from "react";
import {
  View,
  Text,
  ScrollView,
  Linking,
  Image,
  ActivityIndicator,
} from "react-native";
import Header from "../Component/Header";
// import HTMLView from 'react-native-htmlview';
import { color, font } from "../Component/Styles";
import { API } from "../../Privet";
import { withTranslation } from "react-i18next";
import { getUserData } from "../../utility/Utils";
import { withRouter } from "../../withRouter";
import FootTab from "./FootTab";
import parse from "html-react-parser";

const userData = getUserData();

class TermsConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: "",
      arabicInfo: "",
      englishInfo: "",
      isLoading: false,
    };
  }

  async componentDidMount() {
    await this.getInfo();
  }

  async getInfo() {
    const { token } = userData;
    this.setState({ isLoading: true });

    fetch(API.public_pages + "page=terms_conditions", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          this.setState({ isLoading: false, info: res?.data?.value });
          var arabic = /[\u0600-\u06FF]/;
          if (arabic.test(res?.data?.value)) {      
            var splitEnglishArabic = res?.data?.value.split("Conditions")
            this.setState({
              arabicInfo: splitEnglishArabic[1].toString()
              
            });
            this.setState({
              englishInfo: splitEnglishArabic[0].toString()
            })           
          }
          console.log("enslish", this.state.englishInfo);
          console.log("arabic", this.state.arabicInfo);
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }

  render() {
    const { info, arabicInfo, englishInfo, isLoading, showDrawer = false } = this.state;
    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <Header
          showDrawer={showDrawer}
          closeDrawer={() => this.setState({ showDrawer: false })}
          title={this.props.t("Terms & Conditions")}
          Limg={require("../../assets/img/homemenu.png")}
          Lpress={() => this.setState({ showDrawer: true })}
        />
        {info != null ? (
          <View style={{ flex: 1 }}>
            {isLoading ? (
              <ActivityIndicator
                color={color.primeColor}
                style={{ height: 60 }}
              />
            ) : (
              <ScrollView
                bounces={false}
                contentContainerStyle={{ flexGrow: 1 }}
              >
                <View
                  style={{ flex: 1, paddingHorizontal: 20, paddingVertical: 30 }}
                >
                  <View
                    stylesheet={{
                      a: {
                        fontFamily: font.reg,
                        color: "#000", // make links coloured pink
                      },
                    }}
                  >
                    {
                      englishInfo != "" && arabicInfo != ""? (
                        <>
                          <Text>{englishInfo + "Conditions"}</Text>
                          <Text>{arabicInfo}</Text> 
                        </>
                      ) :
                      (
                        <Text>{parse(info)}</Text>
                      )
                    }
                  </View>
                </View>
              </ScrollView>
            )}
          </View>
        ) : (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <Image
              source={require("../../assets/img/nodata.png")}
              style={{ height: 60, width: 60, tintColor: color.primeColor }}
            />
            <Text
              style={{
                fontFamily: font.bold,
                textAlign: "center",
                marginTop: 10,
                color: color.lableColor,
              }}
            >
              {this.props.t("Data not found")}!
            </Text>
          </View>
        )}
        <FootTab />
      </View>
    );
  }
}

export default withTranslation()(withRouter(TermsConditions));
