import React, { Component } from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import { color, font, fontBold, shadow } from "../Component/Styles";

import { withRouter } from "../../withRouter";
import { clearUserLocalStorage, getUserData } from "../../utility/Utils";

import { io } from "socket.io-client";

import { API } from "../../Privet";

import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
class FootTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConnected: true,
      user_id: "",
      username: "",
      activeRoute: "Home",
      isTabBar: true,
      devicetoken: "",
      sellerid: null,
      messageCount: 0,
      currLang: window.localStorage.getItem("lang") ?? "en",
    };
    this.socket = io("https://app.ekushk.com:3001");
  }

  async componentDidMount() {
    let userData = await getUserData();
    // console.log("Header Did Mount 35", userData);
    if (userData === null) {
      this.props.navigate("/login");
    }
    await this.setState({
      activeRoute: userData.currnetPath,
      messageCount: userData.messageCount ? parseInt(userData.messageCount) : 0,
      user_id: userData.user_id,
    });
    // console.log("FootTab.js Did Mount 30", userData, this.state);
    this.messageusersAPI();

    try {
      this.socket.onAny((key, data) => {
        if (key.endsWith("_" + userData.userid)) {
          this.messageusersAPI();
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      // prevState.currLang !== this.state.currLang &&
      this.state.currLang != i18n.language
    ) {
      if (i18n.language === "ar") {
        console.log("header i18n", i18n.language);
        this.setState({
          currLang: i18n.language,
        });
      } else {
        this.setState({
          currLang: i18n.language,
        });
      }
    }
  }

  async messageusersAPI() {
    const { token } = getUserData();

    try {
      await fetch(API.chat_connect_users, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.status === 401) {
            clearUserLocalStorage();
            this.props.navigate("/login");
          }
          return response.json();
        })
        .then(async (res) => {
          if (res.success) {
            let count = 0;
            res.data.collection.map((item) => {
              return (count = item.count_unread_message + count);
            });
            let oldLocalStorage = getUserData();
            oldLocalStorage["messageCount"] = count + "";
            localStorage.setItem(
              "kushkUserData",
              JSON.stringify(oldLocalStorage)
            );
            this.setState({ messageCount: count });
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { activeRoute, messageCount } = this.state;
    // console.log("Footer 35", this.state, getUserData(), messageCount);

    // var getLength = length.length;

    return (
      <div>
        <View
          style={{
            height: 70,
            ...shadow,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            backgroundColor: "#fff",
          }}
        >
          <View
            style={{
              ...shadow,
              elevation: 10,
              flexDirection: "row",
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              overflow: "hidden",
              flex: 1,
              backgroundColor: "#fff",
              paddingTop: 15,
            }}
          >
            {/* Home  */}
            <TouchableOpacity
              // onPress={() => {return <Navigate to="/login" replace />}}
              onPress={() => {
                this.setState({ activeRoute: "/" });
                return this.props.navigate("/");
              }}
              style={{
                flex: 1,
                borderTopColor: color.primeColor,
                marginHorizontal: 10,
              }}
            >
              {activeRoute === "/" ? (
                <Image
                  source={require("../../assets/img/HomefilledPrime.png")}
                  style={{ height: 25, width: 25, alignSelf: "center" }}
                />
              ) : (
                <Image
                  source={require("../../assets/img/homeroute.png")}
                  style={{ height: 25, width: 25, alignSelf: "center" }}
                />
              )}
              <Text
                style={{
                  ...fontBold,
                  flex: 1,
                  fontSize: 12,
                  marginTop: 3,
                  color: activeRoute === "/" ? color.primeColor : "#000",
                  alignSelf: "center",
                }}
              >
                {this.props.t("Home")}
              </Text>
            </TouchableOpacity>

            {/* Shop  */}
            <TouchableOpacity
              onPress={() => {
                this.setState({ activeRoute: "shop" });
                return this.props.navigate("/shop");
              }}
              style={{
                flex: 1,
                borderTopColor: color.primeColor,
                marginRight: this.state.currLang === "ar" ? 0 : 25,
                marginLeft: this.state.currLang === "ar" ? 25 : 0,
              }}
            >
              {activeRoute === "/shop" || activeRoute === "/shop-detail" ? (
                <Image
                  source={require("../../assets/img/shopFilled.png")}
                  style={{ height: 25, width: 25, alignSelf: "center" }}
                />
              ) : (
                <Image
                  source={require("../../assets/img/shopfilledblack.png")}
                  style={{ height: 25, width: 25, alignSelf: "center" }}
                />
              )}
              <Text
                style={{
                  ...fontBold,
                  flex: 1,
                  fontSize: 12,
                  marginTop: 3,
                  color:
                    activeRoute === "/shop" || activeRoute === "/shop-detail"
                      ? color.primeColor
                      : "#000",
                  alignSelf: "center",
                }}
              >
                {this.props.t("Shop")}
              </Text>
            </TouchableOpacity>

            {/* New Product Add */}
            <TouchableOpacity
              onPress={() => this.props.navigate("/product-add")}
              // onPress={() => this.props.history.push("/product-add")}
              style={{
                height: 32,
                width: 32,
                borderRadius: 32,
                backgroundColor: "#000",
                marginTop: 3,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Image
                source={require("../../assets/img/plus.png")}
                style={{ height: "45%", width: "45%", tintColor: "#ffff" }}
              />
            </TouchableOpacity>

            {/* Message */}
            <TouchableOpacity
              onPress={() => {
                this.setState({ activeRoute: "message" });
                return this.props.navigate("/message");
              }}
              style={{
                flex: 1,
                borderTopColor: color.primeColor,
                marginRight: this.state.currLang === "ar" ? 25 : 0,
                marginLeft: this.state.currLang === "ar" ? 0 : 25,
              }}
            >
              <View
                style={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <View style={{ height: 25, width: 30 }}>
                  <Image
                    source={require("../../assets/img/Messageblack.png")}
                    style={{
                      height: 25,
                      width: 25,
                      alignSelf: "center",
                      transform:
                        this.state.currLang === "ar" ? "scaleX(-1)" : "",
                      tintColor:
                        activeRoute === "/message" ? color.primeColor : "#000",
                    }}
                  />

                  {messageCount !== 0 ? (
                    <>
                      {/* <View style={{ height: 15, width: 15, backgroundColor: color.primeColor, justifyContent: 'center', alignItems: 'center', borderRadius: 20, overflow: 'hidden', position: 'absolute', right: 15, top: -5, flexDirection: 'row' }}>
                      
                        <Text style={{ fontFamily: font.bold, color: '#000', fontSize: 8 }}>{getLength > 2 ? '99' : Global.main.state.count}</Text>
                        {getLength > 2 ? <Image source={require('./assets/img/plus-symbol-button.png')} style={{ height: 3, width: 3, resizeMode: 'contain', tintColor: 'red' }} /> : null}
                      </View> */}

                      {messageCount !== null ? (
                        <View
                          style={{
                            height: 15,
                            width: 15,
                            backgroundColor: color.primeColor,
                            borderRadius: 18,
                            justifyContent: "center",
                            alignItems: "center",
                            alignSelf: "flex-end",
                            position: "absolute",
                            right: 0,
                            top: -5,
                          }}
                        >
                          {messageCount > 99 ? (
                            <View
                              style={{
                                height: 10,
                                width: 10,
                                position: "absolute",
                                top: -3,
                                right: -3,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Image
                                source={require("../../assets/img/plus-symbol-button.png")}
                                style={{
                                  height: "55%",
                                  width: "55%",
                                  resizeMode: "contain",
                                  tintColor: "red",
                                }}
                              />
                            </View>
                          ) : null}
                          <Text
                            numberOfLines={1}
                            style={{ color: "#000", fontSize: 9 }}
                          >
                            {messageCount > 99 ? "99" : messageCount}
                          </Text>
                        </View>
                      ) : null}
                    </>
                  ) : null}
                </View>
              </View>
              <Text
                style={{
                  ...fontBold,
                  flex: 1,
                  fontSize: 12,
                  marginTop: 3,
                  color: activeRoute === "/message" ? color.primeColor : "#000",
                  alignSelf: "center",
                }}
              >
                {this.props.t("Messages")}
              </Text>
            </TouchableOpacity>

            {/* Profile  */}
            <TouchableOpacity
              onPress={() => {
                this.setState({ activeRoute: "/profile" });
                return this.props.navigate("/profile");
              }}
              style={{
                flex: 1,
                borderTopColor: color.primeColor,
                marginHorizontal: 10,
              }}
            >
              {activeRoute === "/profile" ? (
                <Image
                  source={require("../../assets/img/profileprime.png")}
                  style={{ height: 25, width: 25, alignSelf: "center" }}
                />
              ) : (
                <Image
                  source={require("../../assets/img/profileblack.png")}
                  style={{ height: 25, width: 25, alignSelf: "center" }}
                />
              )}
              <Text
                style={{
                  ...fontBold,
                  flex: 1,
                  fontSize: 12,
                  marginTop: 3,
                  color: activeRoute === "/profile" ? color.primeColor : "#000",
                  alignSelf: "center",
                }}
              >
                {this.props.t("Profile")}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </div>
    );
  }
}

export default withTranslation()(withRouter(FootTab));
