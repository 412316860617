import React, { Component } from "react";

import {
  View,
  Text,
  Image,
  TouchableOpacity,
  RefreshControl,
  FlatList,
  Dimensions,
} from "react-native";
import Header from "../Component/Header";
import { color, font } from "../Component/Styles";
import { API } from "../../Privet";

import { io } from "socket.io-client";
import { getUserData } from "../../utility/Utils";
import { withRouter } from "../../withRouter";
import FootTab from "./FootTab";
import { withTranslation } from 'react-i18next';

let width = "";
const userData = getUserData();

class Shopdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      CategoryName: "Auto & Cars",
      Categoryid: '',
      SubCategiryList: [],
      isSearch: false,
      text: "",
      currLang: window.localStorage.getItem("lang") ?? "en",
    };
    this.arrayHold = [];
    this.socket = io("https://app.ekushk.com:3001");
  }

  async componentDidMount() {
    const { deviceWidth } = JSON.parse(localStorage.getItem("kushkDevice"));
    width = deviceWidth;

    const { Categoryid, CategoryName } = await this.props.location.state;
    this.setState({ Categoryid: Categoryid, CategoryName: CategoryName });
    // Global.main.setState({ activeRoute: 'Shop' });
    // Global.main.isTabBar(true);
    await this.Subcategorylist();

    await this.messageusersAPI();

    this.socket.onAny((key, data) => {
      if (key.endsWith("_" + userData.userid)) {
        this.messageusersAPI();
      }
    });
  }

  willFocus() {
    // Global.main.setState({ activeRoute: 'Shop' });
    // Global.main.isTabBar(true);
  }

  async messageusersAPI() {
    const { token } = userData;

    await fetch(API.chat_connect_users, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.success) {
          let count = 0;
          res.data.collection.map((item) => {
            return (count = item.count_unread_message + count);
          });
          // const items = [
          //     ['count', count + '']
          // ];
          // await Global.main.setState({
          //     count: count + ''
          // }, () => AsyncStorage.multiSet(items, async () => {
          // }));
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async Subcategorylist() {
    const { token } = userData;
    const { Categoryid } = this.state;
    this.setState({ isLoading: true });

    let formdata = new FormData();
    formdata.append("id", Categoryid);

    await fetch(API.subcategorylist, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          this.setState({ isLoading: false, SubCategiryList: res.data });
          this.arrayHold = res.data;
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }

  search(text) {
    this.setState({ text });
    const data = this.arrayHold.filter((item) => {
      const itemData = `${item.name}`.toUpperCase();
      return itemData.indexOf(text.toUpperCase()) > -1;
    });
    this.setState({ SubCategiryList: data });
  }

  render() {
    const { SubCategiryList, isLoading, isSearch, text, CategoryName } =
      this.state;
    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <Header
          title={CategoryName}
          Limg={require("../../assets/img/back.png")}
          Lpress={() => this.props.navigate(-1)}
          Rimg={require("../../assets/img/search.png")}
          Rpress={() => this.setState({ isSearch: true })}
          _onWillFocus={() => this.willFocus()}
          searchBar={isSearch}
          closeSearchBar={() =>
            this.setState({
              isSearch: false,
              SubCategiryList: this.arrayHold,
              text: "",
            })
          }
          texts={text}
          searchFilterFunction={(text) => this.search(text)}
        />

        <FlatList
          numColumns={2}
          contentContainerStyle={{
            marginTop: 15,
            paddingBottom: 40,
            flexGrow: 1,
          }}
          data={SubCategiryList}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              onPress={() =>
                this.props.navigate("/", {
                  state: { subId: item.id, type: "Subcategory" },
                })
              }
              style={{
                height: 160,
                // width: (width - 50) / 2,
                width: '50%',
                marginLeft: 15,
                marginBottom: 15,
                backgroundColor: "#FAFAFA",
                borderRadius: 8,
                overflow: "hidden",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {item.image ? (
                <Image
                  source={{ uri: item.image }}
                  style={{ height: 78, width: 78, resizeMode: "contain" }}
                />
              ) : (
                <Image
                  source={require("../../assets/img/noimg.jpeg")}
                  style={{ height: 78, width: 78, resizeMode: "contain" }}
                />
              )}
              <Text
                style={{
                  marginTop: 10,
                  color: "#231F20",
                  fontFamily: font.bold,
                  fontSize: 16,
                }}
              >
                  { this.state.currLang === 'ar' ? item.name_ar : item.name}
              </Text>
              {item.totalProducts ? (
                <Text
                  style={{
                    color: "#9E9EA7",
                    fontFamily: font.bold,
                    fontSize: 10,
                  }}
                >
                  {item.totalProducts+ ' '} 
                  { item.totalProducts === 1 || item.totalProducts === 0 ? 
                    this.props.t('Post') : this.props.t('Posts') 
                  }
                </Text>
              ) : null}
            </TouchableOpacity>
          )}
          ListEmptyComponent={
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!isLoading ? (
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={require("../../assets/img/nodata.png")}
                    style={{
                      height: 60,
                      width: 60,
                      tintColor: color.primeColor,
                    }}
                  />
                  <Text
                    style={{
                      fontFamily: font.bold,
                      textAlign: "center",
                      marginTop: 10,
                      color: color.lableColor,
                    }}
                  >
                    Data not found!
                  </Text>
                </View>
              ) : null}
            </View>
          }
          keyExtractor={(item, index) => index + ""}
          refreshControl={
            <RefreshControl
              refreshing={isLoading}
              onRefresh={() => this.Subcategorylist()}
            />
          }
        />
        <FootTab />
      </View>
    );
  }
}
export default withTranslation()(withRouter(Shopdetails));
