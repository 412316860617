import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  FlatList,
  RefreshControl,
  TouchableOpacity,
} from "react-native";
import Header from "../Component/Header";
import { color, font, fontBold } from "../Component/Styles";
import { API } from "../../Privet";
// import ToggleSwitch from "toggle-switch-react-native";
import Switch from "react-js-switch";
import { getUserData } from "../../utility/Utils";
import { withRouter } from "../../withRouter";
import FootTab from "./FootTab";
import { withTranslation, withNamespaces } from "react-i18next";
import i18n from "../../i18n";

const userData = getUserData();

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isRefreshing: false,
      settingList: [],
      language: window.localStorage.getItem("lang") ?? "en",
      direction: window.localStorage.getItem("direction") ?? "ltr",
    };
  }

  async componentDidMount() {
    this.settingListApi();
  }

  willFocus() {
    this.settingListApi();
  }

  async settingListApi() {
    const { token } = userData;
    this.setState({ isLoading: true, isRefreshing: true });

    await fetch(API.app_setting_list, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            isLoading: false,
            isRefreshing: false,
            settingList: res.data,
          });
        } else {
          this.setState({ isLoading: false, isRefreshing: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, isRefreshing: false });
        console.log(error);
      });
  }

  async manageSetting(select_setting, is_selected) {
    const { token } = userData;
    this.setState({ isLoading: true });

    let formdata = new FormData();

    formdata.append("select_setting", select_setting);
    if (is_selected) {
      formdata.append("on_action", "remove");
    }
    await fetch(API.user_select_app_setting, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          this.setState({ isLoading: false });
          this.settingListApi();
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }

  changeLanguage(lng) {
    i18n.changeLanguage(lng);
    const element = document.getElementsByTagName("html")[0];
    this.state.language = lng;
    if (lng === "ar") {
      window.localStorage.setItem("lang", lng);
      window.localStorage.setItem("direction", "rtl");
      element.setAttribute("dir", "rtl");
    } else {
      window.localStorage.setItem("direction", "ltr");
      window.localStorage.setItem("lang", lng);
      element.setAttribute("dir", "ltr");
    }
  }
  render() {
    const {
      isLoading,
      isRefreshing,
      settingList,
      showDrawer = false,
    } = this.state;

    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <Header
          showDrawer={showDrawer}
          closeDrawer={() => this.setState({ showDrawer: false })}
          title={this.props.t("Settings")}
          Limg={require("../../assets/img/homemenu.png")}
          Lpress={() => this.setState({ showDrawer: true })}
        />

        <FlatList
          contentContainerStyle={{
            paddingBottom: 25,
            flexGrow: 1,
            padding: 20,
          }}
          data={settingList}
          renderItem={({ item, index }) => (
            <View style={{ flexDirection: "row", marginVertical: 15 }}>
              <Text
                style={{
                  ...fontBold,
                  flex: 1,
                  marginBottom: 10,
                  color: "#231F20",
                  fontSize: 15,
                  textAlign: this.state.language === "ar" ? "end" : "",
                }}
              >
                {item.title}
              </Text>
              {/* <Switch
                isOn={item.is_selected}
                style={{backgroundColor: color.primeColor}}
                onColor="#BBBCC0"
                offColor="#231F20"
                size="small"
                value={item.is_selected}
                // thumbOnStyle={{ backgroundColor: 'red' }}
                thumbOffStyle={{ backgroundColor: color.primeColor }}
                onToggle={() => this.manageSetting(item.id, item.is_selected)}
              /> */}
              <View style={{
                transform: this.state.language === "ar" ? 'scaleX(-1)' : ''
              }}>

              <Switch
                value={item.is_selected}
                onChange={() => this.manageSetting(item.id, item.is_selected)}
                backgroundColor={{ on: "#BBBCC0", off: "#231F20" }}
                borderColor={{ on: "#BBBCC0", off: "#231F20" }}
                size={30}
              />
              </View>
            </View>
          )}
          ListEmptyComponent={
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!isLoading ? (
                <>
                  <Image
                    source={require("../../assets/img/nodata.png")}
                    style={{
                      height: 60,
                      width: 60,
                      tintColor: color.primeColor,
                    }}
                  />
                  <Text
                    style={{
                      ...fontBold,
                      textAlign: "center",
                      marginTop: 10,
                      color: color.lableColor,
                    }}
                  >
                    {this.props.t("Data not found")}!
                  </Text>
                </>
              ) : null}
            </View>
          }
          keyExtractor={(item, index) => index + ""}
          refreshControl={
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={() => this.settingListApi()}
            />
          }
        />
        {/* language section */}
        <View style={{ marginTop: 15, alignItems: "center", marginBottom: 20 }}>
          <Text style={{ fontFamily: font.bold, fontSize: 15, color: "#000" }}>
            {this.props.t("Language")}
          </Text>
          <View style={{ flexDirection: "row", marginTop: 10 }}>
            <TouchableOpacity
              disabled={this.state.language === "en"}
              onPress={() => this.changeLanguage("en")}
              style={{
                backgroundColor:
                  this.state.language === "en" ? color.primeColor : "#E9E9E9",
                margin: 10,
                paddingHorizontal: 30,
                paddingVertical: 10,
                borderRadius: 50,
              }}
            >
              <Text style={{ fontFamily: font.bold, fontSize: 15 }}>
                English
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              disabled={this.state.language === "ar"}
              onPress={() => this.changeLanguage("ar")}
              style={{
                backgroundColor:
                  this.state.language === "ar" ? color.primeColor : "#E9E9E9",
                margin: 10,
                paddingHorizontal: 30,
                paddingVertical: 10,
                borderRadius: 50,
              }}
            >
              <Text style={{ fontFamily: font.bold, fontSize: 15 }}>
                {this.props.t("Arabic")}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <FootTab />
      </View>
    );
  }
}

export default withTranslation()(withRouter(Settings));
