import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  Linking,
} from "react-native";
import Header from "../Component/Header";
import { font, fontBold, fontReg } from "../Component/Styles";
import { API } from "../../Privet";
import { clearUserLocalStorage, getUserData } from "../../utility/Utils";
import { withRouter } from "../../withRouter";

import { withTranslation } from 'react-i18next';

import FootTab from "./FootTab";
// import Global from '../Global';
// import SkeletonPlaceholder from 'react-native-skeleton-placeholder';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: "",
      currLang: window.localStorage.getItem("lang") ?? "en",
    };
  }

  async componentDidMount() {
    // SplashScreen.hide();
    // Global.main.setState({ activeRoute: 'Profile' });
    // Global.main.isTabBar(true);
    this.ProfileApi();
  }

  willFocus() {
    // Global.main.setState({ activeRoute: 'Profile' });
    // Global.main.isTabBar(true);
    this.ProfileApi();
  }

  // Profile API
  async ProfileApi() {
    const { token } = getUserData();
    this.setState({ isLoading: true });
    await fetch(API.userProfile, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          clearUserLocalStorage();
          this.props.navigate("/login");
          return;
        }
        return response.json();
      })
      .then((res) => {
        if (res.success) {
          this.setState({ data: res.data });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { data, isLoading, showDrawer = false } = this.state;
    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <Header
          Limg={require("../../assets/img/homemenu.png")}
          showDrawer={showDrawer}
          closeDrawer={() => this.setState({ showDrawer: false })}
          Lpress={() => this.setState({ showDrawer: true })}
          // Lpress={() => { console.log("edit click"); }}
          edit={true}
          EditPress={() => this.props.navigate("/edit-profile")}
          // EditPress={() => {console.log('edit click'); this.props.navigate("/edit-profile")}}
          _onWillFocus={() => this.willFocus()}
        />

        <ScrollView contentContainerStyle={{ flexGrow: 1, padding: 15 }}>
          {
            !isLoading ? (
              <View style={{ flex: 1, backgroundColor: "#fff" }}>
                <View
                  style={{
                    height: 120,
                    width: 120,
                    borderRadius: 120,
                    borderWidth: 1,
                    alignSelf: "center",
                    overflow: "hidden",
                    marginTop: 25,
                  }}
                >
                  {data.imageURL ? (
                    <Image
                      source={{ uri: data.imageURL }}
                      style={{
                        height: "100%",
                        width: "100%",
                        alignSelf: "center",
                      }}
                    />
                  ) : (
                    <Image
                      source={require("../../assets/img/user.png")}
                      style={{
                        height: "100%",
                        width: "100%",
                        alignSelf: "center",
                      }}
                    />
                  )}
                </View>
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 15,
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      ...fontBold,
                      color: "#231F20",
                      fontSize: 20,
                    }}
                  >
                    {data.name} {data.lname}
                  </Text>
                  {/* Details */}
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      marginTop: 30,
                      justifyContent: "center",
                      alignItems: "center",
                      marginHorizontal: 25,
                      flexGrow: 1,
                    }}
                  >
                    <View style={{ flex: 1, alignItems: "center" }}>
                      <Text
                        style={{
                          ...fontReg,
                          color: "#9E9EA7",
                          fontSize: 11,
                        }}
                      >
                        {this.props.t('Followers')}                    
                      </Text>
                      <Text
                        style={{
                          ...fontBold,
                          color: "#231F20",
                          fontSize: 15,
                          marginTop: 5,
                        }}
                      >
                        {data.favorites}
                      </Text>
                    </View>
                    <View style={{ flex: 1, alignItems: "center" }}>
                      <Text
                        style={{
                          ...fontReg,
                          color: "#9E9EA7",
                          fontSize: 11,
                        }}
                      >
                         {this.props.t('Rating')}   
                      </Text>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <Text
                          style={{
                            ...fontBold,
                            color: "#231F20",
                            fontSize: 15,
                            marginLeft: 5,
                          }}
                        >
                          {data.rating}
                        </Text>
                        <Image
                          source={require("../../assets/img/rating-yellow.png")}
                          style={{
                            height: 15,
                            width: 15,
                            resizeMode: "contain",
                            marginLeft: 3,
                          }}
                        />
                      </View>
                    </View>
                    <TouchableOpacity
                      onPress={() =>
                        this.props.navigate("/user-review", {
                          state: { sellerProfile_id: data.id },
                        })
                      }
                      style={{ flex: 1, alignItems: "center" }}
                    >
                      <Text
                        style={{
                          ...fontReg,
                          color: "#9E9EA7",
                          fontSize: 11,
                        }}
                      >
                        {this.props.t('Reviews')}   
                      </Text>
                      <Text
                        style={{
                          ...fontBold,
                          color: "#231F20",
                          fontSize: 15,
                          marginTop: 5,
                        }}
                      >
                        {data.review}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={{ marginHorizontal: 10, marginTop: 50 }}>
                  <Text
                    style={{
                      ...fontReg,
                      color: "#9E9EA7",
                      fontSize: 13,
                    }}
                  >
                    {this.props.t('Email')}   
                  </Text>
                  <Text
                    onPress={() => Linking.openURL("mailto:" + data.email)}
                    style={{
                      ...fontBold,
                      color: "#231F20",
                      fontSize: 13,
                      marginTop: 15,
                      textAlign: this.state.currLang === "ar" ? "end" : ""
                    }}
                  >
                    {data.email}
                  </Text>
                  <Text
                    style={{
                      ...fontReg,
                      color: "#9E9EA7",
                      fontSize: 13,
                      marginTop: 30,
                    }}
                  >
                    {this.props.t('Phone')}  
                  </Text>
                  <Text
                    onPress={() => Linking.openURL(`tel:${data.mobile}`)}
                    style={{
                      ...fontBold,
                      color: "#231F20",
                      fontSize: 13,
                      marginTop: 15,
                      textAlign: this.state.currLang === "ar" ? "end" : ""
                    }}
                  >
                    {"+961 " + data.mobile}
                  </Text>
                </View>
              </View>
            ) : (
              // <SkeletonPlaceholder>
              <View style={{ padding: 15 }}>
                <View
                  style={{
                    height: 100,
                    width: 100,
                    marginBottom: 20,
                    borderRadius: 250,
                    alignSelf: "center",
                  }}
                />
                <View
                  style={{
                    height: 20,
                    width: "50%",
                    borderRadius: 7,
                    marginBottom: 10,
                    alignSelf: "center",
                  }}
                />
                <View
                  style={{
                    height: 20,
                    width: "50%",
                    borderRadius: 7,
                    marginBottom: 10,
                    alignSelf: "center",
                  }}
                />
                <View
                  style={{
                    height: 50,
                    width: "100%",
                    borderRadius: 7,
                    marginBottom: 10,
                  }}
                />
                <View
                  style={{
                    height: 50,
                    width: "100%",
                    borderRadius: 7,
                    marginBottom: 10,
                  }}
                />
                <View
                  style={{
                    height: 150,
                    width: "100%",
                    borderRadius: 7,
                    marginBottom: 10,
                  }}
                />
              </View>
            )
            // </SkeletonPlaceholder>
          }
        </ScrollView>
        <FootTab />
      </View>
    );
  }
}

export default withTranslation()(withRouter(Profile));
