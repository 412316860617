import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import Header from "../Component/Header";
import { color, font, fontBold, fontReg } from "../Component/Styles";
import { API } from "../../Privet";

import SnackBar from "../Component/SnackBar";
import Coundown from "../Component/Coundown";
import NImageLoader from "../NComponent/NImageLoader";
import { getUserData } from "../../utility/Utils";
import Skeleton from "react-loading-skeleton";
import { withRouter } from "../../withRouter";

import { withTranslation } from 'react-i18next';

const userData = getUserData();

class SellerProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isRefreshing: false,
      productList: [],
      sellerid: "",
      type: "",
      idLoadingGet: false,
      data: "",
      isLoadingAddFavorite: false,
      isBid: "",
      productType: "",
      currLang: window.localStorage.getItem("lang") ?? "en",
    };
  }
  
  async componentDidMount() {
    // this.setState({ sellerid: this.props.location.state.sellerid, type: this.props.location.state.type });
    await this.setState({ sellerid: this.props.location.state.sellerid });
    await this.setState({
      type: this.props.location.state.type
        ? this.props.location.state.type
        : "",
    });
    await this.setState({
      isBid: this.props.location.state.isBid ? this.props.location.state.isBid : ""
    });
    await this.setState({productType: this.props.location.state.productType})
    await this.GetSellerProfile();
  }

  willFocus() {
    this.GetSellerProfile();
  }

  async GetSellerProfile() {
    const { token, userid } = userData;
    const { sellerid } = this.state;

    this.setState({ isLoading: true });
    let formdata = new FormData();

    formdata.append("appuser_id", userid);
    formdata.append("sellerProfile_id", sellerid);

    await fetch(API.sellerProfile, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          this.setState({ data: res.data, isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }

  addRemoveFavourite(sellerid) {
    const { token, userid } = userData;
    const { data } = this.state;
    this.setState({ isLoadingAddFavorite: true });

    let formdata = new FormData();

    formdata.append("appuser_id", userid);
    formdata.append("seller_user_id", sellerid);
    if (data.its_favorite != null) {
      formdata.append("status", "unfavorite");
      formdata.append("favorite_id", data.its_favorite.id);
    }

    fetch(API.userAddFavoriteSeller, {
      method: "POST",
      headers: {
        Accept: "application/json",
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          this.setState({ isLoadingAddFavorite: false });
          this.refs.RNSnackBar.show(res.message);
          this.GetSellerProfile();
        } else {
          this.setState({ isLoadingAddFavorite: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoadingAddFavorite: false });
        console.log(error);
      });
  }

  checkifDisabled() {
    if (this.state.productType === "Auction") {
      if(this.state.isBid) {
        return false;
      } else {
        return true;
      }
    } else if(this.state.productType != "Auction") {
      return false;
    }
  }

  render() {
    const { type, data, isLoadingAddFavorite, isLoading, isBid, productType } = this.state;
    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <Header
          title={this.props.t("Seller Profile")}
          Limg={require("../../assets/img/back.png")}
          Lpress={() =>
            type === "PaymentSuccess"
              ? this.props.navigate("/")
              : this.props.navigate(-1)
          }
        />

        {!isLoading ? (
          <ScrollView contentContainerStyle={{ flexGrow: 1, padding: 15 }}>
            <View style={{ flex: 1, backgroundColor: "#fff" }}>
              <View
                style={{
                  height: 120,
                  width: 120,
                  borderRadius: 120,
                  borderWidth: 1,
                  alignSelf: "center",
                  overflow: "hidden",
                  marginTop: 25,
                }}
              >
                {data.imageURL ? (
                  <Image
                    source={{ uri: data.imageURL }}
                    style={{
                      height: "100%",
                      width: "100%",
                      alignSelf: "center",
                    }}
                  />
                ) : (
                  <Image
                    source={require("../../assets/img/user.png")}
                    style={{
                      height: "100%",
                      width: "100%",
                      resizeMode: "contain",
                      justifyContent: "center",
                      alignSelf: "center",
                      tintColor: color.lableColor,
                    }}
                  />
                )}
              </View>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 15,
                }}
              >
                <Text
                  style={{
                    ...fontBold,
                    color: "#231F20",
                    fontSize: 20,
                  }}
                >
                  {data.name + " " + data.lname}
                </Text>

                {/* Details */}
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 30,
                    alignItems: "center",
                    marginHorizontal: 25,
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <Text
                      style={{
                        ...fontReg,
                        color: "#9E9EA7",
                        fontSize: 11,
                      }}
                    >
                       {this.props.t('Favourites')}
                    </Text>
                    <Text
                      numberOfLines={1}
                      style={{
                        ...fontBold,
                        color: "#231F20",
                        fontSize: 15,
                        marginTop: 3,
                      }}
                    >
                      {data.favorites ? data.favorites : "0"}
                    </Text>
                  </View>
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <Text
                      style={{
                        ...fontReg,
                        color: "#9E9EA7",
                        fontSize: 11,
                      }}
                    >
                      {this.props.t('Rating')}
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 3,
                      }}
                    >
                      <Text
                        numberOfLines={1}
                        style={{
                          ...fontBold,
                          color: "#231F20",
                          fontSize: 15,
                          marginLeft: 5,
                        }}
                      >
                        {data.rating ? data.rating.toFixed(1) : "0"}
                      </Text>
                      <Image
                        source={require("../../assets/img/rating-yellow.png")}
                        style={{
                          height: 15,
                          width: 15,
                          resizeMode: "contain",
                          marginLeft: 3,
                        }}
                      />
                    </View>
                  </View>
                  <TouchableOpacity
                    onPress={() =>
                      this.props.navigate("/user-review", {
                        state: { sellerProfile_id: data.id },
                      })
                    }
                    style={{ flex: 1, alignItems: "center" }}
                  >
                    <Text
                      style={{
                        ...fontReg,
                        color: "#9E9EA7",
                        fontSize: 11,
                      }}
                    >
                      {this.props.t('Reviews')}
                    </Text>
                    <Text
                      numberOfLines={1}
                      style={{
                        ...fontBold,
                        color: "#231F20",
                        fontSize: 15,
                        marginTop: 3,
                      }}
                    >
                      {data.review ? data.review : "0"}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={{ marginHorizontal: 10, marginTop: 35 }}>
                <View style={{ flexDirection: "row" }}>
                  <TouchableOpacity
                    disabled={this.checkifDisabled()}
                    onPress={() =>
                      this.props.navigate("/message-details",{
                        state: {
                          sellerid: data.id,
                          sender_profile: data.imageURL,
                          sender_name: data.name + " " + data.lname,
                        }
                      })
                    }
                    style={{
                      paddingVertical: 11,
                      borderRadius: 30,
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#231F20",
                      flex: 1,
                      marginLeft:  this.state.currLang === 'ar' ? 5 : 0,
                      marginRight:   this.state.currLang === 'ar' ? 0 : 5,
                    }}
                  >
                    <Text
                      style={{
                        ...fontBold,
                        color: "#FFFFFF",
                        fontSize: 14,
                      }}
                    >
                      {this.props.t('Contact Seller')}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => this.addRemoveFavourite(data.id)}
                    borderColor={"#231F20"}
                    style={{
                      paddingVertical: 11,
                      borderRadius: 30,
                      justifyContent: "center",
                      alignItems: "center",
                      borderWidth: 2,
                      flex: 1,
                      marginLeft:  this.state.currLang === 'ar' ? 0 : 5,
                      marginRight:   this.state.currLang === 'ar' ? 5 : 0,
                      backgroundColor:
                        data.its_favorite != null ? "#000" : null,
                    }}
                  >
                    {isLoadingAddFavorite ? (
                      <ActivityIndicator
                        color={data.its_favorite != null ? "#fff" : "#000"}
                      />
                    ) : (
                      <Text
                        style={{
                          ...fontBold,
                          color:
                            data.its_favorite != null ? "#ffff" : "#231F20",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t('Favourites')}
                      </Text>
                    )}
                  </TouchableOpacity>
                </View>
                {/* Product list */}
                {data.sellerProduct != null ? (
                  <>
                    <Text
                      style={{
                        color: "#231F20",
                        fontSize: 23,
                        marginTop: 30,
                        marginBottom: 20,
                      }}
                    >
                      {this.props.t('Seller products')}
                    </Text>
                    {data.sellerProduct.map((item, index) => (
                      <TouchableOpacity
                        style={{ flexDirection: "row", marginBottom: 20 }}
                        onPress={() =>
                          this.props.navigate("/product-detail", {
                            state: { id: item.id, type: "" },
                          })
                        }
                      >
                        <View
                          style={{
                            height: 88,
                            width: 120,
                            borderRadius: 10,
                            overflow: "hidden",
                          }}
                        >
                          <NImageLoader
                            imageSrc={
                              item.image
                                ? item.image
                                : require("../../assets/img/noimg.jpeg")
                            }
                            imageAlt={item.name}
                            imageStyle={{
                              verticalAlign: "middle",
                              height: "100%",
                              width: "100%",
                            }}
                          />
                        </View>
                        <View style={{ 
                            paddingLeft: this.state.currLang === 'ar' ? 0 : 18, 
                            paddingRight: this.state.currLang === 'ar' ? 18 : 0, 
                            flex: 1 
                          }}
                        >
                          <Text
                            style={{
                              ...fontBold,
                              color: "#000",
                              fontSize: 15,
                              marginTop: 3,
                              textAlign: this.state.currLang === 'ar' ? 'right' : '',
                            }}
                          >
                            {item.name}
                          </Text>
                          {item.location ? (
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                marginRight: this.state.currLang === 'ar' ? 0 : 15,
                                marginTop: 5,
                              }}
                            >
                              <Image
                                source={require("../../assets/img/location.png")}
                                style={{
                                  height: 16,
                                  width: 15,
                                  tintColor: color.lableColor,
                                  resizeMode: "contain",
                                }}
                              />
                              <Text
                                numberOfLines={2}
                                style={{
                                  ...fontReg,
                                  color: color.lableColor,
                                  marginLeft:  this.state.currLang === 'ar' ? 0  : 5,
                                  marginRight:  this.state.currLang === 'ar' ? 5  : 0,
                                  fontSize: 11,
                                  textAlignVertical: "center",
                                }}
                              >
                                {item.location}
                              </Text>
                            </View>
                          ) : null}
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              flex: 1,
                            }}
                          >
                            <View
                              style={{
                                justifyContent: "flex-end",
                                alignItems: "baseline",
                              }}
                            >
                              {item.typeofsell === "Buy it Now" ? (
                                <Text
                                  style={{
                                    ...fontBold,
                                    color: "#E2583A",
                                    fontSize: 14,
                                    marginBottom: -10,
                                  }}
                                >
                                  SAR{" "}{item.saleprice + ""}
                                </Text>
                              ) : (
                                <Text
                                  style={{
                                    ...fontBold,
                                    color: "#E2583A",
                                    fontSize: 14,
                                  }}
                                >
                                  {/* SAR{" "}{item.saleprice} */}
                                </Text>
                              )}
                              {item.aucationminimum === 1 ? (
                                <Text
                                  style={{
                                    ...fontBold,
                                    color: "#E2583A",
                                    fontSize: 14,
                                  }}
                                >
                                  SAR{" "}{item.aucationminimum_amount + ""}
                                </Text>
                              ) : (
                                <Text
                                  style={{
                                    ...fontBold,
                                    color: "#E2583A",
                                    fontSize: 14,
                                  }}
                                >
                                  {/* SAR{" "}{item.saleprice} */}
                                </Text>
                              )}
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: 15,
                              }}
                            >
                              {item.typeofsell === "Auction" ? (
                                <View
                                  style={{
                                    alignSelf: "flex-end",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    borderRadius: 26,
                                    paddingVertical: 8,
                                    paddingHorizontal: 10,
                                    backgroundColor: color.primeColor,
                                  }}
                                >
                                  <Image
                                    source={require("../../assets/img/clock.png")}
                                    style={{ height: 15, width: 15 }}
                                  />
                                  <Text
                                    style={{
                                      ...fontBold,
                                      color: "#000",
                                      marginLeft:  this.state.currLang === 'ar' ? 0  : 8,
                                      marginRight:   this.state.currLang === 'ar' ? 8 : 0,
                                      fontSize: 12,
                                      textAlignVertical: "center",
                                    }}
                                  >
                                    <Coundown
                                      finishTime={item.auction_endtime}
                                    />
                                  </Text>
                                </View>
                              ) : null}
                            </View>
                          </View>
                        </View>
                      </TouchableOpacity>
                    ))}
                  </>
                ) : null}
              </View>
            </View>
          </ScrollView>
        ) : (
          <View style={{ padding: 15 }}>
            <View
              style={{
                height: 100,
                width: 100,
                borderRadius: 150,
                marginBottom: 10,
                alignSelf: "center",
              }}
            >
              <Skeleton width={100} height={100} circle />
            </View>
            <View
              style={{
                height: 30,
                width: 180,
                borderRadius: 7,
                marginBottom: 10,
                alignSelf: "center",
              }}
            >
              <Skeleton />
            </View>
            <View
              style={{
                height: 30,
                width: 180,
                borderRadius: 7,
                marginBottom: 10,
                alignSelf: "center",
              }}
            >
              <Skeleton />
            </View>
            <View
              style={{
                flexDirection: "row",
                marginBottom: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton style={{ height: 60, width: 80, borderRadius: 7 }} />
              <View
                style={{
                  height: 60,
                  width: 80,
                  borderRadius: 7,
                  marginLeft: 10,
                }}
              >
                <Skeleton height={50} />
              </View>
              <View
                style={{
                  height: 60,
                  width: 80,
                  borderRadius: 7,
                  marginLeft: 10,
                }}
              >
                <Skeleton height={50} />
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                marginBottom: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton style={{ height: 50, width: 150, borderRadius: 30 }} />
              <Skeleton
                style={{
                  height: 50,
                  width: 150,
                  borderRadius: 30,
                  marginLeft: 10,
                }}
              />
            </View>
            <Skeleton
              style={{
                height: 100,
                width: "100%",
                borderRadius: 7,
                marginBottom: 10,
              }}
            />
            <Skeleton
              style={{
                height: 100,
                width: "100%",
                borderRadius: 7,
                marginBottom: 10,
              }}
            />
          </View>
        )}

        <SnackBar ref="RNSnackBar" />
      </View>
    );
  }
}
export default withTranslation()(withRouter(SellerProfile));
