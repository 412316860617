import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
  RefreshControl,
} from "react-native";

import Header from "../Component/Header";
import { color, font, fontBold, fontReg, shadow } from "../Component/Styles";
import { API } from "../../Privet";

import moment from "moment";
import Coundown from "../Component/Coundown";
import Skeleton from "react-loading-skeleton";
import { clearUserLocalStorage, getUserData } from "../../utility/Utils";
import NImageLoader from "../NComponent/NImageLoader";
import { withRouter } from "../../withRouter";
import FootTab from "./FootTab";
import { withTranslation } from 'react-i18next';


const userData = getUserData();

class Myposts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isRefreshing: false,
      totalPage: 1,
      page: 1,
      PostList: [],
      isSearch: false,
      text: "",
      currLang: window.localStorage.getItem("lang") ?? "en",
    };
    this.arrayHold = [];
  }

  async componentDidMount() {
    this.PostListApi();
  }

  willFocus() {
    this.PostListApi();

    this.setState(
      {
        isLoading: true,
        page: 1,
        totalPage: 1,
      },
      () => this.PostListApi()
    );
  }

  timecalculate(expirydate) {
    var date = moment().utcOffset("+03:00").format("YYYY-MM-DD HH:mm:ss");

    var diffr = moment.duration(moment(expirydate).diff(moment(date)));

    var hours = parseInt(diffr.asHours());
    var minutes = parseInt(diffr.minutes());
    var seconds = parseInt(diffr.seconds());
    var d = hours * 60 * 60 + minutes * 60 + seconds;

    return d;
  }

  async PostListApi() {
    const { token, userid } = userData;
    const { totalPage, page, isLoading, isRefreshing, PostList, isSearch } =
      this.state;

    if (totalPage >= page && !isSearch) {
      let formdata = new FormData();
      formdata.append("is_api", "myPosts");
      formdata.append("appuser_id", userid);
      formdata.append("page", page + "");
      formdata.append("perPage", "15");
      await fetch(API.myPosts, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        body: formdata,
      })
        .then((response) => {
        if (response.status === 401) {
          clearUserLocalStorage();
          this.props.navigate("/login");
        }
        return response.json();
      })
        .then((res) => {
          if (res.success) {
            if (isLoading || isRefreshing || PostList.length <= 0) {
              this.setState({
                isLoading: false,
                isRefreshing: false,
                PostList: res.data.collection,
                totalPage: res.data.lastPage,
                page: page + 1,
                isVisible: false,
              });
              this.arrayHold = res.data.collection;
            } else {
              this.setState({
                isLoading: false,
                isRefreshing: false,
                PostList: [...PostList, ...res.data.collection],
                totalPage: res.data.lastPage,
                page: page + 1,
                isVisible: false,
              });
              this.arrayHold = [...PostList, ...res.data.collection];
            }
          } else {
            this.setState({
              isLoading: false,
              isRefreshing: false,
              page: page + 1,
              PostList: [],
              isVisible: true,
              msg: res.message,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isRefreshing: false,
            page: page + 1,
          });
          console.log(error);
        })
        .finally(() => {
          this.setState({
            isLoading: false,
            isRefreshing: false,
            page: page + 1,
          });
        });
    }
  }

  renderFooter = () => {
    const { totalPage, page, isLoading, isRefreshing, isSearch } = this.state;
    if (totalPage >= page && (!isRefreshing || isLoading) && !isSearch) {
      return (
        <View
          style={{
            height: 40,
            width: 40,
            borderRadius: 40,
            ...shadow,
            alignSelf: "center",
            marginTop: 20,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator color={color.primeColor} style={{}} />
        </View>
      );
    } else {
      return null;
    }
  };

  search(text) {
    this.setState({ text });
    const data = this.arrayHold.filter((item) => {
      const itemData =
        `${item.name} ${item.sub_category_name} ${item.category_name} ${item.aucationminimum_amount} ${item.saleprice}`.toUpperCase();
      return itemData.indexOf(text.toUpperCase()) > -1;
    });
    this.setState({ PostList: data });
  }

  onRefresh() {
    this.setState(
      {
        totalPage: 1,
        page: 1,
        isRefreshing: true,
        text: "",
        isSearch: false,
      },
      () => this.PostListApi()
    );
  }

  render() {
    const { isLoading, isRefreshing, PostList, isSearch, text,
        showDrawer = false, } = this.state;

    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <Header
         showDrawer={showDrawer}
         closeDrawer={() => this.setState({ showDrawer: false })}
          title={this.props.t("My posts")}
          Limg={require("../../assets/img/homemenu.png")}
          Lpress={() => this.setState({ showDrawer: true })}
          Rimg={require("../../assets/img/search.png")}
          Rpress={() => this.setState({ isSearch: true })}
          _onWillFocus={() => this.willFocus()}
          searchBar={isSearch}
          closeSearchBar={() =>
            this.setState({
              isSearch: false,
              PostList: this.arrayHold,
              text: "",
            })
          }
          texts={text}
          searchFilterFunction={(text) => this.search(text)}
        />
        <FlatList
          contentContainerStyle={{
            paddingBottom: 25,
            flexGrow: 1,
            paddingHorizontal: 20,
            paddingTop: 15,
          }}
          data={PostList}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              onPress={() =>
                this.props.navigate("/product-detail", {
                  state: { id: item.id, type: "" },
                })
              }
              style={{ flexDirection: "row", paddingBottom: 20 }}
            >
              <View
                style={{
                  height: 88,
                  width: 120,
                  borderRadius: 10,
                  overflow: "hidden",
                  marginLeft:  this.state.currLang === 'ar' ? 10 : 0,
                }}
              >
                <NImageLoader
                  imageSrc={
                    item.image
                      ? item.image
                      : require("../../assets/img/noimg.jpeg")
                  }
                  imageAlt={item.name}
                  imageStyle={{
                    verticalAlign: "middle",
                    height: "100%",
                    width: "100%",
                  }}
                />
              </View>
              <View style={{ paddingLeft: 18, flex: 1 }}>
                <Text
                  style={{
                    ...fontBold,
                    color: "#000",
                    fontSize: 15,
                    marginTop: 3,
                    textAlign: this.state.currLang === 'ar' ? 'right' : '',
                  }}
                >
                  {item.name}
                </Text>

                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginRight: this.state.currLang === 'ar' ? 0 : 15,
                    marginTop: 5,
                  }}
                >
                  {item.sub_category_name ? (
                    <Text
                      style={{
                        ...fontReg,
                        color: color.lableColor,
                        fontSize: 12,
                      }}
                    >
                      { this.state.currLang === 'ar' ? item.sub_category_name_ar : item.sub_category_name} ,{" "}
                    </Text>
                  ) : null}
                  <Text
                    style={{
                      ...fontReg,
                      color: color.lableColor,
                      fontSize: 12,
                    }}
                  >
                    { this.state.currLang === 'ar' ? item.category_name_ar : item.category_name}
                  </Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flex: 1,
                  }}
                >
                  <View
                    style={{
                      justifyContent: "flex-end",
                      alignItems: "baseline",
                    }}
                  >
                    {item.typeofsell === "Buy it Now" ? (
                      <Text
                        style={{
                          ...fontBold,
                          color: "#E2583A",
                          fontSize: 14,
                          marginBottom: -10,
                        }}
                      >
                        SAR{" "}{item.saleprice + ""}
                      </Text>
                    ) : (
                      <Text
                        style={{
                          ...fontBold,
                          color: "#E2583A",
                          fontSize: 14,
                        }}
                      >
                        {/* SAR{" "}{item.saleprice} */}
                      </Text>
                    )}
                    {item.typeofsell === "Auction" ? (
                      <Text
                        style={{
                          ...fontBold,
                          color: "#E2583A",
                          fontSize: 14,
                        }}
                      >
                        SAR{" "}
                        {item.maximum_bid_price
                          ? item.maximum_bid_price
                          : item.aucationminimum_amount + ""}{" "}
                      </Text>
                    ) : (
                      <Text
                        style={{
                          ...fontBold,
                          color: "#E2583A",
                          fontSize: 14,
                        }}
                      >
                        {/* SAR{" "}{item.saleprice} */}
                      </Text>
                    )}
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 15,
                    }}
                  >
                    {item.typeofsell === "Auction" ? (
                      <View
                        style={{
                          alignSelf: "flex-end",
                          flexDirection: "row",
                          alignItems: "center",
                          borderRadius: 26,
                          paddingVertical: 8,
                          paddingHorizontal: 10,
                          backgroundColor: color.primeColor,
                        }}
                      >
                        <Image
                          source={require("../../assets/img/clock.png")}
                          style={{ height: 15, width: 15 }}
                        />
                        <Text
                          style={{
                            ...fontBold,
                            color: "#000",
                            marginLeft: 8,
                            fontSize: 12,
                            textAlignVertical: "center",
                          }}
                        >
                          <Coundown finishTime={item.auction_endtime} />
                        </Text>
                      </View>
                    ) : null}
                  </View>
                </View>
              </View>
            </TouchableOpacity>
          )}
          ListEmptyComponent={
            !isRefreshing && (
              <View style={{ flex: 1 }}>
                {!isLoading ? (
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      source={require("../../assets/img/nodata.png")}
                      style={{
                        height: 60,
                        width: 60,
                        tintColor: color.primeColor,
                      }}
                    />
                    <Text
                      style={{
                        ...fontBold,
                        textAlign: "center",
                        marginTop: 10,
                        color: color.lableColor,
                      }}
                    >
                      {this.props.t('Data not found')}!
                    </Text>
                  </View>
                ) : (
                    <>
                    {[1, 2, 3, 4, 5].map(() => {
                      return (
                        <View
                          style={{
                            flexDirection: "row",
                            // backgroundColor: "#a1a1a1"
                          }}
                        >
                          <View
                            style={{
                              height: 100,
                              width: 100,
                              overflow: "hidden",
                            }}
                          >
                            <Skeleton
                              height={100}
                              width={100}
                              containerClassName="avatar-skeleton"
                              style={{ marginBottom: 10}}
                            />
                          </View>
                          <View
                            style={{
                              paddingLeft: 18,
                              flex: 1,
                              width: "100%",
                              // backgroundColor: "#b25b25",
                            }}
                          >
                            <Skeleton width="100%" style={{ marginBottom: 10}}/>
                            <Skeleton width="40%" style={{ marginBottom: 10}}/>
                            <Skeleton width="60%" style={{ marginBottom: 10}}/> 
                            <Skeleton width="80%" style={{ marginBottom: 10}}/>
                          </View>
                        </View>
                      );
                    })}
                  </>
                )}
              </View>
            )
          }
          keyExtractor={(item, index) => index + ""}
          onEndReachedThreshold={0.1}
          onEndReached={() => this.PostListApi()}
          ListFooterComponent={this.renderFooter}
          refreshControl={
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={() => this.onRefresh()}
            />
          }
        />
        <FootTab />
      </View>
    );
  }
}

export default withTranslation()(withRouter(Myposts));
