import React, { Component } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ActivityIndicator,
  Image,
  StatusBar,
  ScrollView,
} from "react-native";
// import AsyncStorage from '@react-native-async-storage/async-storage';
import { font, color, TextInputStyle, fontWeight } from "../Component/Styles";
import SnackBar from "../Component/SnackBar";
import { API } from "../../Privet";
import { withRouter } from "../../withRouter";
import {
  checkOnlyNumber,
  getUserData,
  getUserDeviceToken,
} from "../../utility/Utils";

import { withTranslation } from 'react-i18next';

// const RN = Platform.OS === 'ios' ? require('react-native') : require('react-native-safe-area-context');
// const { SafeAreaView } = RN;

class Otp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingResend: false,
      isLoading: false,
      otp: "",
      active: false,
      second: 0,
      OtpDisplay: "",
      mobile: "",
      currLang: window.localStorage.getItem("lang") ?? "en",
    };
  }

  async componentDidMount() {
    const { OtpSend, mobile } = this.props.location.state;
    // console.log('otp 40',this.props.location, OtpSend, mobile);
    // const OtpSend = 1234;
    this.setState({ OtpDisplay: OtpSend, mobile: mobile });
    this.timer();
  }

  ResendOtp() {
    const { mobile } = this.props.location.state;

    this.setState({ isLoadingResend: true });

    let formdata = new FormData();
    formdata.append("mobile", mobile);

    fetch(API.resend, {
      method: "POST",
      body: formdata,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
    })
      .then((response) => response.json())
      .then(async (response) => {
        if (response.data) {
          this.setState({ OtpDisplay: response.data, isLoadingResend: false });
          this.timer();
        } else {
          this.setState({ isLoadingResend: false });
        }
      })
      .catch((e) => {
        console.log("error: ", e);
        this.setState({ isLoadingResend: false });
      });
  }

  async LoginWithMobileOtp() {
    const { mobile } = this.props.location.state;
    const { otp, OtpDisplay } = this.state;
    const devicetoken = await getUserDeviceToken();
    // console.log('OTP',devicetoken,getUserDeviceToken());
    Keyboard.dismiss();
    if (!otp) {
      this.refs.RNSnackBar.show(this.props.t("Please enter OTP."));
    } else if (otp !== OtpDisplay + "") {
      console.log(otp, OtpDisplay + "");
      this.refs.RNSnackBar.show(this.props.t("Please check your OTP."));
    } else {
      const data = new FormData();
      data.append("mobile", mobile);
      data.append("otp", this.state.otp);
      data.append("devicetoken", devicetoken ? devicetoken : "");
      this.setState({ isLoading: true });
      fetch(API.login, {
        method: "POST",
        body: data,
        // headers: {
        //   "Content-Type": "multipart/form-data",
        // },
      })
        .then((response) => response.json())
        .then(async (response) => {
          if (response.success) {
            let items = {};
            items = {
              userid: response.data.userid + "",
              mobile: response.data.mobile + "",
              token: response.data.token + "",
              firstname: response.data.firstname + "",
              lastname: response.data.lastname + "",
              email: response.data.email + "",
              image: response.data.image + "",
              imageURL: response.data.imageURL + "",
            };

            localStorage.setItem("kushkUserData", JSON.stringify(items));
            const userData = getUserData();
            if (
              typeof userData.email === "undefined" ||
              userData.email === "" ||
              userData.email === "null"
            ) {
              this.props.navigate("/add-profile");
            } else {
              this.props.navigate("/");
            }
            window.location.reload();
          } else {
            this.refs.RNSnackBar.show(response.message);
          }
        })
        .catch((e) => {
          console.log("check", e);
        })
        .finally(() => this.setState({ isLoading: false }));
    }
  }

  async messageusersAPI(token) {
    fetch(API.chat_connect_users, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.success) {
          let count = "";
          res.data.collection.map((item) => {
            return (count = item.count_unread_message + count);
          });
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  timer() {
    this.setState({ second: 30 });
    this.interval = setInterval(() => {
      const { second } = this.state;
      if (second) {
        this.setState({ second: second - 1 });
      } else {
        this.interval = this.interval ? clearInterval(this.interval) : null;
      }
    }, 1000);
  }

  stopInterval() {
    this.setState({ second: 0 });
    this.interval = this.interval ? clearInterval(this.interval) : null;
  }

  render() {
    const mobile = this.state ? this.state?.mobile : null;
    const { isLoading, isLoadingResend, otp, active, second, OtpDisplay } =
      this.state;
    return (
      <KeyboardAvoidingView
        style={{ flex: 1, backgroundColor: "#ffff" }}
        behavior={Platform.OS === "ios" ? "padding" : null}
      >
        {/* <SafeAreaView backgroundColor={color.primeColor} /> */}
        <StatusBar
          barStyle="dark-content"
          backgroundColor={color.primeColor}
          translucent={true}
        />

        <View style={{ flex: 1 }}>
          {/* Logo  */}
          <View
            style={{
              height: "30%",
              backgroundColor: color.primeColor,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              source={require("../../assets/img/logo.png")}
              style={{ height: 80, width: 180, alignSelf: "center" }}
            />
            <TouchableOpacity
              onPress={() => this.props.navigate(-1)}
              style={{
                position: "absolute",
                left: this.state.currLang === 'ar' ? 'unset' : 0,
                right:  this.state.currLang === 'ar' ? 0 : 'unset',
                transform: this.state.currLang === 'ar' ? 'scaleX(-1)' : '',
                top: 0,
                height: 60,
                width: 60,
              }}
            >
              <Image
                source={require("../../assets/img/back.png")}
                style={{ height: 17, width: 17, margin: 20 }}
              />
            </TouchableOpacity>
          </View>
          {/* Sign In  */}

          <ScrollView bounces={false} contentContainerStyle={{ flexGrow: 1 }}>
            <View style={{ flex: 1, backgroundColor: "#fff" }}>
              <View style={{ paddingHorizontal: 20, paddingTop: 20 }}>
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontFamily: font.bold,
                        fontWeight: fontWeight.bold,
                        color: "#000",
                        fontSize: 30,
                      }}
                    >
                      {this.props.t('Confirmation')}
                    </Text>
                  </View>
                </View>
                <Text
                  numberOfLines={2}
                  style={{
                    fontFamily: font.reg,
                    color: "#000",
                    marginTop: 10,
                    fontSize: 15,
                  }}
                >
                  {this.props.t('Enter 6 digit code send to your')} {mobile}
                </Text>

                <View
                  borderColor={otp ? "#000" : color.borderColor}
                  style={{
                    borderColor: active ? "#000" : color.borderColor,
                    borderWidth: 1,
                    borderRadius: 6,
                    marginTop: 40,
                    alignItems: "center",
                    flexDirection: "row",
                    paddingHorizontal: 15,
                    paddingVertical: 5,
                  }}
                >
                  <TextInput
                    style={{
                      ...TextInputStyle,
                      borderWidth: 0,
                      paddingLeft: 5,
                      flex: 1,
                      color: "#000",
                      fontFamily: font.reg,
                      fontSize: 14,
                      textAlign: this.state.currLang === 'ar' ? 'end' : ''
                    }}
                    placeholder={this.props.t("Enter code")}
                    keyboardType="number-pad"
                    maxLength={6}
                    value={otp}
                    onChangeText={(otp) => {
                      let checkItsNumber = checkOnlyNumber(otp);
                      if (otp !== checkItsNumber) {
                        this.refs.RNSnackBar.show(checkItsNumber + "");
                      } else {
                        this.setState({ otp, active: true });
                      }
                    }}
                  />
                </View>

                <TouchableOpacity
                  disabled={isLoading}
                  onPress={() => this.LoginWithMobileOtp()}
                  style={{
                    backgroundColor: otp ? "#000" : "#E9E9E9",
                    paddingVertical: 13,
                    marginTop: 50,
                    borderRadius: 25,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {isLoading ? (
                    <ActivityIndicator color={"#fff"} />
                  ) : (
                    <Text
                      style={{
                        fontFamily: font.bold,
                        fontWeight: fontWeight.bold,
                        color: "#ffff",
                        fontSize: 16,
                      }}
                    >
                      {this.props.t('Submit')}                      
                    </Text>
                  )}
                </TouchableOpacity>
              </View>
              <View
                style={{
                  paddingHorizontal: 20,
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                {second > 0 ? (
                  <>
                    <Text
                      style={{
                        color: "#E2583A",
                        fontFamily: font.bold,
                        fontWeight: fontWeight.bold,
                        paddingBottom: 5,
                        fontSize: 16,
                      }}
                    >
                      {OtpDisplay}
                    </Text>
                    <View style={{ alignItems: "center" }}>
                      <View style={{ flexDirection: "row" }}>
                        <Text
                          style={{
                            color: "#000",
                            fontFamily: font.reg,
                            fontWeight: fontWeight.thin,
                            fontSize: 15,
                          }}
                        >
                          {this.props.t('Resend code in')}  
                        </Text>
                        <Text
                          style={{
                            color: "#000",
                            fontFamily: font.reg,
                            fontSize: 15,
                            marginLeft: 5,
                          }}
                        >
                          00:{second}
                        </Text>
                      </View>
                    </View>
                  </>
                ) : isLoadingResend ? (
                  <ActivityIndicator color={"#000"} />
                ) : (
                  <TouchableOpacity
                    onPress={() => this.ResendOtp()}
                    style={{ paddingHorizontal: 15 }}
                  >
                    <Text
                      style={{
                        color: "#E2583A",
                        fontFamily: font.reg,
                        fontSize: 15,
                      }}
                    >
                      {this.props.t('Resend')}                        
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </ScrollView>
        </View>
        <SnackBar ref="RNSnackBar" />
      </KeyboardAvoidingView>
    );
  }
}
export default withTranslation()(withRouter(Otp));
