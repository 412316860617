import { AppRegistry, Dimensions } from "react-native";
import App from "./App";
import "./index.css";
import './i18n';

AppRegistry.registerComponent("App", () => App);

const { height, width } = Dimensions.get("screen");
// console.log("Index.js 8", height, width, document.getElementById("root").clientHeight,document.getElementById("root").clientWidth);
// localStorage.setItem("kushkDevice", JSON.stringify({ deviceHeight: height, deviceWidth: width }));
localStorage.setItem(
  "kushkDevice",
  JSON.stringify({
    deviceHeight: document.getElementById("root").clientHeight,
    deviceWidth: document.getElementById("root").clientWidth,
  })
);

AppRegistry.runApplication("App", {
  rootTag: document.getElementById("root"),
});
