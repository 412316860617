import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
  RefreshControl,
} from "react-native";
import Header from "../Component/Header";
import { color, font, fontBold, fontReg, shadow } from "../Component/Styles";
import { API } from "../../Privet";
// import Global from '../Global';
// import ImageLoader from '../Component/ImageLoader';
import moment from "moment";
import { getUserData } from "../../utility/Utils";

import NImageLoader from "../NComponent/NImageLoader";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { withRouter } from "../../withRouter";
import FootTab from "./FootTab";

import { withTranslation } from 'react-i18next';

class PurchaseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isRefreshing: false,
      PurchaseList: [],
      totalPage: 1,
      page: 1,
      isSearch: false,
      text: "",
      currLang: window.localStorage.getItem("lang") ?? "en",
    };
    this.arrayHold = [];
  }

  async componentDidMount() {
    // Global.main.setState({ activeRoute: '' });
    // Global.main.isTabBar(true);
    this.PurchaseListApi();
  }

  willFocus() {
    // Global.main.setState({ activeRoute: '' });
    // Global.main.isTabBar(true);
    this.setState(
      {
        isLoading: true,
        page: 1,
        totalPage: 1,
      },
      () => this.PurchaseListApi()
    );
  }

  renderFooter = () => {
    const { totalPage, page, isLoading, isRefreshing } = this.state;
    if ((totalPage >= page && !isRefreshing) || isLoading) {
      return (
        <View
          style={{
            height: 40,
            width: 40,
            borderRadius: 40,
            ...shadow,
            alignSelf: "center",
            marginTop: 20,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator color={color.primeColor} style={{}} />
        </View>
      );
    } else {
      return null;
    }
  };

  onRefresh() {
    this.setState(
      {
        totalPage: 1,
        page: 1,
        subId: "",

        isRefreshing: true,
      },
      () => this.PurchaseListApi()
    );
  }

  async PurchaseListApi() {
    const { token, userid } = getUserData();
    const { totalPage, page, isLoading, isRefreshing, PurchaseList } =
      this.state;

    if (totalPage >= page) {
      let formdata = new FormData();
      formdata.append("appuser_id", userid);
      formdata.append("page", page + "");
      formdata.append("perPage", "15");

      await fetch(API.purchaseList, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        body: formdata,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {
            if (isLoading || isRefreshing || PurchaseList.length <= 0) {
              this.setState({
                isLoading: false,
                isRefreshing: false,
                PurchaseList: res.data.collection,
                totalPage: res.data.lastPage,
                page: page + 1,
                isVisible: false,
              });
              this.arrayHold = res.data.collection;
            } else {
              this.setState({
                isLoading: false,
                isRefreshing: false,
                PurchaseList: [...PurchaseList, ...res.data.collection],
                totalPage: res.data.lastPage,
                page: page + 1,
                isVisible: false,
              });
              this.arrayHold = [...PurchaseList, ...res.data.collection];
            }
          } else {
            this.setState({
              isLoading: false,
              isRefreshing: false,
              page: page + 1,
              PurchaseList: [],
              isVisible: true,
              msg: res.message,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isRefreshing: false,
            page: page + 1,
          });
          console.log(error);
        })
        .finally(() => {
          this.setState({
            isLoading: false,
            isRefreshing: false,
            page: page + 1,
          });
        });
    }
  }

  search(text) {
    this.setState({ text });
    const data = this.arrayHold.filter((item) => {
      const itemData =
        `${item.product_name} ${item.product_category_name} ${item.product_subcategory_name}`.toUpperCase();
      return itemData.indexOf(text.toUpperCase()) > -1;
    });
    this.setState({ PurchaseList: data });
  }
  render() {
    const {
      isLoading,
      isRefreshing,
      PurchaseList,
      isSearch,
      text,
      showDrawer = false,
    } = this.state;
    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <Header
          title={this.props.t("Purchase history")}
          Limg={require("../../assets/img/homemenu.png")}
          showDrawer={showDrawer}
          closeDrawer={() => this.setState({ showDrawer: false })}
          Lpress={() => this.setState({ showDrawer: true })}
          Rimg={require("../../assets/img/search.png")}
          Rpress={() => this.setState({ isSearch: true })}
          _onWillFocus={() => this.willFocus()}
          searchBar={isSearch}
          closeSearchBar={() =>
            this.setState({
              isSearch: false,
              PurchaseList: this.arrayHold,
              text: "",
            })
          }
          texts={text}
          searchFilterFunction={(text) => this.search(text)}
        />
        {console.log("PurchaseList 208 ", PurchaseList)}
        <FlatList
          contentContainerStyle={{
            paddingBottom: 25,
            flexGrow: 1,
            paddingHorizontal: 20,
            paddingTop: 15,
          }}
          data={PurchaseList}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              onPress={() =>
                this.props.navigate("/purchase-product-detail", {
                  state: {
                    purchaseid: item.id,
                    product_rating_review: item.product_rating_review,
                  },
                })
              }
              style={{ flexDirection: "row", paddingBottom: 20 }}
            >
              <View
                style={{
                  height: 88,
                  width: 120,
                  borderRadius: 10,
                  overflow: "hidden",
                  marginLeft:  this.state.currLang === 'ar' ? 10 : 0,
                }}
              >
                <NImageLoader
                  imageSrc={
                    item.product_images[0] && item.product_images[0].product_image
                      ? item.product_images[0].product_image
                      : require("../../assets/img/noimg.jpeg")
                  }
                  imageAlt={item.name}
                  imageStyle={{
                    verticalAlign: "middle",
                    height: "100%",
                    width: "100%",
                  }}
                />
              </View>
              <View style={{ paddingLeft: 18, flex: 1 }}>
                <Text
                  style={{
                    ...fontBold,
                    color: "#000",
                    fontSize: 15,
                    marginTop: 3,
                    textAlign: this.state.currLang === 'ar' ? 'right' : '',
                  }}
                >
                  {item.product_name}
                </Text>
                {item.order_place_date ? (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginRight: this.state.currLang === 'ar' ? 0 : 15,
                      marginTop: 5,
                    }}
                  >
                    <Text
                      style={{
                        ...fontReg,
                        color: color.lableColor,
                        marginRight: this.state.currLang === 'ar' ? 10 : 0,
                        fontSize: 12,
                      }}
                    >
                      {moment(item.order_place_date).format("MMM D, YYYY")}
                    </Text>
                  </View>
                ) : null}
                {item.product_selling_price*item.product_selling_price ? (
                  <Text
                    style={{
                      ...fontBold,
                      color: "#E2583A",
                      fontSize: 14,
                      flex: 1,
                      position: "absolute",
                      bottom: 5,
                      left: this.state.currLang === 'ar' ? 'unset' : 18,
                    }}
                  >
                    SAR{" "}{item.product_selling_price*item.ordered_quantity + ""}
                  </Text>
                ) : null}
              </View>
            </TouchableOpacity>
          )}
          ListEmptyComponent={
            !isRefreshing && (
              <View style={{ flex: 1 }}>
                {!isLoading ? (
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      source={require("../../assets/img/nodata.png")}
                      style={{
                        height: 60,
                        width: 60,
                        tintColor: color.primeColor,
                      }}
                    />
                    <Text
                      style={{
                        ...fontBold,
                        textAlign: "center",
                        marginTop: 10,
                        color: color.lableColor,
                      }}
                    >
                      {this.props.t('Data not found')}!
                    </Text>
                  </View>
                ) : (
                  <>
                    {[1, 2, 3, 4, 5].map(() => {
                      return (
                        <View
                          style={{
                            flexDirection: "row",
                            // backgroundColor: "#a1a1a1"
                          }}
                        >
                          <View
                            style={{
                              height: 100,
                              width: 100,
                              overflow: "hidden",
                              marginBottom: 10
                            }}
                          >
                            <Skeleton
                              height={100}
                              width={100}
                              containerClassName="avatar-skeleton"
                            />
                          </View>
                          <View
                            style={{
                              paddingLeft: 18,
                              flex: 1,
                              width: "100%",
                              // backgroundColor: "#b25b25",
                            }}
                          >
                            <Skeleton width="100%" style={{marginBottom: 10}}/>
                            <Skeleton width="40%" style={{marginBottom: 10}}/>
                            <Skeleton width="60%" style={{marginBottom: 10}}/>
                            <Skeleton width="80%" style={{marginBottom: 10}}/>
                          </View>
                        </View>
                      );
                    })}
                  </>
                )}
              </View>
            )
          }
          keyExtractor={(item, index) => index + ""}
          onEndReachedThreshold={0.1}
          onEndReached={() => this.PurchaseListApi()}
          ListFooterComponent={this.renderFooter}
          refreshControl={
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={() => this.onRefresh()}
            />
          }
        />
        <FootTab />
      </View>
    );
  }
}

export default withTranslation()(withRouter(PurchaseList));
