import React from "react";
import {
  FlatList,
  Image,
  PixelRatio,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { color, font, fontBold, fontReg } from "../Component/Styles";
import { API } from "../../Privet";
import Header from "../Component/Header";
import { getUserData } from "../../utility/Utils";
import moment from "moment";
import NImageLoader from "../NComponent/NImageLoader";
import Coundown from "../Component/Coundown";
import { withRouter } from "../../withRouter";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { withTranslation } from "react-i18next";

const userData = getUserData();

const wp = (widthPercent) => {
  const { deviceWidth } = JSON.parse(localStorage.getItem("kushkDevice"));
  const width = deviceWidth;
  const elemWidth =
    typeof widthPercent === "number" ? widthPercent : parseFloat(widthPercent);
  return PixelRatio.roundToNearestPixel((width * elemWidth) / 100);
};
const hp = (heightPercent) => {
  const { deviceHeight } = JSON.parse(localStorage.getItem("kushkDevice"));
  const height = deviceHeight;
  const elemHeight =
    typeof heightPercent === "number"
      ? heightPercent
      : parseFloat(heightPercent);
  return PixelRatio.roundToNearestPixel((height * elemHeight) / 100);
};

class PurchasedProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      isSelected: { index: 0 },
      isSelectedPath: null,
      purchaseid: "",
      product_image: "",
      product_rating_review: "",
      display: true,
      content: false,
      currLang: window.localStorage.getItem("lang") ?? "en",
    };
  }

  async componentDidMount() {
    // await Global.main.isTabBar(false);
    // const purchaseid = await this.props.navigation.getParam("purchaseid");
    // const product_rating_review = await this.props.navigation.getParam(
    //     "product_rating_review"
    //   );
    const { purchaseid, product_rating_review } = await this.props.location
      .state;
    this.setState({ purchaseid, product_rating_review });
    const condition = product_rating_review.every(
      (obj) => obj.app_user_id === userData.userid + ""
    );

    product_rating_review.map((item) => {
      if (item.app_user_id === userData.userid + "") {
        this.setState({ content: true, contentDis: item.review });
      }
      return;
    });

    if (product_rating_review.length > 0) {
      this.setState({ display: condition ? false : true });
    }

    await this.PurchasedProductDetails();
  }

  timecalculate(expirydate) {
    var date = moment().utcOffset("+03:00").format("YYYY-MM-DD HH:mm:ss");
    var diffr = moment.duration(moment(expirydate).diff(moment(date)));
    var hours = parseInt(diffr.asHours());
    var minutes = parseInt(diffr.minutes());
    var seconds = parseInt(diffr.seconds());
    var d = hours * 60 * 60 + minutes * 60 + seconds;

    return d;
  }

  async willFocus() {
    // Global.main.isTabBar(false);
  }

  PurchasedProductDetails() {
    const { token, userid } = userData;
    const { purchaseid } = this.state;
    this.setState({ isLoading: true });

    let formdata = new FormData();
    formdata.append("appuser_id", userid);
    formdata.append("purchased_order_id", purchaseid);

    fetch(API.purchasedProduct, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            isLoading: false,
            data: res.data,
            product_image:
              res.data.product_images.length > 0
                ? res.data.product_images[0].product_image
                : "",
          });
          this.setState({
            isSelectedPath: this.state.product_image,
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }

  renderImages = (item, index) => {
    return (
      <View style={{ padding: 14, borderRadius: 10 }}>
        <TouchableOpacity
          onPress={() => {
            this.setState({
              isSelected: item,
              isSelectedPath: item.item.product_image,
            });
          }}
        >
          <Image
            style={{
              width: wp(15),
              height: wp(15),
              borderRadius: 10,
              borderColor: "#000",
              resizeMode: "contain",
            }}
            source={{ uri: item.item.product_image }}
          />
        </TouchableOpacity>
      </View>
    );
  };

  render() {
    const {
      data,
      product_image,
      isSelectedPath,
      isLoading,
      display,
      content,
      contentDis,
    } = this.state;
    console.log("product_image 172", this.state);

    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <Header
          title={this.props.t("Purchased Product")}
          Limg={require("../../assets/img/back.png")}
          Lpress={() => this.props.navigate(-1)}
          _onWillFocus={() => this.willFocus()}
        />
        <View style={{ backgroundColor: "#fff", flex: 1 }}>
          <ScrollView showsVerticalScrollIndicator={false}>
            {!isLoading ? (
              <>
                <View>
                  {data.product_type_of_sale === "Auction" ? (
                    <View
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 10,
                        flexDirection: "row",
                        alignItems: "center",
                        borderRadius: 26,
                        paddingVertical: 7,
                        paddingHorizontal: 10,
                        backgroundColor: color.primeColor,
                      }}
                    >
                      <Image
                        source={require("../../assets/img/clock.png")}
                        style={{
                          height: wp(3),
                          width: wp(3),
                          resizeMode: "cover",
                        }}
                      />
                      {data.product_type_of_sale === "Auction" ? (
                        <Text
                          style={{
                            ...fontBold,
                            color: "#000",
                            marginLeft: 6,
                            fontSize: wp(3.2),
                          }}
                        >
                          {/* <Countdown
                            format="d[d]:kk[h]:mm[m]"
                            finishTime={this.timecalculate(
                              item.auction_endtime
                            )}
                          /> */}
                          <Coundown finishTime={data.auction_endtime} />
                        </Text>
                      ) : null}
                    </View>
                  ) : null}
                </View>
                <View>
                  <View style={{ height: 250 }}>
                    <Image
                      style={{
                        height: "100%",
                        width: "100%",
                        resizeMode: "contain",
                      }}
                      source={{
                        uri: this.state.isSelectedPath
                          ? this.state.isSelectedPath
                          : data.image
                          ? data.image
                          : require("../../assets/img/noimg.jpeg"),
                      }}
                    />
                  </View>
                  <FlatList
                    contentContainerStyle={{
                      flex: 1,
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    data={data.product_images}
                    renderItem={this.renderImages}
                    value={this.state.data}
                    showsVerticalScrollIndicator={false}
                    horizontal={true}
                    extraData={this.state.refresh}
                  ></FlatList>
                </View>
                <View
                  style={{
                    backgroundColor: "#fff",
                    paddingTop: 16,
                    paddingHorizontal: 20,
                    paddingBottom: wp(20),
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          ...fontBold,
                          fontSize: 24,
                          color: "#231F20",
                          textAlign: this.state.currLang === "ar" ? "end" : "",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.product_name}
                      </Text>
                      <View
                        style={{ flex: 1, flexDirection: "row", marginTop: 7, marginBottom: 8  }}
                      >
                        <Text
                          style={{
                            ...fontBold,
                            fontSize: 20,
                            color: "#9E9EA7",
                          }}
                        >
                          {moment(data.order_place_date).format("MMM D, YYYY")}
                        </Text>
                      </View>
                      <Text
                        style={{
                         ...fontBold,
                          color: "#231F20",
                          fontSize: 13,
                          marginBottom: 3,
                        }}
                      >
                        {this.props.t("Product price")} -{" "}
                        {data.product_saleprice}
                      </Text>
                      <Text
                        style={{
                         ...fontBold,
                          color: "#231F20",
                          fontSize: 13,
                          marginBottom: 3,
                        }}
                      >
                        {this.props.t("Quantity")} - {data.ordered_quantity}
                      </Text>
                      <Text
                        style={{
                         ...fontBold,
                          color: "#231F20",
                          fontSize: 13,
                          marginBottom: 3,
                        }}
                      >
                        {this.props.t("Comission")} -{" "}
                        {data.admin_commission_amount}
                      </Text>
                    </View>
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Text
                        style={{
                          ...fontBold,
                          //   color: "#231F20",
                          fontSize: 18,
                          color: "#E2583B",
                        }}
                      >
                        SAR{" "}{data.product_selling_price * data.ordered_quantity}
                      </Text>
                    </View>
                  </View>
                  <View style={{ flexDirection: "column", paddingTop: wp(10) }}>
                    <Text
                      style={{
                        ...fontBold,
                        fontSize: 24,
                        color: "#231F20",
                        marginTop: 0,
                      }}
                    >
                      {this.props.t("Description")}
                    </Text>
                    <View style={{ paddingTop: wp(3) }}>
                      <Text
                        style={{
                          ...fontReg,
                          color: "#9E9EA7",
                          fontSize: 20,
                          lineHeight: 20,
                          textAlign: this.state.currLang === "ar" ? "end" : "",
                        }}
                      >
                        {data.product_description}
                      </Text>
                    </View>
                  </View>
                  {content ? (
                    <View
                      style={{ flexDirection: "column", paddingTop: wp(10) }}
                    >
                      <Text
                        style={{
                          ...fontBold,
                          fontSize: wp(3.5),
                          color: "#231F20",
                          marginTop: 0,
                        }}
                      >
                        {this.props.t("Review")}
                      </Text>
                      <View style={{ paddingTop: wp(3) }}>
                        <Text
                          style={{
                            ...fontReg,
                            color: "#9E9EA7",
                            fontSize: wp(3.2),
                            lineHeight: wp(4.7),
                            textAlign:
                              this.state.currLang === "ar" ? "end" : "",
                          }}
                        >
                          {contentDis}
                        </Text>
                      </View>
                    </View>
                  ) : null}
                </View>
              </>
            ) : (
              <>
                <View style={{ padding: 15 }}>
                  <Skeleton
                    style={{
                      height: 250,
                      width: "100%",
                      borderRadius: 7,
                      marginBottom: 10,
                    }}
                  />
                  <Skeleton
                    style={{
                      height: 30,
                      width: "90%",
                      borderRadius: 7,
                      marginBottom: 10,
                    }}
                  />
                  <Skeleton
                    style={{
                      height: 30,
                      width: "70%",
                      borderRadius: 7,
                      marginBottom: 10,
                    }}
                  />
                  <View style={{ flexDirection: "row" }}>
                    <Skeleton
                      style={{
                        height: 60,
                        width: 60,
                        borderRadius: 50,
                        marginBottom: 10,
                      }}
                    />
                    <View style={{ marginLeft: 10 }}>
                      <Skeleton
                        style={{
                          height: 20,
                          width: 160,
                          borderRadius: 7,
                          marginBottom: 10,
                        }}
                      />
                      <Skeleton
                        style={{
                          height: 20,
                          width: 130,
                          borderRadius: 7,
                          marginBottom: 10,
                        }}
                      />
                    </View>
                  </View>
                  <Skeleton
                    style={{
                      height: 100,
                      width: "100%",
                      borderRadius: 7,
                      marginBottom: 10,
                    }}
                  />
                  <Skeleton
                    style={{
                      height: 100,
                      width: "100%",
                      borderRadius: 7,
                      marginBottom: 10,
                    }}
                  />
                </View>
              </>
            )}
          </ScrollView>
        </View>
        {display ? (
          <View
            style={{
              flexDirection: "row",
              backgroundColor: "#fff",
              paddingVertical: 15,
              paddingHorizontal: 25,
              marginBottom: Platform.OS === "ios" ? 25 : 0,
            }}
          >
            {!isLoading ? (
              <TouchableOpacity
                onPress={() =>
                  this.props.navigate("/review-seller", {
                    state: {
                      order_id: data.id,
                      product_id: data.product_id,
                      product_owner_id: data.product_owner_id,
                    },
                  })
                }
                style={{
                  paddingVertical: 11,
                  borderRadius: 30,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#231F20",
                  flex: 1,
                  marginRight: 5,
                }}
              >
                <Text
                  style={{
                    ...fontBold,
                    color: "#FFFFFF",
                    fontSize: 14,
                  }}
                >
                  {this.props.t("Review seller")}
                </Text>
              </TouchableOpacity>
            ) : null}
          </View>
        ) : null}
      </View>
    );
  }
}

export default withTranslation()(withRouter(PurchasedProductDetails));
