import React from "react";
import ImageUpload from "./ImageUpload";

function index() {
  // const imageUploadWithPreviewRef = useRef(null);

  return (
    <div style={{ flex: 1, backgroundColor: "#fff", textAlign: "center" }}>
      <ImageUpload />
    </div>
  );
}

export default index;
