import React, { useState } from "react";
import { withTranslation } from "react-i18next";

function ImageUpload() {
  const [images, setImages] = useState([]);
  const selectImg = (event) => {
    // event = URL.createObjectURL(event);
    setImages({ images, ...event });
  };
  console.log("Image upload 8", images);
  return (
    <>
      <div
        style={{
          fontSize: 18,
          backgroundColor: "#90cee3",
          padding: 10,
          margin: 5,
        }}
        onClick={() => {
          // imageUploadWithPreviewRef;
          console.log("Click Image");
          // this.ImageUploadWithPreview;
        }}
      >
       {this.props.t('Image Upload')}
      </div>
      <input
        type="file"
        placeholder={this.props.t("upload image")}
        onChange={(e) => {
          selectImg(URL.createObjectURL(e.target.files[0]));
          console.log(e.target.files);
        }}
      />

      {/* {images.map((item, index) => {
        console.log("image array of " + index, item);
        <></>;
      })} */}
    </>
  );
}

export default withTranslation(ImageUpload);
