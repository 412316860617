// Main
// const base_url = 'https://ekushk.websitetech.in/api/';
const base_url = 'https://app.ekushk.com/api/';

// const base_url = 'http://192.168.1.53:8000/api/';
// const base_url = 'http://192.168.1.53:7000/api/';

// Change Base Url from 3 File
// Privet -2 Place
// Message.js 


export const API = {
  googleMapKey: 'AIzaSyBF_j9sCrCHNjttUf93Mox3w6b_BK427nA', // Key of Client Account
  // googleMapKey: 'AIzaSyBciUqLdvoApczXk5LXbqkYlEmKzRoWGcs' , // Key of Evolution Account

  // PayTab SDK Key
  Server_Key: 'SZJNBMTGHZ-J2WWGJHMDT-JWWKKZM9GH',
  Client_Key: 'C9KMP2-62276M-VVT6HN-H7D2RQ',
  ProfileId: '86238',

  // base_url: 'https://ekushk.webdemotest.com/api/',
  // base_url: 'https://ekushk.websitetech.in/api/',
  // base_url: 'http://192.168.1.53:8000/api/',
  // base_url: 'http://192.168.1.53:7000/api/',

  base_url: base_url,
  

  sendotp: base_url + 'sendotp',
  login: base_url + 'login',
  productlist: base_url + 'productlist',
  productdetail: base_url + 'productdetail',
  maincategorylist: base_url + 'maincategorylist',
  subcategorylist: base_url + 'subcategorylist',
  productadd: base_url + 'productadd',
  resend: base_url + 'resend',
  myPosts: base_url + 'myPosts',
  userProfile: base_url + 'userProfile',
  userWatchlist: base_url + 'userWatchlist',
  updateUserProfile: base_url + 'updateUserProfile',
  add_removeToWatchlist: base_url + 'add_removeToWatchlist',
  placeBidOnAuctionProduct: base_url + 'placeBidOnAuctionProduct',
  activeBids: base_url + 'activeBids',
  purchaseList: base_url + 'purchaseList',
  userFavoriteSellerList: base_url + 'userFavoriteSellerList',
  purchasedProduct: base_url + 'purchasedProduct',
  productFilterResponse: base_url + 'productFilterResponse',
  productedit: base_url + 'productedit',
  sellerProfile: base_url + 'sellerProfile',
  productdetail_auctioned_product_placed_bids: base_url + 'productdetail-auctioned-product-placed-bids',
  productdetail_auction_product_win: base_url + 'productdetail-auction-product-win',
  userAddFavoriteSeller: base_url + 'userAddFavoriteSeller',
  order: base_url + 'order',
  admin_commission: base_url + 'admin-commission',
  order_product_hold_quntiry: base_url + 'order_product_hold_quntiry',
  purchasedProductRatingReview: base_url + 'purchasedProductRatingReview',
  sellerRatingReview: base_url + 'sellerRatingReview',
  app_setting_list: base_url + 'app-setting-list',
  public_pages:  base_url + 'public-page?',
  user_select_app_setting: base_url + 'user-select-app-setting',
  chat_message_list: base_url + 'chat/message_list?',
  chat_connect_users: base_url + 'chat/connect_users',
  logout: base_url + 'logout',

  checkPaymentComplete: base_url + 'checkPaymentComplete',
};

export const User = {
  id: '',
  userid: '',
  tokenProfile: '',
  token: '',
  email: '',
  mobile: '',
  firstname: '',
  lastname: '',
  image: '',
};
