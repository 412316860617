import React, { Component } from 'react'
import { withRouter } from "../../withRouter";
import { clearUserLocalStorage, getUserData } from "../../utility/Utils";
import { ActivityIndicator, Image, PixelRatio, ScrollView, Text, TouchableOpacity, View } from 'react-native-web';
import { API } from '../../Privet';
import { color, fontBold } from '../Component/Styles';
import { withTranslation } from 'react-i18next';


const hp = (heightPercent) => {
  const { deviceHeight } = JSON.parse(localStorage.getItem("kushkDevice"));
  const height = deviceHeight;
  const elemHeight =
    typeof heightPercent === "number"
      ? heightPercent
      : parseFloat(heightPercent);
  return PixelRatio.roundToNearestPixel((height * elemHeight) / 100);
};
class Payment extends Component {
  state = {
    sellerId : null,
    isLoading : false,
    getParams : {},
    userMessage : null,
    currLang: window.localStorage.getItem("lang") ?? "en",
  }

  async componentDidMount() {
    
    this.setState({getParams : this.props.location.state});
    this.setState({isLoading : true});
    await this.checkPaymentComplete();

    let { deviceHeight, deviceWidth } = JSON.parse(
      await localStorage.getItem("kushkDevice")
    );
    // deviceWidth = deviceWidth;
    await this.setState({ deviceHeight, deviceWidth });
  }

  checkPaymentComplete = () => {
    const { token, userid } = getUserData();
    const { getParams } = this.state;
    if(getParams.id){
      let formdata = new FormData();
      formdata.append("appuser_id", userid);
      formdata.append("payment_id", getParams.id);

      let myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
      };

      fetch(API.checkPaymentComplete, requestOptions)
        .then((response) => {
          if (response.status === 401) {
            clearUserLocalStorage();
            this.props.navigate("/login");
          }
          if (response.status === 404) {
            // console.log(response);
          }
          this.setState({userMessage : "The commission was unpaid...."});
          this.setState({isLoading : false});
          return response.json();
        })
        .then((response) => {
          if (response.success) {
            // alert(response.message);
            console.log("Product Payment", response);
            this.setState({userMessage : "The commission was paid successfully.."});
            this.setState({isLoading : false});
          }
        })
        .catch((error) => console.log("error", error));
    }
    else{
      this.setState({isLoading : false});
    }
    
  };

  onPress = () => {
    if(this.state.getParams.id){
      localStorage.setItem("kushkRedirect", true);
      window.close();
    }
    /*else if(this.state.getParams.sellerid){
      console.log(this.state.getParams.sellerid);
      this.props.navigate("/seller-profile", {
        state: {
          sellerid: this.state.getParams.sellerid,
        },
      });
    }*/
    else{
      this.props.navigate("/seller-profile", {
        state: {
          sellerid: this.state.getParams.sellerid,
        },
      });
    }
    // this.props.location.state.sellerid;
  };
  
  render() {
    const {
      isLoading,
      getParams,
      userMessage,
    } = this.state
    return (
      <>
      {/* { userMessage } */}
      { isLoading ?
        (
        <View
        style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text
            style={{
              fontSize: 20,
              fontWeight: 700,
              marginVertical: 20,
            }}
            >
            {this.props.t('Do not close or change this screen')}
          </Text>
          <ActivityIndicator color={"#000"} />
        </View>
      ) : (
        <View style={{ flex: 1, backgroundColor: "#fff" }}>
          <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
              
                borderColor={color.primeColor}
                style={{
                  height: 95,
                  width: 95,
                  borderWidth: 4,
                  borderRadius: 95,
                  justifyContent: "center",
                  alignItems: "center",
                  borderColor: color.primeColor,
                  transform: this.state.currLang === 'ar' ? 'scaleX(-1)' : ''
                }}
              >
                <Image
                  source={require("../../assets/img/check.png")}
                  style={{
                    height: "70%",
                    width: "70%",
                    resizeMode: "contain",
                    tintColor: color.primeColor,
                  }}
                />
              </View>
              <Text
                style={{
                  ...fontBold,
                  color: color.primeColor,
                  fontSize: 13,
                  paddingHorizontal: 30,
                  paddingTop: 30,
                  paddingBottom: 10,
                }}
              >
                {this.props.t("The commission was paid successfully..")}
              </Text>
              <Text
                style={{
                  ...fontBold,
                  color: "#000",
                  fontSize: 15,
                  paddingHorizontal: 30,
                  paddingBottom: 30,
                  alignSelf: "center",
                  textAlign: "center",
                }}
              >
                {this.props.t('Please contact the seller to discuss next steps')}.
              </Text>
              <TouchableOpacity
                onPress={() => this.onPress()}
                style={{
                  backgroundColor: "#000",
                  paddingVertical: 13,
                  paddingHorizontal: 40,
                  marginTop: 50,
                  borderRadius: 25,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    ...fontBold,
                    color: "#ffff",
                    fontSize: 16,
                  }}
                >
                  {this.props.t('OK')}
                </Text>
              </TouchableOpacity>
            </View>
          </ScrollView>
        </View>
      )}
      </>
    )
  }
}
export default withTranslation()(withRouter(Payment));