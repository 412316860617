import React from "react";
import {
  ActivityIndicator,
  Dimensions,
  Image,
  PixelRatio,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { color, font } from "../Component/Styles";
import { API } from "../../Privet";
import Header from "../Component/Header";

import SnackBar from "../Component/SnackBar";
import RatingBar from "../Component/RatingBar";
import { getUserData } from "../../utility/Utils";
import { withRouter } from "../../withRouter";

import { withTranslation } from 'react-i18next';

const { width } = Dimensions.get("screen");
const wp = (widthPercent) => {
  const elemWidth =
    typeof widthPercent === "number" ? widthPercent : parseFloat(widthPercent);
  return PixelRatio.roundToNearestPixel((width * elemWidth) / 100);
};

const userData = getUserData();

class ReviewSeller extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      isSelected: { index: 0 },
      isSelectedPath: null,
      rating: "0",
      isLoading: false,
      review: "",
      order_id: "",
      product_id: "",
      product_owner_id: "",
      currLang: window.localStorage.getItem("lang") ?? "en",
    };
  }

  async componentDidMount() {
    // await Global.main.isTabBar(false);
    // const order_id = await this.props.navigation.getParam('order_id');
    // const product_id = await this.props.navigation.getParam('product_id');
    // const product_owner_id = await this.props.navigation.getParam('product_owner_id');
    const { order_id, product_id, product_owner_id } = await this.props.location
      .state;
    this.setState({ order_id, product_id, product_owner_id });
  }

  willFocus() {
    // Global.main.isTabBar(false);
  }

  async addReview() {
    const { token, userid } = userData;
    const { rating, review, order_id, product_id, product_owner_id } =
      await this.state;

    if (!review) {
      this.refs.Snackbar.show(this.props.t("Please enter review."));
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);
      this.setState({ isLoading: true });
      var formdata = new FormData();
      formdata.append("appuser_id", userid);
      formdata.append("order_id", order_id);
      formdata.append("purchased_product_id", product_id);
      formdata.append("product_owner_id", product_owner_id);
      formdata.append("review", review);
      formdata.append("rating", rating);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      await fetch(API.purchasedProductRatingReview, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            this.setState({ isLoading: false });
            this.refs.Snackbar.show(result.message);
            this.props.navigate(-1);
            // this.props.navigate("purchase-list");
            return;
            // this.props.navigation.navigate('PurchaseList')
          } else {
            this.setState({ isLoading: false });
          }
        })
        .catch((error) => console.log("error", error));
    }
  }

  rating() {
    const {
      onChange,
      disabled = false,
      value = 0,
      size = 5,
      imageStyle,
    } = this.props;
    let ratingBar = [];
    for (let i = 1; i <= size; i++) {
      ratingBar.push(
        <TouchableOpacity
          key={i + ""}
          disabled={disabled}
          onPress={() => onChange && onChange(i)}
          style={{ marginRight: size === i ? 0 : 3 }}
        >
          <Image
            source={require("../../assets/img/rating-gray.png")}
            style={{
              height: 13,
              width: 13,
              resizeMode: "contain",
              tintColor: value >= i ? color.primeColor : color.lableColor,
              ...imageStyle,
            }}
          />
        </TouchableOpacity>
      );
    }
    return ratingBar;
  }

  render() {
    const { rating, isLoading, review } = this.state;
    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <Header
          title={this.props.t("Reivew Seller")}
          Limg={require("../../assets/img/back.png")}
          Lpress={() => this.props.navigate(-1)}
        />
        <View style={{ backgroundColor: "#fff", flex: 1 }}>
          <ScrollView showsVerticalScrollIndicator={false}>
            <View style={{ flex: 1, padding: 20 }}>
              <Text
                style={{
                  color: "#231F20",
                  fontFamily: font.reg,
                  marginTop: 10,
                }}
              >
                {this.props.t('Please write a review')}
              </Text>
              <TextInput
                style={{
                  height: 150,
                  textAlignVertical: "top",
                  borderWidth: 1,
                  borderRadius: 5,
                  borderColor: color.borderColor,
                  paddingHorizontal: 15,
                  marginTop: 15,
                  paddingTop: 10,
                  textAlign: this.state.currLang === 'ar' ? 'end' : ''
                }}
                placeholder={this.props.t("Write description")}
                multiline={true} // ios fix for centering it at the top-left corner
                value={review}
                onChangeText={(review) => this.setState({ review })}
              />
              <Text
                style={{
                  color: "#231F20",
                  fontFamily: font.reg,
                  marginTop: 30,
                  fontSize: 14,
                }}
              >
                {this.props.t('Rate the seller')}
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  marginVertical: 15,
                  alignItems: "center",
                }}
              >
                <RatingBar
                  value={rating}
                  onChange={(rating) => this.setState({ rating })}
                  imageStyle={{ height: 50, width: 35, marginHorizontal: 5 }}
                />
                <Text
                  style={{
                    color: "#000",
                    fontFamily: font.bold,
                    fontSize: 17,
                    marginLeft: 20,
                  }}
                >
                  {rating}.0
                </Text>
              </View>
            </View>
          </ScrollView>
        </View>
        <View
          style={{
            flexDirection: "row",
            backgroundColor: "#fff",
            paddingVertical: 15,
            paddingHorizontal: 25,
            marginBottom: 20,
          }}
        >
          <TouchableOpacity
            onPress={() => this.addReview()}
            style={{
              paddingVertical: 13,
              borderRadius: 30,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#231F20",
              flex: 1,
              marginRight: 5,
            }}
          >
            {isLoading ? (
              <ActivityIndicator color={"#fff"} />
            ) : (
              <Text
                style={{
                  color: "#FFFFFF",
                  fontSize: 16,
                  fontFamily: font.bold,
                }}
              >
                {this.props.t('Submit')}
              </Text>
            )}
          </TouchableOpacity>
        </View>
        <SnackBar ref="Snackbar" />
      </View>
    );
  }
}

export default withTranslation()(withRouter(ReviewSeller));
