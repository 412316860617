import React, { Component } from "react";
import {
  View,
  Text,
  StatusBar,
  TextInput,
  SafeAreaView,
  Platform,
  Image,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import { Navigate } from "react-router-dom";

import { getUserData, isUserLoggedIn } from "../../utility/Utils";
import { withRouter } from "../../withRouter";
import CustomDrawerMenu from "./CustomDrawerMenu";
// import SplashScreen from 'react-native-splash-screen';
import { font, fontBold, fontReg } from "./Styles";

import { withTranslation } from "react-i18next";
import i18n from "../../i18n";

const { height, width } = Dimensions.get("screen");
// import { NavigationEvents } from 'react-navigation';

// console.log("Header 23", height, width);

interface MyProps {
  Limg: require;
  searchBar: Boolean;
  searchFilterFunction: Function;
}

class Header extends Component<MyProps> {
  constructor(props) {
    super(props);
    this.state = {
      currLang: window.localStorage.getItem("lang") ?? "en",
    };
  }

  async componentDidMount() {
    if (!isUserLoggedIn()) {
      <Navigate to="/login" />;
      this.props.navigate("/login", { state: { isReload: true } });
      return;
      // console.log('Path 20',window.location.pathname, userData);
    }
    let userData = getUserData();
    if (
      typeof userData.email === "undefined" ||
      userData.email === "undefined" ||
      userData.email === "" ||
      userData.email === "null" ||
      userData.email === null
    ) {
      <Navigate to="/add-profile" />;
      await this.props.navigate("/add-profile");
      // window.location.reload();
      console.log(
        "Header Did Mount 44",
        typeof userData.email,
        userData.email,
        userData,
        this.props.location
      );
      return;
    }
    if (userData !== null) {
      // console.log("Header Did Mount 37", userData);
      userData["currnetPath"] = window.location.pathname;
      userData["deviceWidth"] = width;
      userData["deviceHeight"] = height;
      localStorage.setItem("kushkUserData", JSON.stringify(userData));
    }
    // console.log('Header.js 20');
    // SplashScreen.hide();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      // prevState.currLang !== this.state.currLang &&
      this.state.currLang != i18n.language
    ) {
      if (i18n.language === "ar") {
        this.setState({
          currLang: i18n.language,
        });
      } else {
        this.setState({
          currLang: i18n.language,
        });
      }
    }
  }

  render() {
    const {
      Limg,
      title,
      Rimg,
      saveimg,
      savepress,
      Rimg2,
      count,
      edit = false,
      paddingBottom = 5,
      searchBar = false,
      texts,
      searchFilterFunction,
      closeSearchBar,
      Lpress,
      Rpress2,
      Rpress,
      EditPress,
      customeRightButton,
      showDrawer,
    } = this.props;

    // console.log("Header.js 73", this.props);

    return (
      <>
        {showDrawer && (
          <CustomDrawerMenu closeDrawer={() => this.props.closeDrawer()} />
        )}

        <View style={{ backgroundColor: "#fff0", zIndex: 100 }}>
          <SafeAreaView
            padding-top={
              Platform.OS === "android" ? StatusBar.statusBarHeight : 0
            }
            style={{ backgroundColor: "#fff" }}
          />
          <StatusBar backgroundColor={"#ffff"} barStyle="dark-content" />
          {!searchBar ? (
            <View
              style={{
                overflow: "hidden",
                paddingBottom: paddingBottom,
                backgroundColor: "#fff0",
                marginBottom: -paddingBottom,
              }}
            >
              <View
                style={{
                  paddingHorizontal: 15,
                  paddingVertical: 13,
                  borderBottomWidth: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#fff",
                  shadowOffset: { width: 0, height: 0 },
                  shadowOpacity: 0.35,
                  shadowRadius: 4,
                  elevation: 0.5,
                }}
              >
                <View style={{ width: this.state.currLang === "ar" ? 25 : 65 }}>
                  <TouchableOpacity
                    disabled={!Limg ? true : false}
                    onPress={() => (Lpress ? Lpress() : null)}
                    style={{
                      height: 25,
                      width: 25,
                      alignItems: "center",
                      justifyContent: "center",
                      transform:
                        this.state.currLang === "ar" ? "scaleX(-1)" : "",
                    }}
                  >
                    <Image
                      source={Limg}
                      style={{
                        resizeMode: "contain",
                        height: 19,
                        width: 19,
                        tintColor: "#000",
                      }}
                    />
                  </TouchableOpacity>
                </View>

                {/* Title Display */}
                {title ? (
                  <Text
                    numberOfLines={1}
                    style={{
                      ...fontBold,
                      flex: 1,
                      textAlign: "center",
                      fontSize: 18,
                      color: "#000",
                    }}
                  >
                    {title}
                  </Text>
                ) : (
                  <View style={{}}>
                    <Image
                      source={require("../../assets/img/logo.png")}
                      style={{ height: 35, width: 80 }}
                    />
                  </View>
                )}

                {customeRightButton ? (
                  customeRightButton
                ) : (
                  <View
                    style={{
                      width: this.state.currLang === "ar" ? 25 : 65,
                      flexDirection: "row",
                    }}
                  >
                    {Rimg2 && (
                      <TouchableOpacity
                        disabled={!Rimg2 ? true : false}
                        onPress={() => (Rpress2 ? Rpress2() : null)}
                        style={{
                          height: 25,
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Image
                          source={Rimg2}
                          style={{
                            resizeMode: "contain",
                            height: 19,
                            width: 19,
                            tintColor: "#000",
                          }}
                        />
                      </TouchableOpacity>
                    )}

                    {saveimg && (
                      <TouchableOpacity
                        disabled={!saveimg ? true : false}
                        onPress={() => (savepress ? savepress() : null)}
                        style={{
                          height: 25,
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: 40,
                        }}
                      >
                        <Image
                          source={saveimg}
                          style={{
                            resizeMode: "contain",
                            height: 22,
                            width: 22,
                            tintColor: "#000",
                          }}
                        />
                      </TouchableOpacity>
                    )}

                    {edit && (
                      <TouchableOpacity
                        onPress={() => (EditPress ? EditPress() : null)}
                        style={{
                          height: 25,
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={{
                            ...fontBold,
                            display: "contents",
                            alignItems: "center",
                            color: "#231F20",
                            fontSize: 16,
                            height: 40,
                            width: 40,
                          }}
                        >
                          {this.props.t("Edit")}
                        </Text>
                      </TouchableOpacity>
                    )}

                    {/* {console.log("Rimg", !Rimg)} */}
                    <TouchableOpacity
                      disabled={!Rimg ? true : false}
                      onPress={() => (Rpress ? Rpress() : null)}
                      style={{
                        height: 25,
                        width: 25,
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "auto",
                        transform:
                          this.state.currLang === "ar" ? "scaleX(-1)" : "",
                      }}
                    >
                      {Rimg ? (
                        <Image
                          source={Rimg}
                          style={{
                            resizeMode: "contain",
                            height: 22,
                            width: 22,
                            tintColor: "#000",
                          }}
                        />
                      ) : null}
                      {count ? (
                        <View
                          borderColor={"#fff"}
                          style={{
                            position: "absolute",
                            borderWidth: 2,
                            backgroundColor: "#fff",
                            height: 18,
                            width: 18,
                            top: -5,
                            right: -2,
                            borderRadius: 50,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Text style={{ ...fontBold, fontSize: 9 }}>
                            {count}
                          </Text>
                        </View>
                      ) : null}
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </View>
          ) : (
            <View
              style={{
                overflow: "hidden",
                paddingBottom: paddingBottom,
                backgroundColor: "#fff0",
              }}
            >
              <View
                style={{
                  paddingHorizontal: 15,
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  shadowOffset: { width: 0, height: 0 },
                  shadowOpacity: 0.35,
                  shadowRadius: 4,
                  elevation: 4,
                  paddingVertical: 9.5,
                }}
              >
                <View
                  style={{
                    height: 25,
                    width: 25,
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 10,
                  }}
                >
                  <Image
                    source={require("../../assets/img/search.png")}
                    style={{
                      height: 20,
                      width: 20,
                      resizeMode: "contain",
                      tintColor: "#000",
                    }}
                  />
                </View>
                <TextInput
                  autoFocus={searchBar}
                  style={{
                    ...fontReg,
                    padding: 0,
                    flex: 1,
                    fontSize: 17,
                    color: "#000",
                  }}
                  placeholder={this.props.t("Search")}
                  // placeholderTextColor="#fff5"
                  value={texts}
                  blurOnSubmit={true}
                  onChangeText={(texts) =>
                    searchFilterFunction
                      ? this.props.searchFilterFunction(texts)
                      : null
                  }
                />
                <TouchableOpacity
                  onPress={() =>
                    closeSearchBar ? this.props.closeSearchBar() : null
                  }
                  style={{
                    height: 25,
                    width: 25,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    source={require("../../assets/img/cancle_icon.png")}
                    style={{
                      height: 15,
                      width: 15,
                      resizeMode: "contain",
                      tintColor: "#000",
                    }}
                  />
                </TouchableOpacity>
              </View>
            </View>
          )}
          {/* <NavigationEvents
          onWillFocus={() => (_onWillFocus ? this.props._onWillFocus() : null)}
          onDidFocus={() => (_onDidFocus ? this.props._onDidFocus() : null)}
          onWillBlur={() => (_onWillBlur ? this.props._onWillBlur() : null)}
          onDidBlur={() => (_onDidBlur ? this.props._onDidBlur() : null)}
        /> */}
        </View>
      </>
    );
  }
}

export default withTranslation()(withRouter(Header));
